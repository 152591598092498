import React, { useState ,useEffect} from 'react';
import { Box,Grid, Stack, Typography, TextField ,IconButton,LinearProgress,ListItemText, ListItemIcon,Menu,MenuList, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert'; 
import PaymentIcon from '@mui/icons-material/Payment';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import {setContainerMovement} from '../../../../redux/slices/admin_container_slice';
import { useAppDispatch } from '../../../../hooks/hooks';

export function ProductLabel({ container_product }) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleMenuClose = () => {
      setAnchorEl(null);
    };

    return (
      <Box display="flex" alignItems="center" maxHeight={"200px"} sx={{ width:'100%', border: '1px solid #ccc', padding:'3px', borderRadius: '4px' }}>
        <Grid container sx={{ height: '100%', justifyContent:"flex-start"}}>
          <Grid item xs={2.5}>
          <Box
            sx={{ height: '100%', display: 'flex', justifyContent: 'center',alignItems: 'center', }}>
            <img  src={container_product.product.imageUrl} alt="Imagen" style={{ maxHeight: '60px', width: 'auto' }} />
          </Box>
          </Grid>
          <Grid item xs={7.5}>
            <Grid container direction="column" sx={{ height: '100%' }}>
              <Grid item sx={{ height: '40%' }}>
                <Box sx={{ height: '100%'}}>
                  <Typography
                    variant="body1"
                    sx={{
                      width:'100%',
                      overflow: 'hidden',
                      textAlign:'left',          
                      textOverflow: 'ellipsis',      
                      display: '-webkit-box',       
                      WebkitLineClamp: 1,        
                      WebkitBoxOrient: 'vertical', 
                      fontSize: '0.8rem'
                    }}
                  >
                    <strong>{container_product.product.name}</strong>
                  </Typography>
                </Box>
              </Grid>
              <Grid item sx={{ height: '30%' }}>
                <Box sx={{ height: '100%'}}>
                  <Typography
                    variant="body1"
                    sx={{
                      width:'100%',
                      overflow: 'hidden',       
                      textOverflow: 'ellipsis',  
                      display: '-webkit-box',     
                      WebkitLineClamp: 2,          
                      WebkitBoxOrient: 'vertical', 
                      fontSize: '0.8rem'   
                    }}
                  >
                    Vendidos: {new Intl.NumberFormat('es-CL').format(container_product.quantity)}
                  </Typography>
                </Box>
              </Grid>
              <Grid item sx={{ height: '30%' }}>
                {/* <Box sx={{ height: '100%',}}>
                  <Typography
                    variant="body1"
                    sx={{
                      width:'100%',
                      overflow: 'hidden',            
                      textOverflow: 'ellipsis',      
                      display: '-webkit-box',         
                      WebkitLineClamp: 2,           
                      WebkitBoxOrient: 'vertical', 
                      fontSize: '0.8rem'   
                    }}
                  >
                    Pagados:
                  </Typography>
                </Box> */}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2}>
          <Box
            sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            {/* <IconButton aria-label="more" onClick={handleMenuOpen}>
              <MoreVertIcon />
            </IconButton> */}
        <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
        <MenuList>
            <MenuItem >
                <ListItemIcon>
                    <PaymentIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Ver Pagos</ListItemText>
            </MenuItem>
            <MenuItem >
                <ListItemIcon>
                    <PermIdentityIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Proveedor</ListItemText>
            </MenuItem>

        </MenuList>
        </Menu>
          </Box>
          </Grid>
        </Grid>
      </Box>
    );
  }

export function PaymentsPercentage({ progress }){

    const getProgressColor = (progress) => {
      const saturation = Math.min(100, progress);
      return `hsl(120, ${saturation}%, 50%)`;
    };
  
    return (
      <Box sx={{ width: '80%', marginTop: 2, textAlign: 'center',alignItems:"center" }}>
        <LinearProgress
          variant="determinate"
          value={progress}
          sx={{
            backgroundColor: 'lightgray',
            '& .MuiLinearProgress-bar': {
              backgroundColor: getProgressColor(progress),
            },
          }}
        />
        <Typography variant="body2" sx={{ marginTop: 1 }}>
          Total Pagado {progress}% 
        </Typography>
      </Box>
    );
}
  
export function DateSelector ({container_movements})  {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const dispatch = useAppDispatch();

  function formatToISO(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`; 
  }
  useEffect(() => {
    container_movements.map((movement)=>{
      const nowDate = new Date();
      const realDate = new Date(movement.realDate);
      if((nowDate < realDate) ){
        const startDateChile = new Date(movement.createdAt);
        const endDateChile = new Date(movement.estimatedDate);

        setStartDate(formatToISO(startDateChile));
        setEndDate(formatToISO(endDateChile));
          dispatch(setContainerMovement(movement));
          return;
      } 
    })
  }, []);

  return (
    <Box display="flex" justifyContent="center" alignItems="center" style={{ padding:'4px', height: '60%', width:'80%' }}>
      <Stack spacing={2} alignItems="center">
        <Stack spacing={0.5} alignItems="center">
          <Typography variant='body2' style={{ fontSize: '1rem' ,color: 'black'}}>Fecha de Inicio</Typography>
          <TextField type="date" value={startDate} disabled={true} onChange={(e) => setStartDate(e.target.value)} InputLabelProps={{ shrink: true }} variant="outlined" size="small" sx={{ input: { color: 'black' ,textAlign: 'center'}, '& .MuiOutlinedInput-root': { borderColor: 'white' } }}/>
        </Stack>
        <Stack spacing={0.5} alignItems="center">
          <Typography variant='body2' style={{ fontSize: '1rem',color: 'black' }}>Término de etapa</Typography>
          <TextField type="date" disabled={true} value={endDate} onChange={(e) => setEndDate(e.target.value)} InputLabelProps={{ shrink: true }} variant="outlined"  size="small" sx={{ input: { color: 'black',textAlign: 'center'  }, '& .MuiOutlinedInput-root': { borderColor: 'white' } }}
          />
        </Stack>
      </Stack>
    </Box>
  );
};