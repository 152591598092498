interface MesEntityJson {
  cantidad: number;
  ingresos: number;
  costos: number;
  mes: string;
}

interface SimulationEntityJson {
  mes_1: MesEntityJson;
  mes_2: MesEntityJson;
  mes_3: MesEntityJson;
  ganancias_totales: number;
  ingresos_totales: number;
  costos_totales: number;
  roi: number;
  productos_vendidos: number;
  productos_no_vendidos: number;
}

// Mapped Entities

interface MesEntity {
  cantidad: number;
  ingresos: number;
  costos: number;
  mes: string;
}

export interface SimulationEntity {
  mes_1: MesEntity;
  mes_2: MesEntity;
  mes_3: MesEntity;
  ganancias_totales: number;
  ingresos_totales: number;
  costos_totales: number;
  roi: number;
  productos_vendidos: number;
  productos_no_vendidos: number;
}


export function mapJsonToSimulationEntity(json: SimulationEntityJson): SimulationEntity {
  return {	
    mes_1: {
      cantidad: json.mes_1.cantidad,
      ingresos: json.mes_1.ingresos,
      costos: json.mes_1.costos,
      mes: json.mes_1.mes
    },
    mes_2: {
      cantidad: json.mes_2.cantidad,
      ingresos: json.mes_2.ingresos,
      costos: json.mes_2.costos,
      mes: json.mes_2.mes
    },
    mes_3: {
      cantidad: json.mes_3.cantidad,
      ingresos: json.mes_3.ingresos,
      costos: json.mes_3.costos,
      mes: json.mes_3.mes
    },
    ganancias_totales: json.ganancias_totales,
    ingresos_totales: json.ingresos_totales,
    costos_totales: json.costos_totales,
    roi: json.roi,
    productos_vendidos: json.productos_vendidos,
    productos_no_vendidos: json.productos_no_vendidos
  };
}
