import { Either, Usecase } from "../../../core/index";
import { ApiRepositoryImpl } from "../../../data/repository/api";

export default class GetRevenewComparisonUsecase implements Usecase<{year:number,monthly_revenue_data:{month:number,expected_revenue:number,actual_revenue:number,difference:number}[]}> {
    repository: ApiRepositoryImpl;

    constructor(repository: ApiRepositoryImpl) {
        this.repository = repository;
    }

    async execute(): Promise<Either<{year:number,monthly_revenue_data:{month:number,expected_revenue:number,actual_revenue:number,difference:number}[]}>> {
        const data = await this.repository.getRevenewComparison();

        return data;
    }
};