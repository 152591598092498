import { TransactionEntity, TransactionEntityJSON, mapJsonToTransactionEntity } from '../transaction/transaction_entity';

export interface PaymentEntityJson {
  id: string;
  amount: number;
  status: number;
  created_at: string;
  updated_at: string;
  transactions: TransactionEntityJSON[]; // El tipo puede ser any[] porque es JSON sin mapear aún
  percentage: number;
  due_date: string;
  deadline: string;
  overpayment_amount: number;
  overpayment_transactions: TransactionEntityJSON[];
  amount_usd: number;
  

}

export interface PaymentEntity {
  id: string;
  amount: number;
  status: number;
  createdAt: string;
  updatedAt: string;
  transactions: TransactionEntity[]; // Aquí usamos tu entidad TransactionEntity
  percentage: number;
  due_date: string;
  deadline: string;
  overpayment_amount: number;
  overpayment_transactions: TransactionEntity[]; // Aquí usamos tu entidad TransactionEntity
  amount_usd: number;

}

export function mapJsonToPaymentEntity(json: PaymentEntityJson): PaymentEntity {
  return {
    id: json.id,
    amount: json.amount,
    status: json.status,
    createdAt: new Date(json.created_at).toISOString(),
    updatedAt: new Date(json.updated_at).toISOString(),
    transactions: json.transactions.map(mapJsonToTransactionEntity), // Mapeamos las transacciones con tu función existente
    percentage: json.percentage,
    due_date: json.due_date,
    deadline: json.deadline,
    overpayment_amount: json.overpayment_amount,
    overpayment_transactions: json.overpayment_transactions.map(mapJsonToTransactionEntity), // Mapeamos las transacciones con tu función existente
    amount_usd: json.amount_usd
  };
}