import { Either, Usecase } from "../../../core/index";
import { ApiRepositoryImpl } from "../../../data/repository/api";

export default class GetAccountPayableUsecase implements Usecase<{total_payable:number,monthly_payable:number}> {
    repository: ApiRepositoryImpl;

    constructor(repository: ApiRepositoryImpl) {
        this.repository = repository;
    }

    async execute(): Promise<Either<{total_payable:number,monthly_payable:number}>> {
        const data = await this.repository.getAccountPayable();

        return data;
    }
};