import {  createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SimulationEntity } from "../../../domain/entities/simulator/simulation_entity";
import { reset } from "./admin_container_slice";

const initialState = {
  simulation: {} as SimulationEntity,
}

const simulationSlice = createSlice({
  name: "simulation",
  initialState,
  reducers: {
    setSimulation(state, action: PayloadAction<SimulationEntity>) {
      state.simulation = action.payload;
    },
    resetSimulation(state) {
      state.simulation = {} as SimulationEntity;
    }
  },
});

export const { setSimulation, resetSimulation } = simulationSlice.actions;
export default simulationSlice.reducer;
