import React, { FC } from 'react';
import { Box, Typography, Grid, IconButton, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import EditIcon from '@mui/icons-material/Edit';
import {  useAppSelector} from '../../hooks/hooks';
const UserProfile: FC = () => {
    const { user } = useAppSelector(state => state.user);
    const navigate = useNavigate();
    const handleOptionClick = (option) => {
        if (option==='Mi Perfil'){
            navigate("/profile/account_details");
        } else if (option==='Datos de la Empresa'){
            navigate("/profile/company_details");
        } else if (option==='Historial de Compras') {
            navigate("/historialcompras");
        } else if (option==='Pagos Pendientes'){
            navigate("/customer-dashboard");
        }
    };

    const handleEditClick = () => {
        //console.log("Edit clicked");
    };

    const options = [
        { icon: '👤', title: 'Mi Perfil', subtitle: 'Información personal y datos de la cuenta' },
        { icon: '🏢', title: 'Datos de la Empresa', subtitle: 'Información y detalles de la empresa' },
        { icon: '📊', title: 'Historial de Compras', subtitle: 'Ver todas mis compras' },
        { icon: '📈', title: 'Pagos Pendientes', subtitle: 'Revisar pagos por realizar' }
    ];

    return (
        <Box sx={{ padding: 4, alignContent:'center' }}>
            <Grid container direction="column" alignItems="center" spacing={2}>
                {/* { <Grid item>
                    <Box sx={{ position: 'relative', display: 'flex', justifyContent: 'center' }}>
                        <img
                            src="https://definicion.de/wp-content/uploads/2019/07/perfil-de-usuario.png"
                            alt="Perfil"
                            style={{ width: '150px', height: '150px', borderRadius: '50%' }}
                        />
                        { <IconButton
                            sx={{
                                position: 'absolute',
                                bottom: '5px',
                                right: '0px', 
                                bgcolor: 'white',
                                borderRadius: '50%',
                                boxShadow: 2,
                                zIndex: 10,
                                '&:hover': {
                                    bgcolor: 'rgba(255, 255, 255, 0.8)',
                                },
                            }}
                            onClick={handleEditClick}
                        >
                            <EditIcon />
                        </IconButton> }
                    </Box>
                </Grid> } */}
                <Grid item>
                    <Typography variant="h6">{`${user?.first_name} ${user?.last_name}` } </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={2} justifyContent="center" sx={{ marginTop: 3, width: '70%', margin: '0 auto' }}>
                <Grid item xs={12} md={10}>
                    {options.map((option, index) => (
                        <Paper 
                            key={index}
                            elevation={2} 
                            sx={{ display: 'flex', alignItems: 'center', padding: 2, cursor: 'pointer', marginBottom: 1 }} 
                            onClick={() => handleOptionClick(option.title)}
                        >
                            <Typography sx={{ fontSize: 30, marginRight: 2 }}>{option.icon}</Typography>
                            <Box sx={{ flexGrow: 1 }}>
                                <Typography variant="h6">{option.title}</Typography>
                                <Typography variant="body2">{option.subtitle}</Typography>
                            </Box>
                            <IconButton>
                                <ArrowForwardIcon />
                            </IconButton>
                        </Paper>
                    ))}
                </Grid>
            </Grid>

        </Box>
    );
};

export default UserProfile;
