// In your event file
import { Dispatch } from 'redux';
import apiRepository from "../../../data/repository";
import { SimulationEntity } from '../../../domain/entities';
import PostSimulationUseCase from "../../../domain/usecases/post_simulation_usecase";
import { setSimulation } from "../../redux/slices/simulator_slice";
import { setStatus, StatusLayoutEnum } from '../../redux/slices/layout_slice';

export const PostSimulationEvent = async (
  precio_venta: number,
  precio_compra: number,
  cantidad: number,
  product_id: string,
  dispatch: Dispatch
): Promise<SimulationEntity | null> => {
  const usecase = new PostSimulationUseCase(apiRepository);

  try {
    const result = await usecase.execute({
      precio_compra,
      precio_venta,
      cantidad,
      product_id,
    });

    if (result.isLeft()) {
      const error = result.left;

      // Handle 400 error specifically
      //console.log("Error:", error.statusCode);
      if (error) {
        throw new Error("El ID del producto no es válido.");
      }

      throw new Error(error.message || "Error desconocido");
    }

    const simulationEntity = result.right;
    if (!simulationEntity) {
      throw new Error("No se pudo obtener la simulación");
    }

    dispatch(setSimulation(simulationEntity));
    dispatch(setStatus(StatusLayoutEnum.Success));

    return simulationEntity;
  } catch (error) {
    throw error; 
  }
};
