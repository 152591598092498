import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, Modal } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { GetProformasEvent } from "../../events/proforma/get_proformas_event";
import { GetCompanyInvestmentsEvent } from "../../events/company/get_company_investments_event";
import MultiTransaction from "../../components/user_dashboard/jumbo_transaction";
import ContainersTable from "../../components/user_dashboard/containers_table";
import ProformasTable from "../../components/user_dashboard/proformas_table";
import PendingPayments from "../../components/user_dashboard/pending_payments";
import ContainerStatusBarChart from "../../components/user_dashboard/container_status_bar_chart";
import AssignmentIcon from "@mui/icons-material/Assignment";
import PaymentIcon from "@mui/icons-material/Payment";
import DirectionsBoatFilledIcon from "@mui/icons-material/DirectionsBoatFilled";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";

const CustomerDashboard: React.FC = () => {
  const dispatch = useAppDispatch();
  const { proformas } = useAppSelector((state) => state.proformas);
  const { companyInvestments } = useAppSelector(
    (state) => state.company_investments
  );

  const [openContainersModal, setOpenContainersModal] = useState(false);
  const [openProformasModal, setOpenProformasModal] = useState(false);
  const [openPaymentsModal, setOpenPaymentsModal] = useState(false);

  useEffect(() => {
    dispatch(GetProformasEvent());
    dispatch(GetCompanyInvestmentsEvent());
  }, [dispatch]);



  const proformasFiltradas = proformas.filter(
    (proforma) => proforma.current_step !== "Entregado"
  );
  const formatAmount = (amount, currency = "CLP") => {
    const numericAmount = Number(amount);
  
    if (isNaN(numericAmount)) return amount;
  
    if (currency === "CLP") {
      // Formato para CLP: punto entre miles, coma para decimales
      return numericAmount.toLocaleString("es-CL", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    } else if (currency === "USD") {
      // Formato para USD: punto decimal, dos decimales
      return numericAmount.toLocaleString("en-US", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    }
  
    return amount; // Retorno sin formato si no se especifica una moneda válida



  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        minHeight: "100vh",
        position: "relative",
        paddingTop: "20px",
        paddingBottom: "60px",
        paddingX: "20px",
        backgroundColor: "#f5f5f5",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          gap: "20px",
          marginBottom: "30px",
        }}
      >
        <Box
          sx={{
            backgroundColor: "white",
            borderRadius: "5px",
            padding: "20px",
            boxShadow: 2,
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <MonetizationOnIcon sx={{ color: "#0A66A5", fontSize: "30px" }} />
          <Typography
            variant="h6"
            sx={{ color: "#0A66A5", fontWeight: "bold", marginTop: "10px" }}
          >
            Monto Total Invertido
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: "#0A66A5", fontWeight: "bold", marginTop: "5px" }}
          >
            ${formatAmount(companyInvestments[0]?.totalAmount, "CLP")} CLP / $
            {formatAmount(companyInvestments[0]?.totalAmountUsd, "USD")} USD
          </Typography>
        </Box>

        <Box
          onClick={() => setOpenContainersModal(true)}
          sx={{
            backgroundColor: "white",
            borderRadius: "5px",
            padding: "20px",
            boxShadow: 2,
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <DirectionsBoatFilledIcon
            sx={{ color: "#0A66A5", fontSize: "30px" }}
          />
          <Typography
            variant="h6"
            sx={{ color: "#0A66A5", fontWeight: "bold", marginTop: "10px" }}
          >
            Contenedores
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: "#979797", marginTop: "5px" }}
          >
            Ver detalles
          </Typography>
        </Box>

        <Box
          onClick={() => setOpenProformasModal(true)}
          sx={{
            backgroundColor: "white",
            borderRadius: "5px",
            padding: "20px",
            boxShadow: 2,
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <AssignmentIcon sx={{ color: "#0A66A5", fontSize: "30px" }} />
          <Typography
            variant="h6"
            sx={{ color: "#0A66A5", fontWeight: "bold", marginTop: "10px" }}
          >
            Proformas
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: "#979797", marginTop: "5px" }}
          >
            Ver detalles
          </Typography>
        </Box>

        <Box
          onClick={() => setOpenPaymentsModal(true)}
          sx={{
            backgroundColor: "white",
            borderRadius: "5px",
            padding: "20px",
            boxShadow: 2,
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <PaymentIcon sx={{ color: "#0A66A5", fontSize: "30px" }} />
          <Typography
            variant="h6"
            sx={{ color: "#0A66A5", fontWeight: "bold", marginTop: "10px" }}
          >
            Pagos Pendientes
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: "#979797", marginTop: "5px" }}
          >
            Revisar pagos
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          gap: "20px",
        }}
      >
        <Box
          sx={{
            flex: 3,
            backgroundColor: "white",
            borderRadius: "5px",
            padding: "20px",
          }}
          >
          <Typography
            variant="h6"
            sx={{
              color: "#0A66A5",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Estados de Contenedores
          </Typography>
          <Box
            sx={{
              height: "300px",
              marginTop: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {companyInvestments[0]?.containers ? (
              <ContainerStatusBarChart containers={companyInvestments[0].containers} />
            ) : (
              <Typography variant="body1" sx={{ color: "#979797", textAlign: "center" }}>
                No hay datos disponibles para mostrar.
              </Typography>
            )}
          </Box>
        </Box>

        <Box
          sx={{
            flex: 2,
            backgroundColor: "white",
            borderRadius: "5px",
            padding: "20px",
          }}
        >
          <MultiTransaction proformas={proformas} />
        </Box>
      </Box>

      {/* Modales */}
      <Modal open={openContainersModal} onClose={() => setOpenContainersModal(false)}>
            <Box
            sx={{
              width: "80%",
              margin: "auto",
              mt: 5,
              backgroundColor: "white",
              borderRadius: "10px",
              boxShadow: 24,
              p: 4,
              maxHeight: "90vh",
              overflowY: "auto",
              display: "flex",
              gap: "20px",
            }}
            >
            <ContainersTable
              containers={companyInvestments[0]?.containers}
              title="Contenedores No Entregados"
            />
            <ContainersTable
              containers={companyInvestments[0]?.containers}
              title="Contenedores Entregados"
            />

            </Box>
      </Modal>

      <Modal open={openProformasModal} onClose={() => setOpenProformasModal(false)}>
        <Box
          sx={{
            width: "80%",
            margin: "auto",
            mt: 5,
            backgroundColor: "white",
            borderRadius: "10px",
            boxShadow: 24,
            p: 4,
            maxHeight: "90vh",
            overflowY: "auto",
            display: "flex",
            gap: "20px",
          }}
          >
          <ProformasTable
              proformas={companyInvestments[0]?.proformas}
              title="Proformas No Completadas"
            />
            <ProformasTable
              proformas={companyInvestments[0]?.proformas}
              title="Proformas Completadas"
            />
        </Box>
      </Modal>

      <Modal open={openPaymentsModal} onClose={() => setOpenPaymentsModal(false)}>
        <Box
          sx={{
            width: "50%",
            margin: "auto",
            mt: 5,
            backgroundColor: "white",
            borderRadius: "10px",
            boxShadow: 24,
            p: 4,
            maxHeight: "90vh",
            overflowY: "auto",
          }}
        >
          <PendingPayments proformas={proformas} />
        </Box>
      </Modal>
    </Box>
  );
};

export default CustomerDashboard;
