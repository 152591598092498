import React, { Fragment, useState, ChangeEvent } from "react";
import {
  Card,
  Typography,
  Grid,
  Button,
  TextField,
  Divider,
  Box,
  Chip,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
} from "@mui/material";
import { isAfter, isBefore } from "date-fns";
import { useAppSelector, useAppDispatch } from "../../hooks/hooks";
import { setStatus, StatusLayoutEnum } from "../../redux/slices/layout_slice";
import { CreateTransactionEvent } from "../../events/proforma/create_transaction_event";
import { addTransactionToPayment } from "../../redux/slices/proforma_slice";
import { PaymentEntity } from "../../../domain/entities";
import { RootState } from "../../redux";
import TransactionList from "./transaction_list";

interface PaymentCardProps {
  payment: PaymentEntity;
}

const PaymentCard: React.FC<PaymentCardProps> = ({ payment }) => {
  const dispatch = useAppDispatch();
  const [transactionAmount, setTransactionAmount] = useState<number | string>(
    ""
  );
  const [currency, setCurrency] = useState<"USD" | "CLP">("USD");
  const { usdToClp } = useAppSelector(
    (state: RootState) => state.exchange_rate
  );

  const [file, setFile] = useState<File | null>(null);

  const getStatusText = (status: number) => {
    switch (status) {
      case 0:
        return "Pendiente";
      case 1:
        return "Aprobado";
      case 2:
        return "Rechazado";
      case 3:
        return "Failed";
      default:
        return "Unknown";
    }
  };

  const totalTransactionAmount = payment.transactions.reduce(
    (total, transaction) => total + transaction.amount,
    0
  );
  const totalTransactionAmountUSD = payment.transactions.reduce(
    (total, transaction) => total + transaction.amount_usd,
    0
  );
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile && selectedFile.type === "application/pdf") {
      setFile(selectedFile);
    } else {
      alert("Favor seleccione un archivo en formato PDF válido.");
    }
  };

  const handleGenerateOrder = () => {
    if (!file) {
      alert("Favor seleccione un archivo.");
      return;
    }

    const amountUsd =
      currency === "CLP" && usdToClp
        ? Number(transactionAmount) / usdToClp
        : Number(transactionAmount);

    const amountClp =
      currency === "USD" && usdToClp
        ? Number(transactionAmount) * usdToClp
        : Number(transactionAmount);

    dispatch(setStatus(StatusLayoutEnum.Loading));

    CreateTransactionEvent(file, payment.id, amountClp, amountUsd)
      .then((transaction) => {
        dispatch(
          addTransactionToPayment({ paymentId: payment.id, transaction })
        );
        setTransactionAmount("");
        setFile(null);
      })
      .finally(() => {
        dispatch(setStatus(StatusLayoutEnum.Success));
      });
  };

  const handleCurrencyChange = (event: SelectChangeEvent<"USD" | "CLP">) => {
    setCurrency(event.target.value as "USD" | "CLP");
  };

  const convertedAmount =
    currency === "USD"
      ? usdToClp && transactionAmount
        ? (Number(transactionAmount) * usdToClp).toFixed(0)
        : ""
      : usdToClp && transactionAmount
      ? (Number(transactionAmount) / usdToClp).toFixed(2)
      : "";

  const currentDate = new Date();
  const cleanDate = (dateString: string) => {
    const [date] = dateString.split(" a las");
    return new Date(date.trim());
  };

  const isBetweenDueAndDeadline =
    isBefore(currentDate, cleanDate(payment.deadline)) &&
    isAfter(currentDate, cleanDate(payment.due_date));

  const isPastDeadline = isAfter(currentDate, cleanDate(payment.deadline));

  
  const formatAmount = (amount, currency = "CLP") => {
    const numericAmount = Number(amount);
  
    if (isNaN(numericAmount)) return amount;
  
    if (currency === "CLP") {
      // Formato para CLP: punto entre miles, coma para decimales
      return numericAmount.toLocaleString("es-CL", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    } else if (currency === "USD") {
      // Formato para USD: punto decimal, dos decimales
      return numericAmount.toLocaleString("en-US", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    }
  
    return amount; // Retorno sin formato si no se especifica una moneda válida
  };

  return (
    <Fragment>
      <Card
        elevation={3}
        sx={{
          padding: "16px",
          marginBottom: "10px",
          borderRadius: "8px",
          position: "relative",
        }}
      >
        <Box sx={{ position: "absolute", top: "16px", right: "16px" }}>
          <Typography variant="body2" sx={{ color: "GrayText" }}>
            {Math.round(payment.percentage * 100)}%
          </Typography>
        </Box>
        <Typography variant="h6" sx={{ color: "#0A66A5", fontWeight: 600 }}>
          ID de Pago: {payment.id}
        </Typography>
        <Typography variant="body2" sx={{ fontWeight: 600 }}>
          Monto: $ {formatAmount((payment.amount_usd*usdToClp), "CLP")} CLP -  $ {formatAmount(payment.amount_usd, "USD")} USD
        </Typography>
        <Typography variant="body2">
          Total Pagado: $ {formatAmount(totalTransactionAmount, "CLP")} CLP - $ {formatAmount(totalTransactionAmountUSD, "USD")} USD
        </Typography>
        <Typography variant="body2" sx={{ color: "GrayText" }}>
          Status: {getStatusText(payment.status)}
        </Typography>
        <Box sx={{ marginTop: "16px" }}>
          {payment.amount > totalTransactionAmount &&
            isBetweenDueAndDeadline && (
              <Chip label="Pendiente de Pago" color="warning" />
            )}
          {payment.amount > totalTransactionAmount && isPastDeadline && (
            <Chip label="Pago Expirado" color="error" />
          )}
          {payment.amount <= totalTransactionAmount && (
            <Chip label="Pago Completado" color="success" />
          )}
        </Box>
        <TransactionList payment={payment} />
        <Divider sx={{ marginY: 4 }} />
        <Box
          sx={{
            padding: "16px",
            backgroundColor: "#f9f9f9",
            borderRadius: "8px",
          }}
        >
          {payment.status === 0 ? (
            <>
              <Typography
                variant="h6"
                sx={{ color: "#0A66A5", fontWeight: 570, marginBottom: "20px" }}
              >
                Agregar Nueva Transacción
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Moneda</InputLabel>
                    <Select
                      value={currency}
                      onChange={handleCurrencyChange}
                      label="Moneda"
                    >
                      <MenuItem value="USD">USD</MenuItem>
                      <MenuItem value="CLP">CLP</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={`Monto en ${currency}`}
                    type="number"
                    fullWidth
                    value={transactionAmount}
                    onChange={(e) => {
                      const value = Number(e.target.value);
                      if (value >= 0 || e.target.value === "") {
                        setTransactionAmount(e.target.value);
                      }
                    }}
                    error={Number(transactionAmount) < 0}
                    helperText={
                      Number(transactionAmount) < 0
                        ? "El monto no puede ser un número negativo."
                        : ""
                    }
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={`Monto en ${currency === "USD" ? "CLP" : "USD"}`}
                    type="text"
                    fullWidth
                    value={convertedAmount}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                {file && (
                  <Grid item xs={12}>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{ textAlign: "center" }}
                    >
                      Archivo seleccionado: {file.name}
                    </Typography>
                  </Grid>
                )}
                <Grid item container justifyContent="space-between" spacing={2}>
                  <Grid item>
                    <form>
                      <input
                        accept="application/pdf"
                        id="contained-button-file"
                        type="file"
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                      />
                      <label htmlFor="contained-button-file">
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                        >
                          Seleccionar archivo
                        </Button>
                      </label>
                    </form>
                  </Grid>
                  <Grid item>
                    <Button
                      disabled={
                        !file ||
                        !transactionAmount ||
                        Number(transactionAmount) < 0
                      }
                      variant="contained"
                      color="success"
                      onClick={handleGenerateOrder}
                    >
                      Hacer Abono
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : (
            <Typography variant="body2" color="error">
              No es posible agregar transacciones. Este pago no se encuentra en
              estado pendiente.
            </Typography>
          )}
        </Box>
      </Card>
    </Fragment>
  );
};

export default PaymentCard;
