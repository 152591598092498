import React, { useState } from "react";
import Badge, { badgeClasses } from "@mui/material/Badge";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import Search from "./Search";
import DivisasMenu from "./Divisa";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Stack,
  Menu,
  MenuItem,
  ListItemIcon,
} from "@mui/material";
import NotificationsRoundedIcon from "@mui/icons-material/NotificationsRounded";
import { LogoutEvent } from "../../../events/user/logout_event";
import { useAppDispatch } from "../../../hooks/hooks";
import { useNavigate } from "react-router-dom";

export interface MenuButtonProps extends IconButtonProps {
  showBadge?: boolean;
}

function MenuButton({ showBadge = false, ...props }: MenuButtonProps) {
  return (
    <Badge
      color="error"
      variant="dot"
      invisible={!showBadge}
      sx={{ [`& .${badgeClasses.badge}`]: { right: 2, top: 2 } }}
    >
      <IconButton size="small" {...props}>
        <NotificationsRoundedIcon sx={{ color: "white" }} />
      </IconButton>
    </Badge>
  );
}

export default function Header() {
  
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);



  const handleLogout = () => {
    dispatch(LogoutEvent());
  };

  

  const handleProfileClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const handleLogoutMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation(); // Evita que el evento se propague
    handleLogout();
    handleClose();
    navigate("/");
  };



  return (
    <Box sx={{ flexGrow: 1, width: "100%" }}>
      <AppBar position="static" sx={{ backgroundColor: "#0A66A5" }}>
        <Toolbar>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { xs: "none", sm: "block", color: "white" } }}
          >
            Contandem
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Stack
  direction="row"
  spacing={2}
  sx={{ display: { xs: "none", md: "flex" }, color: "white" }}
>
  <DivisasMenu />
  <Box sx={{ flexGrow: 1 }} /> {/* Este espacio empuja Search hacia la derecha */}
  <Search />
  <IconButton
    size="large"
    edge="end"
    onClick={handleProfileClick}
    sx={{ color: "white" }}
  >
    <AccountCircleIcon />
  </IconButton>
</Stack>

        </Toolbar>
      </AppBar>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        
        <MenuItem onClick={handleLogoutMenu}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          Cerrar Sesión
        </MenuItem>
      </Menu>
    </Box>
  );
}
