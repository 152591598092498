export interface ExternalPaymentEntity {
    id: string;
    supplierId: string;
    containerId: string;
    amount: number;
    amount_usd: number;
    description: string;
    createdAt: string;
  }
  
  export interface ExternalPaymentEntityJSON {
    id: string;
    supplier: string;
    container: string;
    amount: string;
    amount_usd: string;
    description: string;
    created_at: string;
    
  
  }
  
  
  export function mapJsonToExternalPaymentEntity(json: ExternalPaymentEntityJSON): ExternalPaymentEntity {
    return {
      id: json.id,
      supplierId: json.supplier,
      containerId: json.container,
      amount: parseFloat(json.amount),
      amount_usd: parseFloat(json.amount_usd),
      description: json.description,
      createdAt: json.created_at,
    } as ExternalPaymentEntity;
  }