import React ,{useEffect,useState} from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { Grid, Box,Stack,Typography,LinearProgress} from '@mui/material';
import Header from '../../components/cfo/layout/Header';
import SideMenu from '../../components/cfo/layout/SideMenu';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import SensorOccupiedIcon from '@mui/icons-material/SensorOccupied';
import DirectionsBoatFilledIcon from '@mui/icons-material/DirectionsBoatFilled';
import {PieChart,BarChart,Bar,Cell, XAxis,YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,Pie} from 'recharts';
import { useAppDispatch } from '../../hooks/hooks';
import {GetRevenewComparisonEvent} from '../../events/cfo/get_revenew_comparison_event';
import {GetContainerCountvent} from '../../events/cfo/get_container_count_event';
import {GetActiveUsersEvent} from  '../../events/cfo/get_active_users_event';
import {GetActiveRegionsEvent} from '../../events/cfo/get_active_regions_event';
import {GetAccountReceivableEvent} from '../../events/cfo/get_account_receivable_event';
import {GetAccountPayableEvent} from '../../events/cfo/get_acount_payable_event';
import RestrictionComponent from '../../components/restriction/restriction';

const monthMapping = {
  1: "Ene.",
  2: "Feb.",
  3: "Mar.",
  4: "Abr.",
  5: "May.",
  6: "Jun.",
  7: "Jul.",
  8: "Ago.",
  9: "Sep.",
  10: "Oct.",
  11: "Nov.",
  12: "Dic."
};



const COLORS = [
  '#0088FE', // Azul
  '#00C49F', // Verde
  '#FFBB28', // Amarillo
  '#FF8042', // Naranja
  '#A569BD', // Morado
  '#2ECC71', // Verde brillante
  '#3498DB', // Azul claro
  '#E74C3C', // Rojo
  '#F1C40F', // Dorado
  '#1ABC9C', // Turquesa
  '#D35400', // Naranja oscuro
  '#C0392B', // Rojo oscuro
  '#7F8C8D', // Gris
  '#34495E', // Azul profundo
  '#9B59B6', // Lila
];


export default function CfoDashboard() {
  const dispatch = useAppDispatch();
  const [monthly_debt,setMonthlyDebt] = useState(0);
  const [total_debt,setTotalDebt] = useState(0);
  const [ActiveUsers,setActiveUsers] = useState(0);
  const [in_factory, setInFactory] = useState(0);
  const [in_route,setInRoute] = useState(0);
  const [total_payable,setTotalPayable] = useState(0);
  const [monthly_payable, setMonthlyPayable] = useState(0);
  const [regions, setRegions] = useState<{ region: string; percentage: number }[]>([]);
  const [dataGraphic,setDataGraphic] = useState<{month:string,value1:number,value2:number}[]>([]);
  const [totalClients,setTotalClients] = useState(10);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [revenew, containers, activeUsers, activeRegion, accountReceivable,accountPayable] = await Promise.all([
          dispatch(GetRevenewComparisonEvent()),
          dispatch(GetContainerCountvent()),
          dispatch(GetActiveUsersEvent()),
          dispatch(GetActiveRegionsEvent()),
          dispatch(GetAccountReceivableEvent()),
          dispatch(GetAccountPayableEvent())
        ]);

        setMonthlyDebt(accountReceivable.monthly_debt)
        setTotalDebt( accountReceivable.total_debt)
        setActiveUsers(activeUsers.ActiveUsers);
        setInFactory(containers.in_factory);
        setInRoute(containers.in_route);
        setTotalPayable(accountPayable.total_payable);
        setMonthlyPayable(accountPayable.monthly_payable);
        setRegions(activeRegion.active_users_percentage);
        setTotalClients(activeUsers.total_user_count);
        const data = revenew.monthly_revenue_data.map(item => ({
          month: monthMapping[item.month],
          value1: item.actual_revenue,
          value2: item.expected_revenue
        }));
        setDataGraphic(data);
        // console.log({
        //   revenew,
        //   containers,
        //   activeUsers,
        //   activeRegion,
        //   accountReceivable,
        // });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    fetchData();
  }, []);
  

  return (
    <>
      <RestrictionComponent auth='CFO'/>
      <CssBaseline enableColorScheme />
      <Box sx={{ display: 'flex' }}>
        <SideMenu />
        <Box
          component="main"
          sx={() => ({
            flexGrow: 1,
            backgroundColor:'#f0f0f0',
          })}
        >
          <Stack
            spacing={2}
            sx={{
              alignItems: 'center',
              pb: 10,
              mt: { xs: 8, md: 0 },
            }}
          >
            <Header />
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Grid container  spacing={2} sx={{ height: '30vh',minHeight:"200px", width: '95%' }}>
                <Grid item xs={3}>
                  <Grid container sx={{ height: '100%',backgroundColor:'white',borderRadius: '5px', padding:'10px'}} direction="column">
                    <Grid item sx={{ height: '30%' }}>
                      <Box sx={{ width: '100%',height:'100%', display: 'flex', alignItems: 'center', gap: 1 }}>
                        <MonetizationOnIcon sx={{ color: '#0A66A5',fontSize:'30px' }} />
                          <Typography variant="h6" sx={{ color: '#0A66A5' }}>
                            Cuentas por cobrar
                          </Typography>
                      </Box>
                    </Grid>
                    <Grid item sx={{ height: '15%' }}>
                      <Box sx={{ width: '100%',display: 'flex',height:'100%', alignItems: 'center'}}>
                        <Typography variant="subtitle1" sx={{color:'#979797'}}>Este mes</Typography>
                      </Box>
                    </Grid>
                    <Grid item sx={{ height: '35%' }}>
                      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center',height:'100%'}}>
                      <Typography variant="h5">
                        <strong>${Math.trunc(Math.abs(monthly_debt)).toLocaleString("es-CL")} </strong>
                        <span style={{ color: '#979797' }}>CLP</span> {/* Cambia el color de "CLP" */}
                      </Typography>
                      </Box>
                    </Grid>
                    <Grid item sx={{ height: '20%' }}>
                      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center',height:'100%'}}>
                        <Typography variant="body1">{`TOTAL:  $${Math.trunc(total_debt).toLocaleString("es-CL")}  CLP`}</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={3} >
                  <Grid container sx={{ height: '100%',backgroundColor:'white',borderRadius: '5px', padding:'10px'}} direction="column">
                    <Grid item sx={{ height: '30%' }}>
                      <Box sx={{ width: '100%',height:'100%', display: 'flex', alignItems: 'center', gap: 1 }}>
                        <MonetizationOnIcon sx={{ color: '#0A66A5',fontSize:'30px' }} />
                          <Typography variant="h6" sx={{ color: '#0A66A5' }}>
                            Cuentas por Pagar
                          </Typography>
                      </Box>
                    </Grid>
                    <Grid item sx={{ height: '15%' }}>
                      <Box sx={{ width: '100%',display: 'flex',height:'100%', alignItems: 'center'}}>
                        <Typography variant="subtitle1" sx={{color:'#979797'}}>Este mes</Typography>
                      </Box>
                    </Grid>
                    <Grid item sx={{ height: '35%' }}>
                      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center',height:'100%'}}>
                      <Typography variant="h5">
                        <strong>${Math.trunc(Math.abs(monthly_payable)).toLocaleString("es-CL")} </strong>
                        <span style={{ color: '#979797' }}>CLP</span> {/* Cambia el color de "CLP" */}
                      </Typography>
                      </Box>
                    </Grid>
                    <Grid item sx={{ height: '20%' }}>
                      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center',height:'100%'}}>
                        <Typography variant="body1">{`TOTAL:  $${Math.trunc(total_payable).toLocaleString("es-CL")}  CLP`}</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={3}>
                  <Grid container sx={{ height: '100%',backgroundColor:'white', borderRadius: '5px', padding:'10px'}} direction="column">
                    <Grid item sx={{ height: '30%' }}>
                      <Box sx={{ width: '100%',height:'100%', display: 'flex', alignItems: 'center', gap: 1 }}>
                        <SensorOccupiedIcon sx={{ color: '#0A66A5',fontSize:'30px' }}/>
                          <Typography variant="h6"  sx={{ color: '#0A66A5' }}>
                          Clientes activos
                          </Typography>
                      </Box>
                    </Grid>
                    <Grid item sx={{ height: '40%' }}>
                      <Box sx={{ width: '100%', display: 'flex', alignItems: 'center',height:'100%'}}>
                      <Typography variant="h5">
                        <strong>{`${ActiveUsers} `}  </strong>
                        <span style={{ color: '#979797' }}>Clientes</span> {/* Cambia el color de "CLP" */}
                      </Typography>
                      </Box>
                    </Grid>
                    <Grid item sx={{ height: '30%' }}>
                      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center',height:'100%'}}>
                      <LinearProgress
                          variant="determinate"
                          value={(ActiveUsers/totalClients)*100}
                          sx={{
                            width: '100%',
                            borderRadius:'5px',
                            height: 10,
                            backgroundColor: 'lightgray',
                            '& .MuiLinearProgress-bar': {
                              backgroundColor: '#0A66A5', 
                            },
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={3}>
                  <Grid container sx={{ height: '100%',backgroundColor:'white', borderRadius: '5px', padding:'10px'}} direction="column">
                    <Grid item sx={{ height: '30%' }}>
                      <Box sx={{ width: '100%',height:'100%', display: 'flex', alignItems: 'center', gap: 1 }}>
                        <DirectionsBoatFilledIcon sx={{ color: '#0A66A5',fontSize:'30px' }}/>
                          <Typography variant="h6"  sx={{ color: '#0A66A5' }}>
                          Contenedores
                          </Typography>
                      </Box>
                    </Grid>
                    <Grid item sx={{ height: '35%' }}>
                      <Box sx={{ width: '100%', display: 'flex',justifyContent: 'flex-end', alignItems: 'center',height:'100%'}}>
                        <Typography variant="body1">
                          {"En ruta     "}
                          <Typography component="span" variant="h5" sx={{ fontWeight: 'bold' }}>
                            {`${in_route} `}
                          </Typography>
                          <span style={{ color: '#979797' }}>contenedores</span>
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item sx={{ height: '35%' }}>
                      <Box sx={{ width: '100%', display: 'flex',justifyContent: 'flex-end', alignItems: 'center',height:'100%'}}>
                        <Typography variant="body1">
                          {"En fábrica  "}
                          <Typography component="span" variant="h5" sx={{ fontWeight: 'bold' }}>
                          {`${in_factory} `}
                          </Typography>
                          <span style={{ color: '#979797' }}> contenedores</span>
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Grid container  spacing={2} sx={{minHeight:"400px", width: '95%' }}>
                <Grid item xs={8}>
                  <Grid container sx={{ height: '100%',backgroundColor:'white',borderRadius: '5px', padding:'10px'}} direction="column">
                    <Grid item sx={{ height: '10%' }}>
                      <Box sx={{ width: '100%',height:'100%', display: 'flex', alignItems: 'center',justifyContent:'center'}}>
                          <Typography variant="h6"  sx={{ color: '#0A66A5' ,textAlign:'center'}}>
                          <strong>Ingresos vs. Ingresos esperados</strong>
                          </Typography>
                      </Box>
                    </Grid>
                    <Grid item sx={{ height: '85%' }}>
                      <Box
                        sx={{
                          width: '100%',
                          height: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <ResponsiveContainer width="100%" height="90%">
                          <BarChart data={dataGraphic} margin={{ top: 20, right: 30, left: 30, bottom: 5 }}>
                            <CartesianGrid strokeDasharray="0" vertical={false} stroke="#ccc" />
                            <XAxis dataKey="month" />
                            <YAxis
                                tickFormatter={(value) => {
                                  if (value >= 1e6) {
                                    return `${(value / 1e6).toFixed(0)} mill`;
                                  }
                                  return value.toLocaleString("es-CL", { maximumFractionDigits: 0 });
                                }}
                                tickCount={11}
                              />
                            <Tooltip
                                formatter={(value) => {
                                  if (typeof value === "number") {
                                    return Math.trunc(value).toLocaleString("es-CL", { maximumFractionDigits: 0 });
                                  }
                                  return value;
                                }}
                              />
                            <Legend />
                            <Bar dataKey="value1" fill="#0A66A5" name="Ingresos" />
                            <Bar dataKey="value2" fill="#4ca5d990" name="Ingresos esperados" />
                          </BarChart>
                        </ResponsiveContainer>


                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Grid container sx={{ height: '100%',backgroundColor:'white',borderRadius: '5px', padding:'10px'}} direction="column">
                    <Grid container sx={{ height: '100%',backgroundColor:'white',borderRadius: '5px', padding:'10px'}} direction="column">
                      <Grid item sx={{ height: '10%' }}>
                        <Box sx={{ width: '100%',height:'100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <Typography variant="h6"  sx={{ color: '#0A66A5' ,textAlign:'center'}}>
                            <strong> Zonas clientes activos</strong>
                            </Typography>
                        </Box>
                      </Grid>
                      <Grid item sx={{ height: '85%' }}>
                        <Box
                          sx={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <ResponsiveContainer width="100%" height={400}>
                            <PieChart>
                              <Pie
                                data={regions}
                                dataKey="percentage"
                                nameKey="region"
                                cx="50%"
                                cy="50%"
                                innerRadius={70}
                                outerRadius={100}
                                fill="#8884d8"
                                label
                              >
                                {regions.map((entry, index) => (
                                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                              </Pie>
                              <Tooltip />
                              <Legend />
                            </PieChart>
                          </ResponsiveContainer>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Stack>
        </Box>
      </Box>
      </>
  );
}