export interface CompanyPaymentsEntity {
    id: string;
    rut: string;
    name: string;
    owner: string;
    address: string;
    phone: string;
    email: string;
    total_amount: number;
    amount_paid: number;
    debt: number;
    total_amount_usd: number;
    amount_paid_usd: number;
    debt_usd: number;
    proformas: any;
}
export function mapJsonToCompanyPaymentsEntity(json): CompanyPaymentsEntity {
    return {
        id: json.id,
        rut: json.rut,
        name: json.name,
        owner: json.owner,
        address: json.address,
        phone: json.phone,
        email: json.email,
        total_amount: json.total_amount,
        amount_paid: json.amount_paid,
        debt: json.debt,
        total_amount_usd: json.total_amount_usd,
        amount_paid_usd: json.amount_paid_usd,
        debt_usd: json.debt_usd,
        proformas: json.proformas
    } as CompanyPaymentsEntity;
}