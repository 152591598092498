import React from "react";
import { Box, Paper, Typography, Divider, LinearProgress } from "@mui/material";
import { CompanyPaymentsEntity } from "../../../../domain/entities";
import ProformasTable from "./ProformasTable";

interface CompanyPaymentsProps {
  payments: CompanyPaymentsEntity[];
}

const formatAmount = (amount, currency = "CLP") => {
  const numericAmount = Number(amount);

  if (isNaN(numericAmount)) return amount;

  if (currency === "CLP") {
    // Formato para CLP: punto entre miles, coma para decimales
    return numericAmount.toLocaleString("es-CL", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  } else if (currency === "USD") {
    // Formato para USD: punto decimal, dos decimales
    return numericAmount.toLocaleString("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  }

  return amount; // Retorno sin formato si no se especifica una moneda válida
};

const CompanyPayments: React.FC<CompanyPaymentsProps> = ({ payments }) => {
  const [currentProforma, setCurrentProforma] = React.useState(null);

  const getStatusLabel = (status: number) => {
    switch (status) {
      case 1:
        return "Aceptada";
      case 2:
        return "Rechazada";
      default:
        return "Pendiente";
    }
  };
  //console.log("PATMENTWS: ", payments)

  return (
    <Box sx={{ marginTop: "40px", textAlign: "left", width: "100%" }}>
      {payments.map((payment) => {
        const paidPercentage =
          (payment.amount_paid / payment.total_amount) * 100;

        const now = new Date();
        const activeProformas = payment.proformas.filter(
          (proforma) => new Date(proforma.proforma_deadline) >= now
        );
        const oldProformas = payment.proformas.filter(
          (proforma) => new Date(proforma.proforma_deadline) < now
        );

        return (
          <Box key={payment.id} sx={{ marginBottom: "20px" }}>
            <Paper
              elevation={3}
              sx={{
                padding: "20px",
                borderRadius: "8px",
                maxWidth: "1100px",
                margin: "auto",
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  marginBottom: "20px",
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "#0A66A5",
                }}
              >
                Pagos de la Compañía
              </Typography>

              <Typography variant="subtitle1">
                <span style={{ fontWeight: "bold" }}>Total a pagar:</span> $
                {formatAmount(payment.total_amount, "CLP")} CLP / $
                {formatAmount(payment.total_amount_usd, "USD")} USD
              </Typography>

              <Typography variant="subtitle1">
                <span style={{ fontWeight: "bold" }}>Pagado:</span> $
                {formatAmount(payment.amount_paid, "CLP")} CLP / $
                {formatAmount(payment.amount_paid_usd, "USD")} USD
              </Typography>
              <Typography variant="subtitle1">
                <span style={{ fontWeight: "bold" }}>Deuda:</span> $
                {formatAmount(payment.debt, "CLP")} CLP / $
                {formatAmount(payment.debt_usd, "USD")} USD
              </Typography>

              <Box
                sx={{
                  width: "100%",
                  maxWidth: "400px",
                  margin: "auto",
                  marginTop: "16px",
                }}
              >
                <LinearProgress variant="determinate" value={paidPercentage} />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "8px",
                }}
              >
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                  Porcentaje Pagado: {paidPercentage.toFixed(2)}%
                </Typography>
              </Box>

              <Divider sx={{ marginY: "15px" }} />

              <Typography variant="h6" sx={{ fontWeight: 'bold', marginTop: '15px', marginBottom: '10px' }}>
                Proformas Activas:
              </Typography>
              <ProformasTable
                proformas={activeProformas}
                emptyMessage="No hay proformas activas."
              />

              <Typography variant="h6" sx={{ fontWeight: 'bold', marginTop: '20px', marginBottom: '10px' }}>
                Proformas Antiguas:
              </Typography>
              <ProformasTable
                proformas={oldProformas}
                emptyMessage="No hay proformas antiguas."
              />
            </Paper>
          </Box>
        );
      })}
    </Box>
  );
};

export default CompanyPayments;
