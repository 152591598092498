import React from "react";
import { Grid, Typography, Box } from "@mui/material";

const ContainerProduct = ({container}) => {
    //console.log(container);
  return (
    <Box
      sx={{
        width: "90%",
        border: "1px solid #ccc",
        padding: 2,
        marginTop:'15px',
      }}
    >
      <Grid container alignItems="center" spacing={2}>
        <Grid item>
            <Box sx={{ height:'15vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img
                src={"https://contandem-assets-public.s3.amazonaws.com/products-images/pano-amarillo.png"} // Imagen de ejemplo  margin: '0 auto'
                alt="Producto"
                style={{ height: '90%', objectFit: 'contain' }}
                />
            </Box>
        </Grid>

        {/* Título y cantidad */}
        <Grid item xs>
          <Typography variant="h5" gutterBottom>
            {container.name}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            Cantidad: {container.quantity}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            Proveedor: {container.supplier}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContainerProduct;
