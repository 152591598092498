import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { CompanyEntity, UserEntity } from '../../../domain/entities';

export enum StatusUserEnum {
  Initial = 'INITIAL',
  Loading = 'LOADING',
  Success = 'SUCCESS',
  Error = 'ERROR'
}

export interface UserState {
  user: UserEntity | null;
  status: StatusUserEnum;
  errorCode: number | null; // Nuevo campo para manejar códigos de error
}

const initialState = {
  user: null,
  status: StatusUserEnum.Initial,
  errorCode: null // Inicializamos el código de error como null
} as UserState;

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<UserEntity | null>) {
      if (action.payload === null) {
        state.user = null;
        state.status = StatusUserEnum.Initial;
        state.errorCode = null; // Reseteamos el código de error al cerrar sesión
        return;
      }
      state.user = action.payload;
    },
    setStatusUser(state, action: PayloadAction<StatusUserEnum>) {
      state.status = action.payload;
    },
    setErrorCode(state, action: PayloadAction<number | null>) {
      state.errorCode = action.payload; // Establecemos el código de error
    },
    setCompany(state, action: PayloadAction<CompanyEntity>) {
      if (state.user) {
        state.user = {
          ...state.user,
          company: action.payload
        };
        //console.log('Company updated:', state.user);
      }
    }
  },
});

export const { setUser, setStatusUser, setErrorCode, setCompany } = userSlice.actions;
export default userSlice.reducer;
