import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Header from '../../../components/admin/layout/Header';
import SideMenu from '../../../components/admin/layout/SideMenu';
import ProductForm from '../../../components/admin/products/ProductForm';
import RestrictionComponent from '../../../components/restriction/restriction';

export default function AdminProductFormPage() {
  return (
    <>
      <RestrictionComponent auth='Admin'/>
      <CssBaseline enableColorScheme />
      <Box sx={{ display: 'flex' }}>
        <SideMenu />
        <Box
          component="main"
          sx={() => ({
            flexGrow: 1
          
          })}
        >
          <Stack
            spacing={2}
            sx={{
              alignItems: 'stretch',
              mx: 0,
              pb: 10,
              mt: { xs: 8, md: 0 },
            }}
          >
            <Header/>
            <Box>
              <Container>

                      <Box sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '80vh',
                      }}>
                          <ProductForm />
                      </Box>

              </Container>
              </Box>
          </Stack>
        </Box>
      </Box>
      </>
  );
}
