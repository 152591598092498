import React from "react";
import PaidIcon from "@mui/icons-material/Paid";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Box,
} from "@mui/material";
import {
  SupplierContainerEntity,
  SupplierContainerProductEntity,
} from "../../../../domain/entities";

interface ContainerProductsModalProps {
  container: SupplierContainerEntity;
  open: boolean;
  onClose: () => void;
  onOpenPaymentDialog: () => void;
}

const formatCurrency = (value: number, locale = "es-CL", currency = "CLP") => {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
    minimumFractionDigits: 0,
  }).format(value);
};

const formatQuantity = (value: number) => {
  return value.toLocaleString("es-CL");
};
const formatAmount = (amount, currency = "CLP") => {
  const numericAmount = Number(amount);

  if (isNaN(numericAmount)) return amount;

  if (currency === "CLP") {
    // Formato para CLP: punto entre miles, coma para decimales
    return numericAmount.toLocaleString("es-CL", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  } else if (currency === "USD") {
    // Formato para USD: punto decimal, dos decimales
    return numericAmount.toLocaleString("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  }

  return amount; // Retorno sin formato si no se especifica una moneda válida
};
const ContainerProductsModal: React.FC<ContainerProductsModalProps> = ({
  container,
  open,
  onClose,
  onOpenPaymentDialog,
}) => (
  <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
    <DialogTitle>Productos del Contenedor {container?.id}</DialogTitle>
    <DialogContent>
      {container?.containerProducts?.length > 0 ? (
        <List>
          {container.containerProducts.map(
            (product: SupplierContainerProductEntity, index: number) => (
              <Card key={index} sx={{ display: "flex", marginBottom: 2 }}>
                <CardMedia
                  component="img"
                  sx={{ width: 150 }}
                  image={product.product.imageUrl || "/default-image.jpg"}
                  alt={product.product.name || "Imagen del producto"}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <CardContent>
                    <Typography variant="h6">
                      {product.product.name || "No disponible"}
                    </Typography>
                    <Typography variant="subtitle1">
                      {`ID: ${product.id || "No disponible"}`}
                    </Typography>
                    <Typography variant="subtitle2">
                      {`Cantidad: ${
                        formatQuantity(product.quantity) || "No disponible"
                      }`}
                    </Typography>
                  </CardContent>
                </Box>
              </Card>
            )
          )}
        </List>
      ) : (
        <Typography variant="body1">
          No hay productos en este contenedor.
        </Typography>
      )}
    </DialogContent>
    <DialogTitle>
    {`Deuda del Contenedor ${container?.id}: ${formatAmount((container?.debt || 0), 'CLP')} CLP / $ ${formatAmount((container?.debt_usd || 0), 'USD')} USD`}
    </DialogTitle>
    <DialogActions>
      {container?.status !== "Entregado" && (
        <Button
          onClick={onOpenPaymentDialog}
          color="primary"
          startIcon={<PaidIcon />}
        >
          Realizar Pago
        </Button>
      )}
      <Button onClick={onClose} color="primary">
        Cerrar
      </Button>
    </DialogActions>
  </Dialog>
);

export default ContainerProductsModal;
