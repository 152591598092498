import React, { useState } from "react";
import { Table, TableBody, TableCell, TableHead, TableRow, TablePagination } from "@mui/material";
import { PaymentPlanTransactionEntity } from "../../../../../domain/entities";

interface TransactionDetailsProps {
    transactions: PaymentPlanTransactionEntity[];
}

const TransactionDetails: React.FC<TransactionDetailsProps> = ({ transactions }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(3);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset to first page when rows per page changes
    };

    return (
        <>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>ID Transacción</TableCell>
                        <TableCell>Cantidad (CLP)</TableCell>
                        <TableCell>Cantidad (USD)</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {transactions
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((transaction) => (
                            <TableRow key={transaction.id}>
                                <TableCell>{transaction.id}</TableCell>
                                <TableCell>${transaction.amount.toLocaleString("es-ES")}</TableCell>
                                <TableCell>${transaction.amount_usd.toLocaleString("en-US")}</TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
            <TablePagination
    rowsPerPageOptions={[3]}
    component="div"
    count={transactions.length}
    rowsPerPage={rowsPerPage}
    page={page}
    onPageChange={handleChangePage}
    onRowsPerPageChange={handleChangeRowsPerPage}
    labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
    labelRowsPerPage="Filas por página"
/>

        </>
    );
};

export default TransactionDetails;
