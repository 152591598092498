
interface ProformaEntityJson {
  id: string;
  total_amount: number;
  total_amount_usd: number;
  payments: PaymentPlanPaymentEntityJson[];
  amount_paid: number;
  amount_paid_usd: number;
  debt: number;
  debt_usd: number;
  payment_progress: number;
  has_debt: boolean;
}

interface ContainerPaymentPlanEntityJson {
  id: string;
  name: string;
  proformas: ProformaEntityJson[];
  container_movements: PaymentPlanContainerMovementEntityJson[];
}

interface PaymentPlanEntityJson {
  id: string;
  owner: string;
  name: string;
  containers: ContainerPaymentPlanEntityJson[];
}

interface PaymentPlanPaymentEntityJson {
  id: string;
  transactions: PaymentPlanTransactionEntityJson[];
  percentage: number;
  amount_usd: number; 
  due_date: string;
  deadline: string;
}

interface PaymentPlanTransactionEntityJson {
  id: string;
  amount: number;
  amount_usd: number;
}

interface PaymentPlanContainerMovementEntityJson {
  id: string;
  status: number;
  is_completed: boolean;
  estimated_date: string;
  real_date: string;
}

// Mapped Entities
export interface PaymentPlanTransactionEntity {
  id: string;
  amount: number;
  amount_usd: number;
}

export interface PaymentPlanPaymentEntity {
  id: string;
  transactions: PaymentPlanTransactionEntity[];
  amount_usd: number; 
  percentage: number;
  due_date: string;
  deadline: string;
}

export interface PaymentPlanProformaEntity {
  id: string;
  total_amount: number;
  total_amount_usd: number;
  payments: PaymentPlanPaymentEntity[];
  amount_paid: number;
  amount_paid_usd: number;
  debt: number;
  debt_usd: number;
  payment_progress: number;
  has_debt: boolean;
}

export interface PaymentPlanContainerEntity {
  id: string;
  name: string;
  proformas: PaymentPlanProformaEntity[];
  container_movements: PaymentPlanContainerMovementEntity[];
}

export interface PaymentPlanContainerMovementEntity {
  id: string;
  status: number;
  is_completed: boolean;
  estimated_date: string;
  real_date: string;
}

export interface PaymentPlanEntity {
  id: string;
  owner: string;
  name: string;
  containers: PaymentPlanContainerEntity[];
}

export function mapJsonToPaymentPlanEntity(json: PaymentPlanEntityJson): PaymentPlanEntity {
  return {
    id: json.id,
    owner: json.owner,
    name: json.name,
    containers: json.containers.map((container: ContainerPaymentPlanEntityJson) => ({
      id: container.id,
      name: container.name,
      proformas: container.proformas.map((proforma: ProformaEntityJson) => ({
        id: proforma.id,
        total_amount: proforma.total_amount,
        total_amount_usd: proforma.total_amount_usd,
        payments: proforma.payments.map((payment: PaymentPlanPaymentEntityJson) => ({
          id: payment.id,
          transactions: payment.transactions.map((transaction: PaymentPlanTransactionEntityJson) => ({
            id: transaction.id,
            amount: transaction.amount,
            amount_usd: transaction.amount_usd,
          })),
          percentage: payment.percentage,
          amount_usd: payment.amount_usd,
          due_date: payment.due_date,
          deadline: payment.deadline,
        })),
        amount_paid: proforma.amount_paid,
        amount_paid_usd: proforma.amount_paid_usd,
        debt: proforma.debt,
        debt_usd: proforma.debt_usd,
        payment_progress: proforma.payment_progress,
        has_debt: proforma.has_debt,
      })),
      container_movements: container.container_movements.map((movement: PaymentPlanContainerMovementEntityJson) => ({
        id: movement.id,
        status: movement.status,
        is_completed: movement.is_completed,
        estimated_date: movement.estimated_date,
        real_date: movement.real_date,
      })),
    })),
  };
}
