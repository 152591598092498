import { Either } from '../../core/either';
import { ApiRepository } from '../repository/api';
import { ContainerProductEntity } from '../entities';
import { ICreateProductContainer} from '../../data/datasource/api'


export default class CreateProductContainerUseCase {
    private repository: ApiRepository;

    constructor(repository: ApiRepository) {
        this.repository = repository;
    }
    async execute(params: ICreateProductContainer): Promise<Either<ContainerProductEntity>> {
        return this.repository.createProductContainer(
            params.min_quantity,
            params.max_quantity,
            params.unit_base_price,
            params.increased_percent,
            params.interval,
            params.container,
            params.product,
            params.metric_unit,
            params.units_per_package,
            params.unit_base_price_usd,
            params.unit_purchase_price_usd, 
            params.unit_purchase_price
        );
    }

};