import React from "react";
import { Grid, Card, Typography, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import { SimulationEntity } from "../../../domain/entities";
import { useAppSelector } from "../../hooks/hooks";



const CLPFormatter = new Intl.NumberFormat("es-CL", {
  style: "currency",
  currency: "CLP",
});

const USDFromatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const ResultsSection: React.FC = () => {
  const simulation = useAppSelector((state) => state.simulation.simulation); 
  const { ganancias_totales, ingresos_totales, roi } = simulation;

  return (
    <Card sx={{ padding: 3, borderRadius: 2 }}>
      <Typography variant="h6" sx={{ marginBottom: 2, color: "#0A66A5", fontWeight: "bold" }}>
        Resultados de la Simulación
      </Typography>

      {/* Results Table */}
      <TableContainer component={Paper} sx={{ marginTop: 3 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><strong>Mes</strong></TableCell>
              <TableCell><strong>Ingresos</strong></TableCell>
              <TableCell><strong>Costos</strong></TableCell>
              <TableCell><strong>Cantidad Vendida</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(simulation)
              .filter((key) => key.startsWith("mes_"))
              .map((key) => {
                const monthData = simulation[key];
                return (
                  <TableRow
                    key={key}
                    sx={{
                      "&:hover": {
                        backgroundColor: "#f0f0f0", // Adjust this color to make it darker
                      },
                    }}
                  >
                    <TableCell>{monthData.mes}</TableCell>
                    <TableCell>{CLPFormatter.format(monthData.ingresos)}</TableCell>
                    <TableCell>{CLPFormatter.format(monthData.costos)}</TableCell>
                    <TableCell>{monthData.cantidad}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      {/* General Results (Revenue, ROI, etc.) */}
      <Box sx={{ marginTop: 3 }}>
        <Typography variant="h6" sx={{ marginBottom: 2, color: "#0A66A5", fontWeight: "bold" }}>
          Utilidad Proyectada
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="body2">Ingresos Totales</Typography>
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              {CLPFormatter.format(ingresos_totales)}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">ROI</Typography>
            <Typography
              variant="body1"
              sx={{
                fontWeight: "bold",
                color: roi > 0 ? "green" : "red",
              }}
            >
              {roi > 0 ? "+" : ""}
              {roi}%
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ marginTop: 2 }}>
          <Grid item xs={6}>
            <Typography variant="body2">Ganancias Totales</Typography>
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              {CLPFormatter.format(ganancias_totales)}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">Productos Vendidos</Typography>
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              {simulation.productos_vendidos}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

export default ResultsSection;
