import React, { useState } from 'react';
import {
  Grid,
  Button,
  Typography,
  TablePagination,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { PaymentPlanEntity } from '../../../../../domain/entities';
import ContainerDetails from './ContainerDetails';

interface ClientTableProps {
  company: PaymentPlanEntity;
}

const ClientTable: React.FC<ClientTableProps> = ({ company }) => {
  const [showTables, setShowTables] = useState(false);
  const [page, setPage] = useState(0); 
  const itemsPerPage = 3; 

  const handleToggle = () => {
    setShowTables((prev) => !prev);
  };

  function calculateTotalDebtAndPaid(company: PaymentPlanEntity) {
    let totalDebt = 0;
    let totalPaid = 0;
    let totalDebtUsd = 0;
    let totalPaidUsd = 0;
    company.containers.forEach((container) => {
      container.proformas.forEach((proforma) => {
        totalDebt += proforma.debt;
        totalPaid += proforma.amount_paid;
        totalDebtUsd += proforma.debt_usd;
        totalPaidUsd += proforma.amount_paid_usd;
      });
    });

    return { totalDebt, totalPaid, totalDebtUsd, totalPaidUsd };
  }

  const sortedContainers = company.containers
    .map((container) => ({
      ...container,
      unpaidProformasCount: container.proformas.filter(
        (proforma) => proforma.has_debt
      ).length,
    }))
    .sort((a, b) => b.unpaidProformasCount - a.unpaidProformasCount); 

  const activeContainers = sortedContainers.filter(
    (container) => container.container_movements?.[0] && 
      container.container_movements[0].is_completed === false  
  );
  //console.log("COMPANY: ", company)
  //console.log("SORTED CONTAINERS: ", sortedContainers);
  //console.log("ACTIVE CONTAINERS: ", activeContainers);

  const total = calculateTotalDebtAndPaid(company);

  const paginatedContainers = activeContainers.slice(
    page * itemsPerPage,  
    (page + 1) * itemsPerPage
  );

  const handlePageChange = (_: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
  };

  return (
    <Grid container style={{ width: "100%" }}>
      {/* Fila superior */}
      <Grid
        onClick={handleToggle}
        item
        xs={12}
        style={{ height: "50px", borderBottom: "1px solid #ccc" }}
        sx={{
          "&:hover": {
            backgroundColor: "#e0e0e0",
            cursor: "pointer",
          },
        }}
      >
        <Grid container>
          <Grid
            item
            xs={1}
            sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          >
            <Button
              startIcon={
                showTables ? (
                  <ExpandLessIcon style={{ fontSize: "30px" }} />
                ) : (
                  <ExpandMoreIcon style={{ fontSize: "30px" }} />
                )
              }
              sx={{ minHeight: "35px", padding: "0" }}
            />
          </Grid>
          <Grid
            item
            xs={1.3}
            sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          >
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              {company.owner}
            </Typography>
          </Grid>
          <Grid
            item
            xs={1.5}
            sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          >
            <Typography variant="body1">{company.name}</Typography>
          </Grid>
          <Grid
            item
            xs={1.5}
            sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          >
            <Typography variant="body1">{activeContainers.length}</Typography>
          </Grid>
          <Grid
            item
            xs={1.5}
            sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          >
            <Typography variant="body1" sx={{ color: total.totalDebt > 0 ? 'red' : 'inherit' }}>
              ${total.totalDebt.toLocaleString('es-ES')}
            </Typography>
          </Grid>
          <Grid
            item
            xs={1.5}
            sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          >
            <Typography variant="body1" sx={{ color: total.totalDebt > 0 ? 'red' : 'inherit' }}>
              ${total.totalDebtUsd.toLocaleString('en-US')}
            </Typography>
          </Grid>
          <Grid
            item
            xs={1.5}
            sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          >
            <Typography variant="body1">
              ${total.totalPaid.toLocaleString('es-ES')}
            </Typography>
          </Grid>
          <Grid
            item
            xs={1.5}
            sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          >
            <Typography variant="body1">
              ${total.totalPaidUsd.toLocaleString('en-US')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>


      {showTables && (
        <>
          {paginatedContainers.map((container) => (
            <ContainerDetails key={container.id} container={container} />
          ))}

          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            <TablePagination
              component="div"
              count={activeContainers.length}
              page={page}
              onPageChange={handlePageChange}
              rowsPerPage={itemsPerPage}
              rowsPerPageOptions={[]}
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
              labelRowsPerPage="Filas por página"
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default ClientTable;
