import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { Box, Grid, Typography, FormControl, InputAdornment, OutlinedInput, IconButton } from '@mui/material/';
import Stack from '@mui/material/Stack';
import SideMenu from '../../../components/cfo/layout/SideMenu';
import ContainerLayout from '../../../components/cfo/containers/container_layout';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { GetContainersPaymentsFullDetailEvent } from '../../../events/admin/get_containers_payments_full_detail';
import { useAppDispatch } from '../../../hooks/hooks';
import Pagination from '@mui/material/Pagination';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Header from '../../../components/cfo/layout/Header';
import RestrictionComponent from '../../../components/restriction/restriction';


const CFOContainersPage: React.FC = () => {
  const [searchTerm, setSearchTerm] = React.useState('');
  const [containers, setContainers] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const itemsPerPage = 10; 
  const [sortField, setSortField] = React.useState('');
  const [sortOrder, setSortOrder] = React.useState<'asc' | 'desc'>('asc');
  const [pages,setPages] = React.useState(1);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    const fetchData = async () => {
      const temp = await dispatch(GetContainersPaymentsFullDetailEvent(currentPage));
      setContainers(temp.containers);
      setPages(temp.pages)
    };

    fetchData();
  }, [dispatch,currentPage]);

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  const handleSort = (field: string) => {
    const newOrder = sortField === field && sortOrder === 'asc' ? 'desc' : 'asc';
    setSortField(field);
    setSortOrder(newOrder);
  };

  const filteredContainers = containers?.filter(container =>
    container.name.toLowerCase().includes(searchTerm.toLowerCase())
  ) || [];

  const indexOfLastItem = itemsPerPage;
  const indexOfFirstItem = 0;

  // Ordenar `currentContainers` en tiempo real según `sortField` y `sortOrder`
  const currentContainers = [...filteredContainers]
    .slice(indexOfFirstItem, indexOfLastItem)
    .sort((a, b) => {
      if (!sortField) return 0; // No ordenar si no hay campo seleccionado

      let valueA = sortField === 'saldo' 
        ? a.received_amount - (a.china_amount + a.customs_amount)
        : a[sortField];
      let valueB = sortField === 'saldo' 
        ? b.received_amount - (b.china_amount + b.customs_amount)
        : b[sortField];

      if (sortField === 'updated_at') {
        valueA = new Date(a.updated_at);
        valueB = new Date(b.updated_at);
      }

      if (valueA < valueB) return sortOrder === 'asc' ? -1 : 1;
      if (valueA > valueB) return sortOrder === 'asc' ? 1 : -1;
      return 0;
    });
    
  return (
    <>
    <RestrictionComponent auth='CFO'/>
    <Header/>
      <CssBaseline enableColorScheme />
      <Box sx={{ display: 'flex' }}>
        <SideMenu />
        
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            overflow: 'auto',
            backgroundColor: '#f0f0f0'
          }}
        >
          <Grid container spacing={2} sx={{ backgroundColor: '#f0f0f0' }}>
            <Grid item xs={8} container alignItems="center">
              <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#0A66A5', padding: '10px',marginLeft:'15px', marginTop: '15px' }}>
                <strong>Contenedores</strong>
              </Typography>
            </Grid>
            <Grid item xs={4}>
            <FormControl
  sx={{
    width: { xs: '100%', md: '30ch' },
    padding: '10px',
    marginLeft: '90px',
    marginTop: '20px',
  }}
  variant="outlined"
>
  <OutlinedInput
    size="small"
    id="search"
    placeholder="Buscar contenedor"
    sx={{
      flexGrow: 1,
      //backgroundColor: 'primary.light', // Color de fondo resaltado
      border: '2px solid', // Borde más grueso
      borderColor: 'primary.main', // Color del borde
      //borderRadius: '10px', // Bordes redondeados
      //boxShadow: 2, // Sombra para destacar
      
    }}
    startAdornment={
      <InputAdornment position="start" sx={{ color: 'text.primary' }}>
        <SearchRoundedIcon fontSize="small" />
      </InputAdornment>
    }
    inputProps={{
      'aria-label': 'search',
    }}
    value={searchTerm}
    onChange={e => setSearchTerm(e.target.value)}
  />
</FormControl>

            </Grid>
          </Grid>
          <Stack
            spacing={2}
            sx={{
              alignItems: 'center',
              mx: 3,
              pb: 10,
              mt: { xs: 8, md: 5 },
              backgroundColor: '#f5f5f5',
              padding: '8px'
            }}
          >
            <Grid container style={{ width: '95%' }}>
              <Grid item xs={12} style={{ height: '50px', borderBottom: '1px solid #ccc', display: 'flex', alignItems: 'center' }}>
                <Grid container sx={{ display: 'flex', alignItems: 'center' }}>
                  <Grid item xs={0.8}></Grid>
                  <Grid item xs={1.8} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#0A66A5' }}>
                      Contenedor
                    </Typography>
                    <IconButton onClick={() => handleSort('updated_at')}>
                        {sortField === 'updated_at' && sortOrder === 'asc' ? (
                          <ArrowUpwardIcon fontSize="small" />
                        ) : (
                          <ArrowDownwardIcon fontSize="small" />
                        )}
                      </IconButton>
                  </Grid>
                  <Grid item xs={1.8} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#0A66A5' }}>
                      Estado
                    </Typography>
                  </Grid>
                  <Grid item xs={1.9} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#0A66A5', display: 'flex', alignItems: 'center' }}>
                      Abonos recibidos
                      <IconButton onClick={() => handleSort('received_amount')}>
                        {sortField === 'received_amount' && sortOrder === 'asc' ? (
                          <ArrowUpwardIcon fontSize="small" />
                        ) : (
                          <ArrowDownwardIcon fontSize="small" />
                        )}
                      </IconButton>
                    </Typography>
                  </Grid>
                  <Grid item xs={1.9} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#0A66A5', display: 'flex', alignItems: 'center' }}>
                      Pagos a China
                      <IconButton onClick={() => handleSort('china_amount')}>
                        {sortField === 'china_amount' && sortOrder === 'asc' ? (
                          <ArrowUpwardIcon fontSize="small" />
                        ) : (
                          <ArrowDownwardIcon fontSize="small" />
                        )}
                      </IconButton>
                    </Typography>
                  </Grid>
                  <Grid item xs={1.9} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#0A66A5', display: 'flex', alignItems: 'center' }}>
                      Pagos internación
                      <IconButton onClick={() => handleSort('customs_amount')}>
                        {sortField === 'customs_amount' && sortOrder === 'asc' ? (
                          <ArrowUpwardIcon fontSize="small" />
                        ) : (
                          <ArrowDownwardIcon fontSize="small" />
                        )}
                      </IconButton>
                    </Typography>
                  </Grid>
                  <Grid item xs={1.9} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#0A66A5' }}>
                      Saldo
                      <IconButton onClick={() => handleSort('saldo')}>
                        {sortField === 'saldo' && sortOrder === 'asc' ? (
                          <ArrowUpwardIcon fontSize="small" />
                        ) : (
                          <ArrowDownwardIcon fontSize="small" />
                        )}
                      </IconButton>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item style={{ width: '95%' }}>
              <Grid container spacing={1}>
                {currentContainers.map((container) => (
                  <ContainerLayout contenedor={container} key={container.id} />
                ))}
                {filteredContainers.length === 0 && (
                  <Grid item xs={12}>
                    <Typography variant="body1">No se encontraron resultados.</Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <Pagination
                count={pages}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
              />
            </Grid>
          </Stack>
        </Box>
      </Box>
    </>
  );
};

export default CFOContainersPage;
