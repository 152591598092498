import { ContainerProductEntity, mapJsonToContainerProductEntity,
        ContainerMovementEntity, mapJsonToContainerMovementEntity,
        ContainerPaymentEntity, mapJsonToContainerPaymentEntity
      } from './container_full_detail_entity';


export interface ContainerAdminInfoEntity {
    id: string; // Corresponde a models.CharField
    createdAt: string; // Corresponde a models.DateTimeField(auto_now_add=True)
    updatedAt: string; // Corresponde a models.DateTimeField(auto_now=True)
    name: string; // Corresponde a models.CharField
    containerProducts: ContainerProductEntity[];
    containerMovements: ContainerMovementEntity[];
    containerPayments: ContainerPaymentEntity[];
}

export function mapJsonToContainerAdminInfoEntity(json): ContainerAdminInfoEntity {
    return {
        id: json.id,
        createdAt: json.created_at,
        updatedAt: json.updated_at,
        name: json.name,
        containerProducts: json.container_products.map(mapJsonToContainerProductEntity),
        containerMovements: json.container_movements.map(mapJsonToContainerMovementEntity),
        containerPayments: json.container_payments.map(mapJsonToContainerPaymentEntity),
    } as ContainerAdminInfoEntity;
}
