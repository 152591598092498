import React from "react";
import { Link } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import AnalyticsRoundedIcon from "@mui/icons-material/AnalyticsRounded";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import AssignmentRoundedIcon from "@mui/icons-material/AssignmentRounded";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import StorageIcon from "@mui/icons-material/Storage";
import StoreIcon from "@mui/icons-material/Store";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import { Icon } from "@mui/material";
export default function MenuContent() {
  const [openTools, setOpenTools] = React.useState(false);

  const handleProductsClick = () => {
    setOpenTools(!openTools);
  };

  const mainListItems = [
    { text: "Inicio", icon: <HomeRoundedIcon />, link: "/" },
    {
      text: "Proveedores",
      icon: <AnalyticsRoundedIcon />,
      link: "/cfo/providers",
    },
    { text: "Balance", icon: <QueryStatsIcon />, link: "/cfo" },
    { text: "Clientes", icon: <PeopleRoundedIcon />, link: "/cfo/clients" },
    // {
    //   text: "Productos",
    //   icon: <LocalGroceryStoreIcon />,
    //   link: "/cfo/products",
    // },
    {
      text: "Contenedores",
      icon: <DirectionsBoatIcon />,
      link: "/cfo/containers",
    }
  ];

  const secondaryListItems = [
    { text: "Settings", icon: <SettingsRoundedIcon />, link: "/cfo" },
    { text: "About", icon: <InfoRoundedIcon />, link: "/cfo" },
    { text: "Feedback", icon: <HelpRoundedIcon />, link: "/cfo" },
  ];

  return (
    <Stack sx={{ flexGrow: 1, p: 1, justifyContent: "space-between" }}>
      <List dense>
        {mainListItems.map((item, index) => (
          <React.Fragment key={index}>
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                component={item.link ? Link : "button"}
                to={item.link || ""}
                // onClick={item.expandable ? item.action : undefined}
                selected={index === 0}
                sx={{ pl: 2, pr: 1, py: 1 }}
              >
                <ListItemIcon sx={{ minWidth: "36px", color: "white" }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{
                    fontSize: "0.9rem",
                    color: "white",
                  }}
                  primary={item.text}
                />
                {/* {item.expandable ? (
                  item.open ? (
                    <ExpandLess sx={{ ml: 'auto', color: 'white' }} />
                  ) : (
                    <ExpandMore sx={{ ml: 'auto', color: 'white' }} />
                  )
                ) : null} */}
              </ListItemButton>
            </ListItem>

            {/* {item.expandable && (
              <Collapse in={item.open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {item.subItems.map((subItem, subIndex) => (
                    <ListItem key={subIndex} disablePadding sx={{ display: 'block' }}>
                      <ListItemButton
                        component={Link}
                        to={subItem.link}
                        sx={{ pl: 4, pr: 1, py: 0.8 }}
                      >
                        <ListItemIcon sx={{ minWidth: '32px', color: 'white' }}>{subItem.icon}</ListItemIcon>
                        <ListItemText
                          primaryTypographyProps={{ fontSize: '0.85rem', color: 'white' }}
                          primary={subItem.text}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            )} */}
            {index === 3 && <Divider sx={{ borderColor: "white" }} />}
          </React.Fragment>
        ))}
      </List>

      {/* <List dense>
        {secondaryListItems.map((item, index) => (
          <ListItem key={index} disablePadding sx={{ display: "block" }}>
            <ListItemButton component={Link} to={item.link}>
              <ListItemIcon sx={{ color: "white" }}>{item.icon}</ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ color: "white" }}
                primary={item.text}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List> */}
    </Stack>
  );
}
