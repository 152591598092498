import { Either } from '../../core/either';
import { ApiRepository } from '../repository/api';
import { SimulationEntity } from '../entities';
import {IPostSimulation} from '../../data/datasource/api';


export default class PostSimulationUseCase {
    private repository: ApiRepository;

    constructor(repository: ApiRepository) {
        this.repository = repository;
    }
    async execute(params: IPostSimulation): Promise<Either<SimulationEntity>> {
        return this.repository.postSimulation(
            params.precio_venta,
            params.precio_compra,
            params.cantidad,
            params.product_id,
        );
    }
};