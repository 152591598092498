import apiRepository from "../../../data/repository";
import CreateContainerUseCase from "../../../domain/usecases/create_container_usecase";
import { ContainerEntity } from '../../../domain/entities';
import {CreateContainerMovementEvent} from './create_container_movement_event'
import { setStatus, StatusLayoutEnum } from "../../redux/slices/layout_slice";

export const CreateContainerEvent = async (
    name:string,
    capacity:number,
    fixed_cost: number,
    tax: number,
    payments: number,
    percentages: number[],
    due_dates: string[],
    deadlines: string[],
    dispatch
): Promise<ContainerEntity> => {
    dispatch(setStatus(StatusLayoutEnum.Loading));
    const usecase = new CreateContainerUseCase(apiRepository);

    const result = await usecase.execute({
        name:name,
        capacity:capacity,
        fixed_cost: fixed_cost,
        tax: tax,
        payments: payments,
        percentages: percentages,
        due_dates: due_dates,
        deadlines: deadlines
    });

    if (result.isLeft()) {
        const error = result.left;
        if (error) {
            throw new Error(error.message);
        } else {
            throw new Error("Error desconocido");
        }
    }

    const conainerEntitie = result.right;
    if (!conainerEntitie) {
        throw new Error("No se pudo crear el producto");
    }

    const infinityDate = new Date(2999, 11, 31, 17, 0).toISOString().slice(0, 19) + 'Z';
    const endOfToday = new Date();
    endOfToday.setHours(23, 59, 59, 999);
    const endOfTodayISO = endOfToday.toISOString();
    await CreateContainerMovementEvent(endOfTodayISO,infinityDate,1,conainerEntitie.id,1,dispatch);
    return conainerEntitie;
}