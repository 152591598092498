import { Either } from '../../core/either';
import { ApiRepository } from '../repository/api';
import { ContainerEntity } from '../entities';
import { ICreateContainer } from '../../data/datasource/api';


export default class CreateContainerUseCase {
    private repository: ApiRepository;

    constructor(repository: ApiRepository) {
        this.repository = repository;
    }
    async execute(params: ICreateContainer): Promise<Either<ContainerEntity>> {
        return this.repository.createContainer(
            params.name,
            params.capacity,
            params.fixed_cost,
            params.tax,
            params.payments,
            params.percentages,
            params.due_dates,
            params.deadlines
        );
    }

};
