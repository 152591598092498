import apiRepository from '../../../data/repository/index';
import { setStatus, StatusLayoutEnum } from '../../redux/slices/layout_slice';
import GetRevenewComparisonUsecase from '../../../domain/usecases/cfo_dashboard/get_revenew_comparison_usecase';

export const GetRevenewComparisonEvent = () => async (dispatch) => {
  dispatch(setStatus(StatusLayoutEnum.Loading));
  const usecase = new GetRevenewComparisonUsecase(apiRepository);

  const result = await usecase.execute();

  result.fold(
    (error) => {
      console.error('Error:', error);
    },
    (data) => {
      dispatch(setStatus(StatusLayoutEnum.Success));
    }
  );
  return result.right;
};
