import React, { useEffect,useMemo,useState } from 'react';
import { GetContainersEvent } from '../../events/container/get_containers_event';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { Container, Grid, Typography, Pagination  } from '@mui/material';
import ContainerCard from '../../components/user_containers/containers_card';

const ContainersPage: React.FC = () => {
    const { containers } = useAppSelector(state => state.container);
    const dispatch = useAppDispatch();

    const [page, setPage] = useState(1);
    const itemsPerPage = 5;

    useEffect(() => {
        dispatch(GetContainersEvent());
    }, []);

    const containersValidos = useMemo(() => {
        return containers
            .flatMap(container =>
                container.containerProducts
                    .filter(containerProduct =>
                        Math.trunc(
                            (containerProduct.maxQuantity - containerProduct.quantity) /
                            containerProduct.unitsPerPackage
                        ) >= 1
                    )
                    .map(containerProduct => ({
                        container_id: container.id,
                        containerProduct: { id: containerProduct.id,
                            product: containerProduct.product,
                            quantity: containerProduct.quantity,
                            minQuantity: containerProduct.minQuantity,
                            maxQuantity: containerProduct.maxQuantity,
                            unit_base_price_usd:  containerProduct.unit_base_price_usd,
                            unit_purchase_price_usd: containerProduct.unit_purchase_price_usd,
                            unitBasePrice: containerProduct.unitBasePrice,
                            unitsPerPackage: containerProduct.unitsPerPackage,
                            increasedPercent: containerProduct.increasedPercent,
                            interval: containerProduct.interval
                        },
                        containerPayments: container.containerPayments
                    }))
            );
    }, [containers]);

    const startIndex = (page - 1) * itemsPerPage;
    const paginatedContainers = containersValidos.slice(startIndex, startIndex + itemsPerPage);

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
        window.scrollTo(0, 0);
    };

    return (
        <Container sx={{ padding: '20px' }}>
            <Grid container direction={'column'}>
                <Typography
                    variant='h4'
                    sx={{
                        fontWeight: '600',
                        marginBottom: '30px',
                    }}
                >
                    Importaciones en curso
                </Typography>
                <Grid container spacing={2}>
                    {paginatedContainers.length !== 0 &&
                        paginatedContainers.map((container) => (
                            <Grid item xs={12} key={container.container_id}>
                                <ContainerCard container={container} />
                            </Grid>
                        ))}
                </Grid>
                {/* Paginación */}
                <Pagination
                    count={Math.ceil(containersValidos.length / itemsPerPage)}
                    page={page}
                    onChange={handlePageChange}
                    sx={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}
                />

            </Grid>
        </Container>
    );
};

export default ContainersPage;
