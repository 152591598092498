import React from "react";
import { Box, Typography, Card, Grid } from "@mui/material";
import { ProformaEntity } from "../../../domain/entities/proforma/proforma_entity";

interface PendingPaymentsProps {
  proformas: ProformaEntity[];
}

const PendingPayments: React.FC<PendingPaymentsProps> = ({ proformas }) => {
  const formatDate = (date: string): string => {
    try {
      const formattedDate = date.replace(" a las ", "T").concat(":00Z");
      return formattedDate;
    } catch (error) {
      console.error(`Error formatting date: ${date}`, error);
      return date;
    }
  };

  const displayDateWithTime = (date: string): string => {
    try {
      const parsedDate = new Date(formatDate(date));
      return new Intl.DateTimeFormat("es-CL", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      }).format(parsedDate);
    } catch (error) {
      console.error(`Error displaying date with time: ${date}`, error);
      return date;
    }
  };

  const pendingPayments = proformas.flatMap((proforma) =>
    proforma.payments
      .filter((payment) => payment.status === 0)
      .map((payment) => {
        const totalTransactionsAmountClp = payment.transactions.reduce(
          (acc, transaction) => acc + transaction.amount,
          0
        );
        const totalTransactionsAmountUsd = payment.transactions.reduce(
          (acc, transaction) => acc + (transaction.amount_usd || 0),
          0
        );

        const remainingAmount = payment.amount - totalTransactionsAmountClp;
        const remainingAmountUsd = payment.amount_usd - totalTransactionsAmountUsd;

        return {
          ...payment,
          remainingAmount,
          remainingAmountUsd,
          formattedDueDate: formatDate(payment.due_date),
        };
      })
      .filter((payment) => payment.remainingAmountUsd > 1)
  );



  // Formateador para agregar puntos a los miles, truncando a la unidad
  const formatAmount = (amount, currency = "CLP") => {
    const numericAmount = Number(amount);
  
    if (isNaN(numericAmount)) return amount;
  
    if (currency === "CLP") {
      // Formato para CLP: punto entre miles, coma para decimales
      return numericAmount.toLocaleString("es-CL", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    } else if (currency === "USD") {
      // Formato para USD: punto decimal, dos decimales
      return numericAmount.toLocaleString("en-US", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    }
  
    return amount; // Retorno sin formato si no se especifica una moneda válida
  };


  const sortedPayments = pendingPayments.sort((a, b) => {
    const dateA = new Date(a.formattedDueDate).getTime();
    const dateB = new Date(b.formattedDueDate).getTime();
    return dateA - dateB;
  });



  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "500px",
        height: "400px",
        padding: "16px",
        backgroundColor: "#f9f9f9",
        borderRadius: "8px",
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: 1,
      }}
    >
      <Typography variant="h5" sx={{ color: "#0A66A5", fontWeight: "bold" }}>
        Pagos Pendientes
      </Typography>

      <Box
        sx={{
          width: "100%",
          overflowY: "auto",
          maxHeight: "400px",
        }}
      >
        {sortedPayments.length === 0 ? (
          <Typography
            variant="body2"
            color="textSecondary"
            align="center"
            sx={{ color: "#0A66A5" }}
          >
            No hay pagos pendientes.
          </Typography>
        ) : (
          <Grid container spacing={2}>
            {sortedPayments.map((payment, index) => (
              <Grid item xs={12} key={index}>
                <Card
                  sx={{
                    padding: "15px",
                    borderRadius: "7px",
                    textAlign: "left",
                    marginBottom: "10px",
                  }}
                >
                  <Typography variant="h6" sx={{ color: "#0A66A5", fontWeight: 600 }}>
                    Pago ID: {payment.id}
                  </Typography>
                  <Typography variant="body2" sx={{ fontWeight: 600 }}>



                    Monto restante: ${formatAmount(payment.remainingAmount, 'CLP')} CLP / ${formatAmount(payment.remainingAmountUsd, 'USD')} USD

                  </Typography>
                  <Box sx={{ mb: 1 }} />
                  <Typography variant="body2" sx={{ color: "GrayText" }}>
                    Fecha de vencimiento: {displayDateWithTime(payment.due_date)}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "GrayText" }}>
                    Fecha límite: {displayDateWithTime(payment.deadline)}
                  </Typography>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default PendingPayments;
