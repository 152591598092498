// src/components/ContainerList.tsx
import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../hooks/hooks';
import {Typography} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { AdminProductsModeEnum } from "../../../redux/slices/admin_products_slice";
import { setMode } from '../../../redux/slices/admin_products_slice';

const ProducsNavBar: React.FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const handleAddProduct = () =>{
        dispatch(setMode(AdminProductsModeEnum.Create))
        navigate('/admin/product/form');
    }

    return (
        <Fragment>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static" sx={{backgroundColor: 'transparent', boxShadow: 'none' }}>
                    <Toolbar variant="dense">
                        <Typography variant="h5" color="#0A66A5" component="div" sx={{ flexGrow: 1,fontWeight: 'bold' }}>
                            Productos
                        </Typography>
                        <Button variant="contained" onClick={handleAddProduct} startIcon={<AddIcon />}
                        sx={{
                            backgroundColor: '#4caf50',
                            color: '#fff',
                            '&:hover': {
                              backgroundColor: '#388e3c',
                            },
                          }}>
                            Añadir Producto
                        </Button>
                    </Toolbar>
                </AppBar>
               
            </Box>
        </Fragment>
    );
};

export default ProducsNavBar;
