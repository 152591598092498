import { Either, Usecase } from "../../../core/index";
import { ApiRepositoryImpl } from "../../../data/repository/api";

export default class GetContainerCountUsecase implements Usecase<{in_factory:number,in_route:number}> {
    repository: ApiRepositoryImpl;

    constructor(repository: ApiRepositoryImpl) {
        this.repository = repository;
    }

    async execute(): Promise<Either<{in_factory:number,in_route:number}>> {
        const data = await this.repository.getContainerCount();

        return data;
    }
};