import React, { useEffect, useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { Grid, Box, TextField, Button, Typography, CircularProgress } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { StatusUserEnum } from '../../redux/slices/user_slice';
import { LoginEvent } from '../../events/user/login_event';
import AppImages from '../../../theme/app_images';
import { useNavigate } from 'react-router-dom';

const LoginForm: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { status, errorCode } = useAppSelector(state => state.user); // Incluye errorCode del estado

  useEffect(() => {
    if (status === StatusUserEnum.Success) {
      navigate('/');
    } else if (status === StatusUserEnum.Error) {
      //console.log("Código de error:", errorCode); // Verifica qué código llega
      // Mostrar mensajes específicos según el código de error
      if (errorCode === 401) {
        enqueueSnackbar('Contraseña incorrecta', { variant: 'error', autoHideDuration: 2000 });
      } else if (errorCode === 404) {
        enqueueSnackbar('Usuario no encontrado', { variant: 'error', autoHideDuration: 2000 });
      } else {
        enqueueSnackbar('Error al iniciar sesión', { variant: 'error', autoHideDuration: 2000 });
      }
    }
  }, [status, errorCode, navigate]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(LoginEvent({ email, password }));
  };

  return (
    <Grid container>
      {/* Imagen */}
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            height: '100%',
            backgroundImage: `url(${AppImages.loginImage})`,
            backgroundSize: 'cover',
            position: 'relative',
          }}
        ></Box>
      </Grid>

      {/* Formulario */}
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#f5f5f5',
            padding: 3,
          }}
        >
          <Typography component="h1" variant="h5" gutterBottom>
            Iniciar sesión
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Correo electrónico"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Contraseña"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Box
              sx={{
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                mt: 3,
                mb: 2,
              }}
            >
              <Button
                type="submit"
                style={{
                  width: '100%',
                  maxWidth: '150px',
                  borderRadius: '10px',
                  height: '40px',
                  backgroundColor: '#0A66A5',
                  color: 'white',
                  fontSize: '16px',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                variant="contained"
                disabled={status === StatusUserEnum.Loading}
              >
                Ingresar
              </Button>
              {status === StatusUserEnum.Loading && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: '#0A66A5',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                />
              )}
            </Box>
            {status === StatusUserEnum.Error && (
              <Typography color="error">
                {errorCode === 401
                  ? 'Contraseña incorrecta'
                  : errorCode === 404
                  ? 'Usuario no encontrado'
                  : 'Error al iniciar sesión'}
              </Typography>
            )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default LoginForm;

