import React from "react";
import { useAppSelector } from "../../hooks/hooks";

const RestrictionComponent: React.FC<{ auth: string }> = ({ auth }) => {
  const { user } = useAppSelector((state) => state.user);

  React.useEffect(() => {
    if (!user || !isAuthorized(user.id, auth)) {
      //console.log("Unauthorized access");
      window.location.href = "/";
    }
  }, [user, auth]);

  const isAuthorized = (userRole: string, requiredRole: string) => {
    if (requiredRole === "CFO") {
      return userRole === "CFO";
    }
    if (requiredRole === "Admin") {
      return userRole === "Admin" || userRole === "CFO";
    }
    return false;
  };

  return <></>;
};

export default RestrictionComponent;
