import React, { Fragment } from 'react';
import { Card, Container, Grid, Typography, TableContainer, TableRow, TableHead, TableCell, TableBody, Table } from '@mui/material';

interface simulatorProductInfoProps {
    pricingData: any;
    container_product: any;
    product: any;
}

const simulatorProductInfo: React.FC<simulatorProductInfoProps> = ({ pricingData, container_product, product }) => {
  return (
    <Grid container item>
      <Grid container xs={6} item direction="column">
        <Container>
          <Typography
            variant="h5"
            sx={{
              fontWeight: "600",
              color: "#0A66A5",
            }}
          >
            {product.name}
          </Typography>
        </Container>

        <Container
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "30px",
          }}
        >
          <img
            src={product.imageUrl}
            style={{
              height: "200px",
              aspectRatio: "1/1",
            }}
            alt="Random"
          />
        </Container>
        <Container
          sx={{
            marginTop: "30px",
          }}
        >
          <Card
            sx={{
              padding: "5px",
              borderRadius: "30px",
              borderColor: "#0A66A5",
              borderWidth: "1px",
              borderStyle: "solid",
            }}
          >
            <Grid
              container
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Grid
                item
                xs
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    color: "#0A66A5",
                    lineHeight: "1",
                  }}
                >
                  {product.size}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: "#0A66A5",
                    lineHeight: "1",
                  }}
                >
                  Centímetros
                </Typography>
              </Grid>
              <div style={{ border: "1px solid #0A66A5" }}></div>
              <Grid
                item
                xs
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    color: "#0A66A5",
                    lineHeight: "1",
                  }}
                >
                  {product.weight} Kg
                </Typography>
              </Grid>
              <div style={{ border: "1px solid #0A66A5" }}></div>
              <Grid
                item
                xs
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    color: "#0A66A5",
                    lineHeight: "1",
                  }}
                >
                  {product.materials}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Container>
      </Grid>
      <Grid item xs={6}>
      <Grid container sx={{ height: '100%' }}>
        {/* Image and Text Section */}
        <Grid container item xs={12} sx={{ height: '35%', marginBottom: '15px', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '24px' }}>

          {/* Text Section */}
          <Grid item xs={7} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            {/* Percentage */}
            <Typography sx={{ fontSize: '45px', color: '#0A66A5', marginBottom: '10px', textAlign: 'center' }}>
              <strong>{Math.round((container_product.quantity / container_product.maxQuantity) * 100)}%</strong>
            </Typography>
            
            {/* Description */}
            <Typography sx={{ fontSize: '24px', color: '#0A66A5', textAlign: 'center' }}>
              <strong>Del contenedor vendido</strong>
            </Typography>
          </Grid>
        </Grid>

        {/* Final Text */}
        <Grid item xs={12} sx={{ display: 'flex', padding: '8px', justifyContent: 'center' }}>
          <Typography sx={{ fontSize: '18px', textAlign: 'center' }}>
            / Quedan {Math.round((container_product.maxQuantity - container_product.quantity) / container_product.unitsPerPackage)} de{' '}
            {Math.round(container_product.maxQuantity / container_product.unitsPerPackage)} cajas
          </Typography>
        </Grid>
        {/* Pricing Table */}
        {/* Header */}
        <Grid item xs={12} sx={{ height: '15%', display: 'flex', alignItems: 'center' }}>
          <Typography variant="h6" sx={{ color: '#0A66A5', marginLeft: '30px' }}>
            <strong>Tabla de precios:</strong>
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ height: '60%', display: 'flex', justifyContent: 'center' }}>
          <TableContainer sx={{ maxHeight: '100%', width: '90%' }}>
            <Table sx={{ borderCollapse: 'separate', borderSpacing: '5px 0px', width: '100%' }}>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{ fontWeight: 'bold', color: '#0A66A5', padding: '2px', textAlign: 'center', backgroundColor: '#f0f0f0', border: 'none' }}
                  >
                    Desde-Hasta
                  </TableCell>
                  <TableCell
                    sx={{ fontWeight: 'bold', color: '#0A66A5', padding: '2px', textAlign: 'center', backgroundColor: '#f0f0f0', border: 'none' }}
                  >
                    Precio (IVA inc)
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pricingData.map((row, index) => (
                  <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{ padding: '1px', textAlign: 'center', backgroundColor: '#f0f0f0', border: 'none' }}>{row.range}</TableCell>
                    <TableCell sx={{ padding: '1px', textAlign: 'center', backgroundColor: '#f0f0f0', border: 'none' }}>{row.price}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Grid>
    </Grid>
  );
};
export default simulatorProductInfo;