import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ProductEntity, CategoryProductEnum } from '../../../domain/entities'
export interface IProductFilterEntity {
    range: number[];
    categories: CategoryProductEnum[];
}

export enum StatusProductEnum {
    Initial = 'INITIAL',
    Loading = 'LOADING',
    Success = 'SUCCESS',
    Error = 'ERROR'
}

export interface ProductState {
    products: ProductEntity[];
    status: StatusProductEnum;
    filter: IProductFilterEntity;
}

const initialState = {
    products: [],
    status: StatusProductEnum.Initial,
    filter: { range: [0, 1000], categories: [] },
} as ProductState;

const productSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        setProducts(state, action: PayloadAction<ProductEntity[] | []>) {
            state.products = action.payload
        },
        setFilter(state, action: PayloadAction<IProductFilterEntity>) {
            state.filter = action.payload
        },
        deleteProduct(state, action: PayloadAction<string>) {
            state.products = state.products.filter(product => product.id !== action.payload);
        },
        addProduct(state, action: PayloadAction<ProductEntity>) {
            state.products.push(action.payload);
          },
        setStatusProduct(state, action: PayloadAction<StatusProductEnum>) {
            state.status = action.payload
        }, 
        changeVisibility(state,action:PayloadAction<{id:string,is_visible:boolean}>){
            const product = state.products.find(product => product.id === action.payload.id);
            if (product) {
                product.is_visible = action.payload.is_visible;
        }},
        updateProduct(state, action: PayloadAction<ProductEntity>) {
            const index = state.products.findIndex(product => product.id === action.payload.id);
            if (index !== -1) {
              state.products[index] = action.payload;
            }
        },
        reset(state) {
            state.filter = { range: [0, 1000], categories: [] }
            state.products = []
            state.status = StatusProductEnum.Initial
        }

    },
})

export const { setProducts, setFilter, setStatusProduct, reset,deleteProduct,addProduct,updateProduct,changeVisibility } = productSlice.actions
export default productSlice.reducer
