import React, { useState } from "react";
import { Modal, Button, Typography, Box, Collapse, IconButton } from "@mui/material";
import { ExpandMore, ExpandLess } from "@mui/icons-material";

const formatAmount = (amount, currency = "CLP") => {
  const numericAmount = Number(amount);

  if (isNaN(numericAmount)) return amount;

  if (currency === "CLP") {
    // Formato para CLP: punto entre miles, coma para decimales
    return numericAmount.toLocaleString("es-CL", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  } else if (currency === "USD") {
    // Formato para USD: punto decimal, dos decimales
    return numericAmount.toLocaleString("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  }

  return amount; // Retorno sin formato si no se especifica una moneda válida
};

const getPaymentStatusIndicator = (deadline, transactions, amount_usd) => {
  const now = new Date();
  const reformattedDeadline = deadline?.replace(" a las ", "T");
  const deadlineDate = reformattedDeadline ? new Date(reformattedDeadline) : null;
  let isPaid = true;

  const totalPaidUsd = transactions.reduce(
    (sum, transaction) => (transaction.status === 1 ? sum + transaction.amount_usd : sum),
    0
  );

  if (totalPaidUsd < amount_usd) {
    isPaid = false;
  }

  if (isPaid) {
    return { text: "Pago completado", color: "green" };
  } else if (!isPaid && deadlineDate && deadlineDate < now) {
    return { text: "Pago atrasado", color: "red" };
  } else if (!isPaid && deadlineDate && deadlineDate >= now) {
    return { text: "Pago en progreso", color: "orange" };
  }
  return { text: "Estado desconocido", color: "gray" };
};

const PaymentDetailsModal = ({ open, handleClose, proforma, handleTransactionStatus, getStatusLabel }) => {

  const [updatedTransactions, setUpdatedTransactions] = useState({});
  const [expandedPayments, setExpandedPayments] = useState({});

  const handleToggleExpand = (paymentId) => {
    setExpandedPayments((prev) => ({
      ...prev,
      [paymentId]: !prev[paymentId],
    }));
  };

  const handleButtonClick = (transactionId, paymentId, status) => {
    setUpdatedTransactions((prev) => ({
      ...prev,
      [transactionId]: status,
    }));
    handleTransactionStatus(transactionId, paymentId, status);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          maxHeight: "80vh",
          overflowY: "auto",
          borderRadius: "8px",
        }}
      >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Detalles de Pagos y Transacciones
        </Typography>
        {proforma.payments && proforma.payments.length > 0 ? (
          proforma.payments.map((paymentDetail) => {
            const { text: statusText, color: statusColor } = getPaymentStatusIndicator(
              paymentDetail.deadline,
              paymentDetail.transactions,
              paymentDetail.amount_usd
            );
            const isExpanded = expandedPayments[paymentDetail.id] || false;
            const totalPaid = paymentDetail.transactions.reduce(
              (sum, transaction) => (transaction.status === 1 ? sum + transaction.amount : sum),
              0
            );
            const totalPaidUsd = paymentDetail.transactions.reduce(
              (sum, transaction) => (transaction.status === 1 ? sum + transaction.amount_usd : sum),
              0
            );

            return (
              <Box
                key={paymentDetail.id}
                sx={{
                  marginTop: "15px",
                  padding: "10px",
                  border: "1px solid #ccc",
                  borderRadius: "8px",
                }}
              >
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                  Pago ID: {paymentDetail.id}
                </Typography>
                <Typography variant="body2">
                  Monto total: ${formatAmount(paymentDetail.amount, 'CLP')} CLP / ${formatAmount(paymentDetail.amount_usd, "USD")} USD
                </Typography>
                <Typography variant="body2">
                  Pagado: ${formatAmount(totalPaid, 'CLP')} CLP / ${formatAmount(totalPaidUsd, "USD")} USD
                </Typography>
                <Typography variant="body2">
                  Deuda: ${formatAmount(paymentDetail.amount - totalPaid, 'CLP')} CLP / ${formatAmount(paymentDetail.amount_usd - totalPaidUsd, "USD")} USD
                </Typography>
                <Typography variant="body2">
                  Fecha límite:{" "}
                  {paymentDetail.deadline
                    ? new Date(paymentDetail.deadline.replace(" a las ", "T")).toLocaleString("es-CL", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                      })
                    : "Fecha no disponible"}
                </Typography>

                <Typography
                  variant="body2"
                  sx={{ color: statusColor, fontWeight: "bold", marginTop: "5px" }}
                >
                  {statusText}
                </Typography>

                <Box sx={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                  <Typography variant="body2" sx={{ fontWeight: "bold", flexGrow: 1 }}>
                    Transacciones:
                  </Typography>
                  <IconButton onClick={() => handleToggleExpand(paymentDetail.id)}>
                    {isExpanded ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                </Box>

                <Collapse in={isExpanded}>
                  {paymentDetail.transactions && paymentDetail.transactions.length > 0 ? (
                    paymentDetail.transactions.map((transaction, index) => {
                      const isUpdated = updatedTransactions[transaction.id] !== undefined;
                      const updatedStatus = updatedTransactions[transaction.id];

                      return (
                        <Box key={transaction.id} sx={{ marginLeft: "20px", marginTop: "5px" }}>
                          <Typography variant="body2">Transacción ID: {transaction.id}</Typography>
                          <Typography variant="body2">
                            Monto: ${formatAmount(transaction.amount, 'CLP')} CLP / ${formatAmount(transaction.amount_usd, 'USD')} USD
                          </Typography>
                          <Typography variant="body2">
                            Fecha:{" "}
                            {new Date(transaction.created_at).toLocaleString("es-CL", {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                              hour: "2-digit",
                              minute: "2-digit",
                            })}
                          </Typography>
                          <Typography variant="body2">
                            Estado:{" "}
                            {isUpdated
                              ? updatedStatus === 1
                                ? "Aceptada"
                                : "Rechazada"
                              : getStatusLabel(transaction.status)}
                          </Typography>
                          {!isUpdated && transaction.status === 0 && (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "10px",
                              }}
                            >
                              <Button
                                variant="contained"
                                color="success"
                                onClick={() => handleButtonClick(transaction.id, paymentDetail.id, 1)}
                              >
                                Aprobar
                              </Button>
                              <Button
                                variant="contained"
                                color="error"
                                onClick={() => handleButtonClick(transaction.id, paymentDetail.id, 2)}
                              >
                                Rechazar
                              </Button>
                            </Box>
                          )}
                          {index !== paymentDetail.transactions.length - 1 && (
                            <Box sx={{ borderTop: "1px solid #ccc", marginTop: "10px" }} />
                          )}
                        </Box>
                      );
                    })
                  ) : (
                    <Typography variant="body2" sx={{ marginLeft: "20px" }}>
                      Aún no se realizan transacciones para este pago.
                    </Typography>
                  )}
                </Collapse>
              </Box>
            );
          })
        ) : (
          <Typography variant="body2">No hay pagos registrados para esta proforma.</Typography>
        )}
      </Box>
    </Modal>
  );
};

export default PaymentDetailsModal;