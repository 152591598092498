import React from 'react';
import {
  Card, CardHeader, CardMedia, CardContent, CardActions, Avatar, IconButton, Typography, Menu, MenuItem,
  MenuList, ListItemText, ListItemIcon, Divider} from '@mui/material';
import { red } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';
import { Favorite as FavoriteIcon, MoreVert as MoreVertIcon, Delete as DeleteIcon, Edit as EditIcon, DoNotDisturbOn as DoNotDisturbOnIcon, LocalOffer as LocalOfferIcon} from '@mui/icons-material';
import { ProductEntity } from '../../../../domain/entities';
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { setSelectedProduct } from '../../../redux/slices/admin_products_slice';
import { DivisasEnum } from '../../../redux/slices/divisa_slice';
import { ChangeVisibilityEvent} from '../../../events/admin/changeVisibility_event';
import {DeleteProductEvent} from '../../../events/admin/delete_product_event';
import {deleteProduct} from '../../../redux/slices/product_slice';
import VisibilityIcon from '@mui/icons-material/Visibility';

interface ProductCardProps {
    product: ProductEntity;
}

const AdminProductCard: React.FC<ProductCardProps> = ({ product }) =>{
    const {divisa} = useAppSelector((state) => state.divisa);
    const dispatch = useAppDispatch();
    const navigate = useNavigate()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleEditar = () =>{
      dispatch(setSelectedProduct(product))
      navigate('/admin/product/form');
    }
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const handleMenuAction = (action: string) => {
      //console.log(action);
      handleClose();
    };
    const handleEliminar = () =>{
      const confirmacion = window.confirm("¿Estás seguro de que deseas eliminar este producto?");
      if (confirmacion) {
        DeleteProductEvent(product.id);
        dispatch(deleteProduct(product.id));
      }
    }

    const cambiar_vista = () => {
      ChangeVisibilityEvent(product.id,dispatch);
    }

  return (
  <Card  sx={{   maxWidth: 300,   height: 350,   display: 'flex',   flexDirection: 'column', opacity: product.is_visible ? 1 : 0.5, backgroundColor: product.is_visible ? 'white' : '#f5f5f5',  transition: 'opacity 0.3s, background-color 0.3s'}}
    >
      <CardHeader
       sx={{ display: 'flex',height: '20%' ,width: "100%",alignContent:'center' }}
        // avatar={
        //   <IconButton aria-label="avatar-action" sx={{ p: 0.5,width:'7%' }}>
        //     <Avatar sx={{
        //       bgcolor: red[500],
        //       width: 33,  
        //       height: 33, 
        //       fontSize: 16
        //     }} aria-label="recipe">
        //       P
        //     </Avatar>
        //   </IconButton>
        // }
        action={
          <IconButton aria-label="settings" onClick={handleClick}>
            <MoreVertIcon />
          </IconButton>
        }
        title={
            <Typography
              variant="body1"
              sx={{
                maxHeight: '3em',
                width: "90%",
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2, 
                flexGrow: 1,
                textAlign: 'center',
              }}
            >
              {product.name}
            </Typography>
          }
          
          subheader={
            <Typography
              variant="body2"
              sx={{
                maxHeight: '2em',
                width: '90%',
                textAlign:'center',
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitLineClamp: 1,
                WebkitBoxOrient: 'vertical',
                color: 'text.secondary',
              }}
            >
              {product.supplier}
            </Typography>
            }
      />
      <CardMedia
        component="img"
        height="150px"
        image={product.imageUrl}
        alt= {product.name}
        sx={{
            objectFit: 'contain',
          }}
      />
    <CardContent sx={{ display: 'flex', alignItems: 'center',flexDirection: 'column', flexGrow: 1, height: '90px'}}>
      <Typography
          variant="body2"
          sx={{
            color: 'text.secondary',
            display: '-webkit-box',
            overflow: 'hidden',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 3,
            textAlign: 'justify',
            flexGrow: 1,
          }}
        >
            {product.description}
        </Typography>
      </CardContent>
      <CardActions disableSpacing sx={{ justifyContent: 'center'}}>
        {/* <IconButton aria-label="add to favorites">
          <FavoriteIcon />
        </IconButton> */}
        { divisa.divisa === DivisasEnum.USD &&
                <Typography
                variant="body2"
                sx={{
                  fontWeight: 500,
                  color: "#424242",
                  textAlign:'center'
                }}
              >
                Precio: $ {product.price} USD
              </Typography>
}
        { divisa.divisa === DivisasEnum.CLP &&
                        <Typography
                        variant="body2"
                        sx={{
                          fontWeight: 500,
                          color: "#424242",
                        }}
                      >
                        Precio: $ {new Intl.NumberFormat('es-CL').format(product.price * divisa.value)} CLP
                      </Typography>}
      </CardActions>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuList>
            {/* <MenuItem onClick={() => handleMenuAction('Opción 1')}>
                <ListItemIcon>
                    <LocalOfferIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Añadir Oferta</ListItemText>
            </MenuItem> */}
            <MenuItem onClick={() => cambiar_vista()}>
            {product.is_visible?(
              <>
                <ListItemIcon>
                    <DoNotDisturbOnIcon fontSize="small" />
                </ListItemIcon>
      
                <ListItemText>Ocultar Producto</ListItemText>
                </>
                ): (
                  <>
                  <ListItemIcon>
                      <VisibilityIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Habilitar Producto</ListItemText>
                  </>
                )}
            </MenuItem>
            {/* <MenuItem onClick={() => handleMenuAction('Opción 1')}>
                <ListItemIcon>
                    <LocalShippingIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Importaciones</ListItemText>
            </MenuItem> */}
            <MenuItem onClick={() => handleEditar()}>
                <ListItemIcon>
                    <EditIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Editar</ListItemText>
            </MenuItem>
            {/* <Divider />
            <MenuItem onClick={() => handleEliminar()}>
            <ListItemIcon>
                <DeleteIcon color="primary" fontSize="small" />
                </ListItemIcon>
                <ListItemText>Eliminar</ListItemText>
            </MenuItem> */}
        </MenuList>
      </Menu>
    </Card>
  );
}
export default AdminProductCard;