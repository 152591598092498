import React, { Fragment, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { SnackbarProvider } from "notistack";

import "./App.css";

import LoginPage from "./presentation/pages/user/login";
import ContainersPage from "./presentation/pages/containers/containers";
import RegisterPage from "./presentation/pages/user/register";
import LoadingBackdrop from "./presentation/components/layout/loading_backdrop";
import SessionBackdrop from "./presentation/components/layout/session_redirect";
import Footer from "./presentation/components/layout/footer";
import { Header } from "./presentation/components/layout/header";
import HomePage from "./presentation/pages/home";
import ProductsPage from "./presentation/pages/products/products";
import HistorialComprasPage from "./presentation/pages/containers/historialcompras";
import EstadoDeCuenta from "./presentation/pages/containers/estadodecuenta";
import CartPage from "./presentation/pages/containers/cart";
import SimulationPage from "./presentation/pages/containers/simulation";
import AcceptUserPage from './presentation/pages/admin/clients/user_accept';
import AdminProducts from './presentation/pages/admin/products/admin_products';
import AdminClientsPage from './presentation/pages/admin/clients/admin_clients';
import AdminClientDetail from './presentation/pages/admin/clients/admin_client_detail';
import AdminClientsPaymentPlanPage from './presentation/pages/admin/clients/payment_plans';
import AdminDashboard from './presentation/pages/admin/dashboard';
import CustomerDashboard from './presentation/pages/containers/customer_dashboard';
import AdminProvidersPage from './presentation/pages/admin/providers/providers';
import AdminContainersPage from './presentation/pages/admin/containers/admin_containers';
import ContainerDetailPage from './presentation/pages/admin/containers/admin_container_detail';
import ContainerClientsPage from './presentation/pages/admin/containers/admin_clients_detail';
import AdminProviderFormPage from './presentation/pages/admin/providers/provider_form';
import ProductDetailPage from './presentation/pages/products/product_detail';
import CfoDashboard from './presentation/pages/cfo/dashboard';
import CfoClientsPage from './presentation/pages/cfo/clients/cfo_clients';
import CfoClientDetail from './presentation/pages/cfo/clients/cfo_client_detail';
import UserProfile from './presentation/pages/user/profile';
import UserAccound from './presentation/pages/user/accound';
import EmpresaProfile from './presentation/pages/user/empresa';
import CFOContainersPage from './presentation/pages/cfo/containers/acontainers_page';
import CFOProvidersPage from './presentation/pages/cfo/providers/providers';
import ScrollToTop from './ScrollToTop'; // Import the ScrollToTop component
import  AdminProductFormPage from './presentation/pages/admin/products/admin_product_form';
import { useAppDispatch } from "./presentation/hooks/hooks";
import { fetchUsdToClp } from "./presentation/redux/slices/exchange_rate_slice";
import AdminContainerForm from "./presentation/pages/admin/containers/admin_container_form";
const App = () => {
    const dispatch = useAppDispatch()
    useEffect(() => {
        // Llama al thunk para actualizar automáticamente al cargar la aplicación
        dispatch(fetchUsdToClp());
    
        // Si deseas actualizar periódicamente (por ejemplo, cada 10 minutos):
        const interval = setInterval(() => {
          dispatch(fetchUsdToClp());
        }, 10 * 60 * 1000); // 10 minutos en milisegundos
    
        return () => clearInterval(interval); // Limpia el intervalo al desmontar el componente
      }, [dispatch]);
    return (
        <Fragment>
            <SnackbarProvider />
            <BrowserRouter>
                <ScrollToTop /> {/* Scrolls to top on route change */}
                <LoadingBackdrop />
                <Routes>
                    <Route path="/" element={
                        <Fragment>
                            <Header />
                            <SessionBackdrop />
                            <HomePage />
                            <Footer />
                        </Fragment>
                    } />
                    <Route path="/login" element={<Fragment><Header /><LoginPage /><Footer /></Fragment>} />
                    <Route path="/register" element={<Fragment><Header /><RegisterPage /><Footer /></Fragment>} />
                    <Route path="/accept-user/:user_id" element={
                        <Fragment><Header /><AcceptUserPage /><Footer /></Fragment>} />
                    <Route path="/containers" element={
                        <Fragment>
                            <Header />
                            <SessionBackdrop />
                            <ContainersPage />
                            <Footer />
                        </Fragment>
                    } />
                    <Route path="/products" element={
                        <Fragment>
                            <Header />
                            <SessionBackdrop />
                            <ProductsPage />
                            <Footer />
                        </Fragment>
                    } />
                    <Route path="/product/:id" element={
                        <Fragment>
                            <Header />
                            <SessionBackdrop />
                            <ProductDetailPage />
                            <Footer />
                        </Fragment>
                    } />
                    <Route path="/historialcompras" element={
                        <Fragment>
                            <Header />
                            <SessionBackdrop />
                            <HistorialComprasPage />
                            <Footer />
                        </Fragment>
                    } />
                    <Route path="/cart" element={
                        <Fragment>
                            <Header />
                            <SessionBackdrop />
                            <CartPage />
                            <Footer />
                        </Fragment>
                    } />
                    <Route path="/simulation" element={
                        <Fragment>
                            <Header />
                            <SessionBackdrop />
                            <SimulationPage />
                            <Footer />
                        </Fragment>
                    } />
                    <Route path="/estadodecuenta/:id" element={
                        <Fragment>
                            <Header />
                            <SessionBackdrop />
                            <EstadoDeCuenta />
                            <Footer />
                        </Fragment>
                    } />
                    <Route path="/admin" element={
                        <Fragment>
                            <AdminDashboard />
                        </Fragment>
                    } />
                    <Route path="/admin/products" element = {
                        <Fragment>
                            <AdminProducts />
                        </Fragment>
                    } />
                    <Route path="/admin/clients" element={
                        <Fragment>
                            <AdminClientsPage />
                        </Fragment>
                    }/>
                    <Route path="/admin/containers" element = {
                        <Fragment>
                            <AdminContainersPage/>
                        </Fragment>
                    }/>
                    <Route path="/admin/container/create" element = {
                        <Fragment>
                            <AdminContainerForm/>
                        </Fragment>
                    }/>
                    <Route path="/admin/container/:id" element = {
                        <Fragment>
                            <ContainerDetailPage/>
                        </Fragment>
                    }/>
                    <Route path="/admin/container-clients/:id" element = {
                        <Fragment>
                            <ContainerClientsPage/>
                        </Fragment>
                    }/>
                    <Route path="/admin/providers" element = {
                        <Fragment>
                            <AdminProvidersPage/>
                        </Fragment>
                    }/>
                    <Route path="/admin/product/form" element = {
                        <Fragment>
                            <AdminProductFormPage/>
                        </Fragment>
                    }/>
                    <Route path="/admin/provider/form" element={
                        <Fragment>
                            <AdminProviderFormPage/>
                        </Fragment>
                    }/>
                    <Route path="/admin/clients/:id" element={
                        <Fragment>
                            <AdminClientDetail />
                        </Fragment>
                    } />
                    <Route path="/admin/clients/payment-plan" element={
                        <Fragment>
                            <AdminClientsPaymentPlanPage />
                        </Fragment>
                    } />
                    <Route path="/customer-dashboard" element={
                        <Fragment>
                            <Header />
                            <SessionBackdrop />
                            <CustomerDashboard />
                            <Footer />
                        </Fragment>
                    } />
                    <Route path="/cfo" element={
                        <Fragment>
                            <CfoDashboard />
                        </Fragment>
                    } />
                    <Route path="/cfo/clients" element={
                        <Fragment>
                            <CfoClientsPage />
                        </Fragment>
                    }/>
                    <Route path="/cfo/clients/:id" element={
                        <Fragment>
                            <CfoClientDetail />
                        </Fragment>
                    } />
                    <Route path="/cfo/containers" element={
                        <Fragment>
                            <CFOContainersPage />
                        </Fragment>
                    } />
                    <Route path="/profile" element={
                        <Fragment>
                            <Header />
                            <SessionBackdrop />
                            <UserProfile />
                            <Footer />
                        </Fragment>
                    } />
                    <Route path="/profile/account_details" element={
                        <Fragment>
                            <Header />
                            <SessionBackdrop />
                            <UserAccound />
                            <Footer />
                        </Fragment>
                    } />
                    <Route path="/profile/company_details" element={
                        <Fragment>
                            <Header />
                            <SessionBackdrop />
                            <EmpresaProfile />
                            <Footer />
                        </Fragment>
                    } />
                    <Route path="/cfo/providers" element = {
                        <Fragment>
                            <CFOProvidersPage/>
                        </Fragment>
                    }/>
                </Routes>
            </BrowserRouter>
        </Fragment>
    );

};

export default App;
