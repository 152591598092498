interface ProductEntity {
    id: string;
    name: string;
    supplier: string;
    quantity: number;
    maxQuantity: number;
    unitPurchasePrice: number;
    unitPurchasePriceUsd: number;
}

export interface ContainerProductDeudas {
    statusDescription: string;
    containerProducts: ProductEntity[];
    debtUsd: number;
    debtClp: number;
}

export function mapJsonToContainerWithProductsEntity(json): ContainerProductDeudas {
    return {
        id: json.id,
        statusDescription: json.status, 
        containerProducts: json.container_products.map((product) => ({
            id: product.id,
            name: product.product.name,
            supplier: product.product.supplier,
            quantity: product.quantity,
            maxQuantity: product.max_quantity,
            unitPurchasePrice: product.unit_purchase_price,
            unitPurchasePriceUsd: product.unit_purchase_price_usd,
        })),
        debtUsd: json.debt_usd,
        debtClp: json.debt_clp,
    } as ContainerProductDeudas;
}
