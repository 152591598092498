import { Either, Usecase } from "../../../core/index";
import { ApiRepositoryImpl } from "../../../data/repository/api";

export default class GetActiveUsersUsecase implements Usecase<{ActiveUsers:number,total_user_count:number}> {
    repository: ApiRepositoryImpl;

    constructor(repository: ApiRepositoryImpl) {
        this.repository = repository;
    }

    async execute(): Promise<Either<{ActiveUsers:number,total_user_count:number}>> {
        const data = await this.repository.getActiveUsers();

        return data;
    }
};