import React, { FC, Fragment, ReactElement, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import {
  Grid,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  Divider,
} from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { ButtonHeader } from "./button_header";
import { LogoutEvent } from "../../events/user/logout_event";

import AppImages from "../../../theme/app_images";

export const Header: FC = (): ReactElement => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { user } = useAppSelector((state) => state.user);
  const [isLoginPage, setIsLoginPage] = React.useState<boolean>(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  useEffect(() => {
    setIsLoginPage(location.pathname === "/login");
  }, [location]);

  const handleLogout = () => {
    dispatch(LogoutEvent());
  };

  const handleLogoClick = () => {
    navigate("/");
  };

  const handleProfileClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleViewProfile = () => {
    navigate("/profile");
    handleClose();
  };

  const handleLogoutMenu = () => {
    handleLogout();
    handleClose();
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" elevation={0}>
        <Toolbar
          sx={{
            backgroundColor: user !== null ? "primary" : "white",
          }}
        >
          <Grid container sx={{ padding: "20px", alignContent: "center" }}>
            {user !== null ? (
              <Fragment>
                <Grid item xs={2}>
                  <IconButton onClick={handleLogoClick} sx={{ padding: 0 }}>
                    <Box
                      component="img"
                      src={AppImages.logowhite}
                      alt="logo"
                      sx={{
                        width: "200px",
                        height: "auto",
                      }}
                    />
                  </IconButton>
                </Grid>
                <Grid item container xs={8} justifyContent={"center"}>
                  <ButtonHeader title="Productos" route="/products" />
                  <ButtonHeader title="Importación" route="/containers" />
                  <ButtonHeader title="Historial" route="/historialcompras" />
                  <ButtonHeader title="DashBoard" route="/customer-dashboard" />
                  {(user.id === "Admin" ) && (
                    <ButtonHeader title="Admin" route="/admin/products" />
                  )}
                  {user.id === "CFO" && (
                    <ButtonHeader title="CFO" route="/cfo" />
                  )}
                </Grid>
                <Grid
                  container
                  item
                  xs={2}
                  justifyContent={"end"}
                  alignContent={"center"}
                >
                  <Grid item>
                    <IconButton
                      onClick={handleProfileClick}
                      sx={{ color: "white" }}
                    >
                      <AccountCircleIcon sx={{ fontSize: "40px" }} />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <MenuItem onClick={handleViewProfile}>
                        <ListItemIcon>
                          <AccountCircleIcon fontSize="small" />
                        </ListItemIcon>
                        Ver Perfil
                      </MenuItem>
                      <MenuItem onClick={handleLogoutMenu}>
                        <ListItemIcon>
                          <LogoutIcon fontSize="small" />
                        </ListItemIcon>
                        Cerrar Sesión
                      </MenuItem>
                    </Menu>
                  </Grid>
                </Grid>
              </Fragment>
            ) : isLoginPage ? (
              <Grid item xs={12}>
                <img src={AppImages.logo} alt="logo" />
              </Grid>
            ) : (
              <Fragment>
                <Grid item xs={8}>
                  <img src={AppImages.logo} alt="logo" />
                </Grid>
                <Grid
                  item
                  container
                  justifyContent="flex-end"
                  alignContent="center"
                  xs={4}
                >
                  <Button
                    variant="outlined"
                    style={{
                      width: "20%",
                      borderRadius: "10px",
                      height: "40px",
                      backgroundColor: "#0A66A5",
                      color: "white",
                      fontSize: "16px",
                      margin: "10px",
                    }}
                    onClick={() => navigate("/register")}
                  >
                    Asociarse
                  </Button>
                  <Divider />
                  <Button
                    variant="outlined"
                    style={{
                      width: "20%",
                      borderRadius: "10px",
                      height: "40px",
                      backgroundColor: "#0A66A5",
                      color: "white",
                      fontSize: "16px",
                      margin: "10px",
                    }}
                    onClick={() => navigate("/login")}
                  >
                    Entrar
                  </Button>
                </Grid>
              </Fragment>
            )}
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  );
};
