import { Either } from "../../core/either";
import { ApiRepository } from "../repository/api";


export default class ChangeVisibilityUseCase {
  private repository: ApiRepository;

  constructor(repository: ApiRepository) {
    this.repository = repository;
  }

  async execute( params: {product_id:string}): Promise<Either<{id:string,is_visible:boolean}>> {
    return this.repository.changeVisibility(
        params.product_id
    );
  }
}
