import apiRepository from "../../../data/repository";
import LoginUserUsecase, { LoginParams } from "../../../domain/usecases/login_user_usecase";
import { setStatusUser, setErrorCode, StatusUserEnum } from "../../redux/slices/user_slice";

export const LoginEvent = (params: LoginParams) => async (dispatch) => {
    const usecase = new LoginUserUsecase(apiRepository);
    dispatch(setStatusUser(StatusUserEnum.Loading));
    dispatch(setErrorCode(null)); // Reseteamos el errorCode antes de intentar el login

    const result = await usecase.execute(params);
    //console.log("Resultado de execute:", result);
    result.fold(
        (error) => {
            //console.log("Error recibido:", error);
    
            // Intenta extraer el código del mensaje
            let extractedCode = 404; // Valor predeterminado si no encontramos el código en el mensaje
            if (error.message.includes("status code 401")) {
                extractedCode = 401;
            } else if (error.message.includes("status code 403")) {
                extractedCode = 403;
            }
    
            dispatch(setErrorCode(extractedCode)); // Asigna el código extraído
            dispatch(setStatusUser(StatusUserEnum.Error));
        },
        () => {
            dispatch(setStatusUser(StatusUserEnum.Success));
            dispatch(setErrorCode(null)); // Resetea el código de error en caso de éxito
        }
    );

};
