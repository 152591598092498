import { Either } from "../../core/either";
import { ApiRepository } from "../repository/api";
import { ProformaEntity } from "../entities";

interface CreateProformaParams {
  id: string;
  product_container_id: string;
  quantity: number;
  price: number;
  price_usd: number;
  dispatch: boolean;
  address: string;
}

export default class CreateProformaUseCase {
  private repository: ApiRepository;

  constructor(repository: ApiRepository) {
    this.repository = repository;
  }

  async execute(params: CreateProformaParams): Promise<Either<ProformaEntity>> {
    return this.repository.createProforma(
      params.id,
      params.product_container_id,
      params.quantity,
      params.price,
      params.price_usd,
      params.dispatch,
      params.address
    );
  }
}
