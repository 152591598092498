import apiRepository from '../../../data/repository/index';
import { setStatus, StatusLayoutEnum } from '../../redux/slices/layout_slice';
import GetAccountReceivableUsecase from '../../../domain/usecases/cfo_dashboard/get_account_receivable_usecase';


export const GetAccountReceivableEvent = () => async (dispatch) => {
  dispatch(setStatus(StatusLayoutEnum.Loading));
  const usecase = new GetAccountReceivableUsecase(apiRepository);

  const result = await usecase.execute();

  result.fold(
    (error) => {
      console.error('Error:', error);
    },
    (data) => {
      dispatch(setStatus(StatusLayoutEnum.Success));
    }
  );
  return result.right;
};

