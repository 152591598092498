import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

interface BarChartProps {
  containers: Record<string, { status: string }>;
}

const ContainerStatusBarChart: React.FC<BarChartProps> = ({ containers }) => {
  const containersArray = Object.values(containers);

  const statusCounts: Record<string, number> = containersArray.reduce(
    (acc, container) => {
      const status = container.status;
      acc[status] = (acc[status] || 0) + 1;
      return acc;
    },
    {}
  );

  const labels = Object.keys(statusCounts);
  const data = Object.values(statusCounts);

  const chartData = {
    labels,
    datasets: [
      {
        label: "Cantidad de Contenedores",
        data,
        backgroundColor: "#0A66A5",
        borderColor: "#074c81",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      tooltip: {
        callbacks: {
          label: (context: any) => `${context.raw} contenedores`,
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Estado",
        },
      },
      y: {
        title: {
          display: true,
          text: "Cantidad",
        },
        beginAtZero: true,
        ticks: {
          stepSize: 1,
          callback: function (value: number | string) {
            return Number(value).toFixed(0);
          },
        },
      },
    },
  };
  

  return <Bar data={chartData} options={options} />;
};

export default ContainerStatusBarChart;
