import { Either } from "../../core/either";
import { ApiRepository } from "../repository/api";
import { ContainerMovementEntity } from "../entities";
import { ICreateContainerMovement } from "../../data/datasource/api";

export default class CreateContainerMovementUseCase {
  private repository: ApiRepository;

  constructor(repository: ApiRepository) {
    this.repository = repository;
  }
  async execute(
    params: ICreateContainerMovement
  ): Promise<Either<ContainerMovementEntity>> {
    return this.repository.createContainerMovement(
      params.estimated_date,
      params.real_date,
      params.step,
      params.container,
      params.status
    );
  }
}
