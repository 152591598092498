import React, { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import ProviderInfo from "./provider_info";
import ContainerList from "./container_list";
import ContainerProductsModal from "./container_products_modal";

import {
  SupplierContainerEntity,
  SupplierEntity,
} from "../../../../domain/entities";

interface ProviderCardProps {
  supplier: SupplierEntity;
}

const ProviderCard: React.FC<ProviderCardProps> = ({ supplier }) => {
  const [selectedContainer, setSelectedContainer] =
    useState<SupplierContainerEntity>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openPaymentDialog, setOpenPaymentDialog] = useState(false);
  
  const formatAmount = (amount, currency = "CLP") => {
    const numericAmount = Number(amount);
  
    if (isNaN(numericAmount)) return amount;
  
    if (currency === "CLP") {
      // Formato para CLP: punto entre miles, coma para decimales
      return numericAmount.toLocaleString("es-CL", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    } else if (currency === "USD") {
      // Formato para USD: punto decimal, dos decimales
      return numericAmount.toLocaleString("en-US", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    }
  
    return amount; // Retorno sin formato si no se especifica una moneda válida
  };
  // Calcular la deuda total de todos los contenedores
  const totalDebt =
    supplier.containers?.reduce(
      (sum, container) => sum + (container.debt || 0),
      0
    ) || 0;

  // Calcular la cantidad total de contenedores
  const totalContainers = supplier.containers?.length || 0;

  // Calcular la cantidad total de productos en todos los contenedores
  const totalProducts =
    supplier.containers?.reduce(
      (sum, container) => sum + (container.containerProducts?.length || 0),
      0
    ) || 0;

  // Abrir modal de productos del contenedor
  const handleOpenDialog = (container: SupplierContainerEntity) => {
    setSelectedContainer(container);
    setOpenDialog(true);
  };

  // Cerrar modal de productos del contenedor
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedContainer(null);
  };

  // Abrir modal de pago
  const handleOpenPaymentDialog = () => {
    setOpenPaymentDialog(true);
  };
  const totalDebt_usd = 
  supplier.containers?.reduce(
    (sum, container) => sum + (container.debt_usd || 0),
    0
  ) || 0;

  // Cerrar modal de pago
  

  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{
        width: "100%",
        border: "1px solid #ccc",
        padding: "10px",
        borderRadius: "4px",
        marginBottom: "25px",
        overflow: "hidden",
      }}
    >
      <Grid container spacing={1} style={{ width: "100%" }}>
        {/* Nombre del proveedor centrado */}
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h6"
            gutterBottom
            style={{ fontWeight: "bold", color: "#0A66A5" }}
          >
            {supplier.name || "Nombre Proveedor"}
          </Typography>
        </Grid>

        {/* ProviderInfo: Ocupa la mitad del ancho */}
        <Grid container spacing={8} style={{ width: "100%" }}>
          {/* ProviderInfo: Ocupa la mitad del ancho */}
          <Grid
            item
            xs={6}
            style={{
              marginTop: "20px",
            }}
          >
            <ProviderInfo supplier={supplier} />
          </Grid>

          {/* ContainerList: Ocupa la otra mitad del ancho */}
          <Grid
            item
            xs={6}
            style={{
              marginTop: "20px",
            }}
          >
            <ContainerList
              containers={supplier.containers}
              onSelectContainer={handleOpenDialog}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" align="center" sx={{ marginTop: "15px" }}>
            Deuda Acumulada: ${formatAmount(totalDebt, "CLP")} CLP - $
            {formatAmount(totalDebt_usd, "USD")} USD
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body1" align="center" sx={{ marginTop: "10px" }}>
            Total de Contenedores: {totalContainers}
          </Typography>
          <Typography variant="body1" align="center">
            Total de Productos: {totalProducts}
          </Typography>
        </Grid>

        <ContainerProductsModal
          container={selectedContainer}
          open={openDialog}
          onClose={handleCloseDialog}
          onOpenPaymentDialog={handleOpenPaymentDialog}
        />
      </Grid>
    </Box>
  );
};

export default ProviderCard;
