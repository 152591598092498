import { combineReducers } from "redux";
import userSlice from "./slices/user_slice";
import layoutSlice from "./slices/layout_slice";
import containerSlice from "./slices/container_slice";
import registerSlice from "./slices/register_slice";
import productSlice from "./slices/product_slice";
import proformaSlice from "./slices/proforma_slice";
import admin_productSlice from "./slices/admin_products_slice";
import admin_containerSlice from "./slices/admin_container_slice";
import admin_simple_containerSlice from "./slices/admin_simple_container_slice";
import divisaSlice from "./slices/divisa_slice";
import companySlice from "./slices/company_slice";
import companyPaymentsSlice from "./slices/company_payments_slice";
import companyPaymentPlanSlice from "./slices/company_payment_plan_slice";
import { configureStore } from "@reduxjs/toolkit";
import metricSlice from "./slices/metric_slice";
import supplierSlice from "./slices/supplier_slice";
import transactionSlice from "./slices/transaction_slice";
import companyInvestmentsSlice from "./slices/company_investments_slice";
import exchangeRateSlice from "./slices/exchange_rate_slice";
import simulationSlice from "./slices/simulator_slice";

const rootReducer = combineReducers({
  user: userSlice,
  divisa: divisaSlice,
  layout: layoutSlice,
  container: containerSlice,
  register: registerSlice,
  product: productSlice,
  proformas: proformaSlice,
  suppliers: supplierSlice,
  admin_products: admin_productSlice,
  admin_container: admin_containerSlice,
  payment_plan: companyPaymentPlanSlice,
  simple_container: admin_simple_containerSlice,
  company: companySlice,
  simulation: simulationSlice,
  company_payments: companyPaymentsSlice,
  company_investments: companyInvestmentsSlice,

  metric: metricSlice,
  transaction: transactionSlice,
  exchange_rate: exchangeRateSlice,
});

const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
