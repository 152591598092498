import React, { useEffect, useState } from "react";
import { Container, Grid, Box, Divider, Card, Slider, Typography, Checkbox, Pagination } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { GetProductsEvent } from "../../../events/products/get_products_event";
import { AdminProductsModeEnum } from "../../../redux/slices/admin_products_slice";
import { SetProductFilterEvent } from '../../../events/filter/set_product_filter_event';
import { CategoryProductEnum } from '../../../../domain/entities';
import { ProductEntity } from "../../../../domain/entities";
import ProductsNavBar from "./ProductsNavBar";
import AdminProductCard from "./ProductCard";

const mapCategory = (category: CategoryProductEnum) => {
    switch (category) {
        case CategoryProductEnum.BATHROOM:
            return 'Baño';
        case CategoryProductEnum.CLOTHING:
            return 'Vestuario';
        case CategoryProductEnum.CONTRUCTION:
            return 'Construcción';
        case CategoryProductEnum.ELECTRONIC:
            return 'Electrónica';
        case CategoryProductEnum.FURNITURE:
            return 'Muebles';
        case CategoryProductEnum.KITCHEN:
            return 'Cocina';
        case CategoryProductEnum.OTHER:
            return 'Otros';
    }
}

const ProductsAdmin: React.FC = () => {
    const { products, filter } = useAppSelector((state) => state.product);
    const { mode } = useAppSelector((state) => state.admin_products);
    const dispatch = useAppDispatch();
    const [priceRange, setPriceRange] = useState([0, 1000]);
    const [page, setPage] = useState(1);
    const productsPerPage = 9;
    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    const filteredProducts = products.filter((product: ProductEntity) => 
        product.price >= filter.range[0] &&
        product.price <= filter.range[1] &&
        (filter.categories.length === 0 || filter.categories.includes(product.category))
    );

    const indexOfLastProduct = page * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);

    useEffect(() => {
        if (mode === AdminProductsModeEnum.View) {
            dispatch(GetProductsEvent());
        }
    }, [mode]);

    useEffect(() => {
        if (products.length > 0) {
            const minPrice = Math.min(...products.map((product) => product.price));
            const maxPrice = Math.max(...products.map((product) => product.price));
            setPriceRange([minPrice, maxPrice]); 
        }
    }, [products]);

    return (
        <Box>
            <ProductsNavBar />
            <Container>
                    <Grid container sx={{ padding: '10px' }} spacing={3}>
                        {/* Menú a la izquierda */}
                        <Grid item xs={12} sm={4} md={3} sx={{ minWidth: '100px' }}>
                            <Card elevation={5} sx={{
                                margin: '5px',
                                padding: '10px',
                                borderRadius: '30px',
                            }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant='h6'>
                                            Categorías
                                        </Typography>
                                    </Grid>
                                    <Grid container item xs={12} direction='column'>
                                        {Object.values(CategoryProductEnum).map((category) => (
                                            <Grid key={category}>
                                                <Checkbox
                                                    checked={filter.categories.includes(category)}
                                                    onChange={(_, value) => {
                                                        const categories = value ? [...filter.categories, category] :
                                                            filter.categories.filter((c) => c !== category);
                                                        dispatch(
                                                            SetProductFilterEvent(
                                                                { ...filter, categories },
                                                            ));
                                                    }}
                                                />
                                                {mapCategory(category)}
                                            </Grid>
                                        ))}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant='h6'>
                                            Precio
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <Slider
                                            getAriaLabel={() => 'Rango de precio'}
                                            value={filter.range}
                                            onChange={(_e, value) => dispatch(
                                                SetProductFilterEvent(
                                                    { ...filter, range: value as number[] },
                                                ))}
                                            valueLabelDisplay="auto"
                                            valueLabelFormat={(value) => `$${value}`}
                                            min={priceRange[0]}
                                            max={priceRange[1]}
                                            step={0.01} 
                                            sx={{ width: '80%' }} // Establece el ancho del slider
                                        />
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>

                        {/* Productos a la derecha */}
                        <Grid item xs={12} sm={8} md={9}>
                            <Grid container spacing={4} justifyContent="center">
                                {currentProducts.map((product: ProductEntity) => (
                                    <Grid item key={product.id} xs={12} sm={6.3} md={4.5} lg={3.4}>
                                        <AdminProductCard product={product} />
                                    </Grid>
                                ))}
                            </Grid>
                            {/* Paginación */}
                            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                                <Pagination
                                    count={Math.ceil(filteredProducts.length / productsPerPage)}
                                    page={page}
                                    onChange={handlePageChange}
                                    color="primary"
                                />
                            </Box>
                        </Grid>
                    </Grid>

                {/* {(mode === AdminProductsModeEnum.Create || mode === AdminProductsModeEnum.Edit) && (
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '80vh',
                    }}>
                        <ProductForm />
                    </Box>
                )} */}
            </Container>
        </Box>
    );
};

export default ProductsAdmin;
