import React from "react";
import { PaymentEntity } from "../../../domain/entities";
import { Card, Typography, Box } from "@mui/material";
import { format } from "date-fns";

interface TransactionListProps {
  payment: PaymentEntity;
}

const getStatusText = (status: number) => {
  switch (status) {
    case 0:
      return "Pendiente";
    case 1:
      return "Aprobado";
    case 2:
      return "Rechazado";
    case 3:
      return "Failed";
    default:
      return "Unknown";
  }
};

const formatAmount = (amount, currency = "CLP") => {
  const numericAmount = Number(amount);

  if (isNaN(numericAmount)) return amount;

  if (currency === "CLP") {
    // Formato para CLP: punto entre miles, coma para decimales
    return numericAmount.toLocaleString("es-CL", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  } else if (currency === "USD") {
    // Formato para USD: punto decimal, dos decimales
    return numericAmount.toLocaleString("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  }

  return amount; // Retorno sin formato si no se especifica una moneda válida
};


const TransactionList: React.FC<TransactionListProps> = ({ payment }) => {
  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h6" sx={{ color: "#0A66A5", fontWeight: 570 }}>
  Transacciones
</Typography>

      {payment.transactions.length === 0 ? (
        <Typography variant="body2" color="textSecondary">
          No transactions available.
        </Typography>
      ) : (
        payment.transactions.map((transaction) => (
          <Card
            key={transaction.id}
            elevation={2}
            sx={{
              padding: "10px",
              marginBottom: "8px",
              borderRadius: "8px",
              backgroundColor: "#f5f5f5", // Fondo gris claro
            }}
          >
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              ID de la transacción: {transaction.id}
            </Typography>
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              Monto: $ {formatAmount(transaction.amount, "CLP")} CLP - $ {formatAmount(transaction.amount_usd, "USD")} USD
            </Typography>
            <Box sx={{ mb: 1 }} />{" "}
            {/* Espacio entre el monto y el resto de la información */}
            <Typography variant="body2" color="textSecondary">
              Estado: {getStatusText(transaction.status)}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Realizada el:{" "}
              {format(new Date(transaction.created_at), "dd/MM/yyyy HH:mm")}hrs
            </Typography>
          </Card>
        ))
      )}
    </Box>
  );
};

export default TransactionList;
