import React, { useState } from "react";
import { Grid, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination } from "@mui/material";
import { ContainerCostumerEntity } from "../../../../../domain/entities";

interface ClientsProfomaAdminProps {
  customers: ContainerCostumerEntity[];
  container: any; // Replace 'any' with the appropriate type if known
}

const ClientsProfomaAdmin: React.FC<ClientsProfomaAdminProps> = ({ customers, container }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const renderCustomerProformaDetails = (customer: ContainerCostumerEntity, proformaData: any) => {
    const proforma = proformaData.proforma;
    //console.log("PROFORMA: ", proforma)
    if (!proforma) return null;

    const product = container.container_products.find((p) => p.product.id === proforma.product_container.product);
    const total_quantity = proforma.product_container.quantity;
    const total_price = proforma.price.toFixed(2) * proforma.quantity;
    const total_price_usd = proforma.price_usd.toFixed(2) * proforma.quantity;
    return (
      <TableRow key={proforma.id} hover sx={{ '&:hover': { backgroundColor: '#E3F2FD' } }}>
        <TableCell align="center" sx={{ padding: '16px', border: '1px solid #ddd' }}>{customer.company.name}</TableCell>
        <TableCell align="center" sx={{ padding: '16px', border: '1px solid #ddd' }}>{customer.company.rut}</TableCell>
        <TableCell align="center" sx={{ padding: '16px', border: '1px solid #ddd' }}>{proforma.id}</TableCell>
        <TableCell align="center" sx={{ padding: '16px', border: '1px solid #ddd' }}>{product?.product.name}</TableCell>
        <TableCell align="center" sx={{ padding: '16px', border: '1px solid #ddd' }}>
            {new Intl.NumberFormat('es-CL').format(total_quantity)}
        </TableCell>
        <TableCell align="center" sx={{ padding: '16px', border: '1px solid #ddd' }}>
            {new Intl.NumberFormat('es-CL').format(proforma.quantity)}
        </TableCell>
        <TableCell align="center" sx={{ padding: '16px', border: '1px solid #ddd' }}>${proforma.price.toLocaleString("es-ES", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
        <TableCell align="center" sx={{ padding: '16px', border: '1px solid #ddd' }}>${proforma.price_usd.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
        <TableCell align="center" sx={{ padding: '16px', border: '1px solid #ddd' }}>
          ${new Intl.NumberFormat('es-CL').format(total_price)}
        </TableCell>
        <TableCell align="center" sx={{ padding: '16px', border: '1px solid #ddd' }}>
          ${new Intl.NumberFormat('en-US').format(total_price_usd)}
        </TableCell>
        <TableCell align="center" sx={{ padding: '16px', border: '1px solid #ddd' }}>{proforma.dispatch ? "Despachado" : "Pendiente"}</TableCell>
      </TableRow>
    );
  };

  const renderCustomersTable = () => {
    // Calculate the sliced data for the current page
    const paginatedCustomers = customers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
      <TableContainer component={Paper} sx={{ boxShadow: 3, marginTop: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center" sx={{ color: '#0A66A5', fontWeight: 'bold', border: '1px solid #ddd' }}>Nombre Empresa</TableCell>
              <TableCell align="center" sx={{ color: '#0A66A5', fontWeight: 'bold', border: '1px solid #ddd' }}>RUT</TableCell>
              <TableCell align="center" sx={{ color: '#0A66A5', fontWeight: 'bold', border: '1px solid #ddd' }}>Proforma ID</TableCell>
              <TableCell align="center" sx={{ color: '#0A66A5', fontWeight: 'bold', border: '1px solid #ddd' }}>Producto</TableCell>
              <TableCell align="center" sx={{ color: '#0A66A5', fontWeight: 'bold', border: '1px solid #ddd' }}>Cantidad Producto Contenedor</TableCell>
              <TableCell align="center" sx={{ color: '#0A66A5', fontWeight: 'bold', border: '1px solid #ddd' }}>Cantidad Proforma</TableCell>
              <TableCell align="center" sx={{ color: '#0A66A5', fontWeight: 'bold', border: '1px solid #ddd' }}>Precio Unitario (CLP)</TableCell>
              <TableCell align="center" sx={{ color: '#0A66A5', fontWeight: 'bold', border: '1px solid #ddd' }}>Precio Unitario (USD)</TableCell>
              <TableCell align="center" sx={{ color: '#0A66A5', fontWeight: 'bold', border: '1px solid #ddd' }}>Precio Total (CLP)</TableCell>
              <TableCell align="center" sx={{ color: '#0A66A5', fontWeight: 'bold', border: '1px solid #ddd' }}>Precio Total (USD)</TableCell>
              <TableCell align="center" sx={{ color: '#0A66A5', fontWeight: 'bold', border: '1px solid #ddd' }}>Estado Despacho</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedCustomers.map((customer, idx) => (
              <React.Fragment key={idx}>
                {customer.proformas.map((proformaData, pIdx) =>
                  renderCustomerProformaDetails(customer, proformaData)
                )}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={customers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
            labelRowsPerPage="Filas por página"
          />
      </TableContainer>
    );
  };

  return (
    <Grid>
      {renderCustomersTable()}
    </Grid>
  );
};

export default ClientsProfomaAdmin;
