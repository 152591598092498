import React from "react";
import { List, ListItem, ListItemText, Typography, Divider } from "@mui/material";
import { SupplierContainerEntity } from "../../../../domain/entities";

interface ContainerListProps {
  containers: SupplierContainerEntity[];
  onSelectContainer: (container: SupplierContainerEntity) => void;
}

const ContainerList: React.FC<ContainerListProps> = ({ containers, onSelectContainer }) => {
  const inTransitContainers = containers.filter(container => container.status !== "Entregado");
  const deliveredContainers = containers.filter(container => container.status === "Entregado");

  return (
    <>
      <Typography variant="h6" align="center">
        Contenedores
      </Typography>
      <List style={{ maxHeight: 600, overflowY: "auto" }} aria-labelledby="container-list-title">
        <Typography variant="subtitle1" align="left" style={{ marginTop: 10 }}>
          En Tránsito
        </Typography>
        {inTransitContainers.map((container, index) => (
          <ListItem
            button
            key={`in-transit-${index}`}
            onClick={() => onSelectContainer(container)}
            sx={{ paddingBottom: "10px" }}
            aria-label={`Seleccionar contenedor en tránsito con id ${container.id}`}
          >
            <ListItemText
              primary={`Contenedor id: ${container.id || "Sin ID"}`}
              secondary={`Estado: ${container.status || "No disponible"}`}
            />
          </ListItem>
        ))}
        <Divider style={{ marginTop: 10, marginBottom: 10 }} />

        <Typography variant="subtitle1" align="left" style={{ marginTop: 10 }}>
          Entregados
        </Typography>
        {deliveredContainers.map((container, index) => (
          <ListItem
            button
            key={`delivered-${index}`}
            onClick={() => onSelectContainer(container)}
            sx={{ paddingBottom: "10px" }}
            aria-label={`Seleccionar contenedor entregado con id ${container.id}`}
          >
            <ListItemText
              primary={`Contenedor id: ${container.id || "Sin ID"}`}
              secondary={`Estado: ${container.status || "No disponible"}`}
            />
          </ListItem>
        ))}
      </List>
    </>
  );
};

export default ContainerList;
