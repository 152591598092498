import React, { useState, useEffect } from 'react';
import { StatusEntity } from '../../../../../domain/entities';
import { GetContainerAdminInfoEvent } from "../../../../events/container/get_container_admin_info_event";
import { Grid, Typography, Box } from '@mui/material';
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import { useNavigate } from 'react-router-dom';
import { setStatus, StatusLayoutEnum } from "../../../../redux/slices/layout_slice";
import { Status } from '../../../../redux/slices/admin_container_slice';
import Carousel from 'react-material-ui-carousel';

interface ContainerCardProps {
    container_id: string;
    status: StatusEntity;
}

const ContainerLayout: React.FC<ContainerCardProps> = ({ container_id, status }) => {
    const { single_container } = useAppSelector((state) => state.simple_container);
    const container = single_container[container_id];
    const [hover, setHover] = useState(false);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(GetContainerAdminInfoEvent(container_id));
        dispatch(setStatus(StatusLayoutEnum.Success));
    }, [dispatch, container_id]);

    if (!container) return null;

    const handleMouseEnter = () => setHover(true);
    const handleMouseLeave = () => setHover(false);

    const handleNavigate = () => {
      navigate(`/admin/container-clients/${container.id}`);
    };

    const getLastModified = (status: StatusEntity) => {
        return status.updatedAt;
    };

    return (
        <Grid container 
              sx={{ 
                height: '100%', 
                padding: '10px', 
                border: '1px solid #ccc',  
                transition: 'background-color 0.3s',
                backgroundColor: hover ? '#f0f8ff' : '#fff', 
                alignItems: 'center', 
                textAlign: 'center'
              }} 
              onMouseEnter={handleMouseEnter} 
              onMouseLeave={handleMouseLeave}
              >
            
            <Grid item xs={2} sx={{ backgroundColor: '#0A66A5', padding: '10px', borderRadius: '5px', color: 'white'}}>
                <Typography onClick={handleNavigate} sx={{ cursor: 'pointer' }}>
                    {container.name}
                </Typography>
            </Grid>
            <Box sx={{ width: '2px', backgroundColor: '#ccc', height: '40px', margin: 'auto' }} />
            <Grid item xs={2} sx={{ padding: '10px' }}>
                <Typography>{new Date(container.createdAt).toLocaleString()}</Typography>
            </Grid>
            <Box sx={{ width: '2px', backgroundColor: '#ccc', height: '40px', margin: 'auto' }} />
            <Grid item xs={2} sx={{ padding: '10px' }}>
                <Typography>{new Date(getLastModified(status)).toLocaleString()}</Typography>
            </Grid>
            <Box sx={{ width: '2px', backgroundColor: '#ccc', height: '40px', margin: 'auto' }} />
            <Grid item xs={2} sx={{ padding: '10px' }}>
                {status.description !="" ? (<Typography>{status.description}</Typography>):(
                    <Typography>{Status[status.id-1].name}</Typography>
                )}
            </Grid>
            <Box sx={{ width: '2px', backgroundColor: '#ccc', height: '40px', margin: 'auto' }} />
            <Grid item xs={2} sx={{ padding: '10px' }}>
                <Carousel
                    sx={{
                        width: '100%',
                        '& .MuiCarousel-dots': {
                            bottom: '5px', 
                        }
                    }}
                >
                    {container.containerProducts.map((product) => (
                        <Box key={product.product.id} sx={{ textAlign: 'center' }}>
                            <img src={product.product.imageUrl} style={{ height: '25px', objectFit: 'contain' }} />
                            <Typography 
                                variant="h6" 
                                sx={{ fontSize: '14px' }}>
                                {product.product.name}
                            </Typography>
                        </Box>
                    ))}
                </Carousel>
            </Grid>
        </Grid>
    );
};

export default ContainerLayout;
