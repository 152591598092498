import { StatusLayoutEnum, setMessage, setStatus } from "../../redux/slices/layout_slice";
import apiRepository from "../../../data/repository";
import PatchCompanyUseCase from "../../../domain/usecases/patch_company_usecase";
import { setCompany } from "../../redux/slices/user_slice";

export const PatchCompanyEvent = (params: {name: string,  address:string, phone:string, email:string}) => async (dispatch) => {
    dispatch(setStatus(StatusLayoutEnum.Loading));
    const usecase = new PatchCompanyUseCase(apiRepository);
    const result = await usecase.execute(params);

    result.fold(
        (error) => {
            dispatch(setMessage(error.message));
            dispatch(setStatus(StatusLayoutEnum.Error));
        },
        (data) => {
            dispatch(setStatus(StatusLayoutEnum.Success));
            //console.log("data:", data)
            dispatch(setCompany(data));
        }
    );
}