import React from "react";
import { Box, Card, Grid, Typography } from "@mui/material";

interface Product {
  name: string;
  id: string;
}

interface Proforma {
  duration: number | string;
  product: Product;
  status: string;
}

interface ProformasTableProps {
  proformas: { [key: string]: Proforma };
  title: string;
}

const ProformasTable: React.FC<ProformasTableProps> = ({ proformas, title }) => {
  //console.log("proformas:", proformas);
  const proformasArray = Object.entries(proformas).map(([key, value]) => ({
    key,
    ...value,
  }));
  const inTransitProformas = proformasArray.filter((proforma) => proforma.status !== "Entregado");
  const deliveredProformas = proformasArray.filter((proforma) => proforma.status === "Entregado");
  const proformasByTitle =
    title === "Proformas Completadas" ? deliveredProformas : inTransitProformas;

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "500px",
        height: "400px",
        padding: "16px",
        backgroundColor: "#f9f9f9",
        borderRadius: "8px",
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: 1,
      }}
    >
      <Typography
        variant="h5"
        sx={{ color: "#0A66A5", marginBottom: 2, fontWeight: "bold" }}
      >
        {title}
      </Typography>
      <Box
        sx={{
          width: "100%",
          overflowY: "auto",
          maxHeight: "400px",
        }}
      >
        {proformasByTitle.length === 0 ? (
          <Typography
            variant="body2"
            color="textSecondary"
            align="center"
            sx={{ color: "#0A66A5", textAlign: "center", marginTop: "16px" }}
          >
            No hay proformas para mostrar.
          </Typography>
        ) : (
          <Grid container spacing={2}>
            {proformasByTitle.map((proforma, index) => (
              <Grid item xs={12} key={index}>
                <Card
                  sx={{
                    padding: "15px",
                    borderRadius: "7px",
                    textAlign: "left",
                    marginBottom: "10px",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#0A66A5",
                      fontWeight: 600,
                      marginBottom: "8px",
                    }}
                  >
                    Proforma ID: {proforma.key}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: 600, marginBottom: "8px" }}
                  >
                    Duración:{" "}
                    {typeof proforma.duration === "number"
                      ? `${proforma.duration} días`
                      : proforma.duration}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: 600, marginBottom: "8px" }}
                  >
                    Estado: {proforma.status}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: 600, marginBottom: "8px" }}
                  >
                    Producto: {proforma.product?.name || "Producto sin nombre"}
                  </Typography>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default ProformasTable;
