import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent, // Importa el tipo correcto
} from "@mui/material";
import { SupplierContainerEntity } from "../../../../domain/entities";
import { useAppSelector } from "../../../hooks/hooks";

interface PaymentModalProps {
  container: SupplierContainerEntity;
  paymentAmount: string;
  setPaymentAmount: (value: string) => void;
  open: boolean;
  onClose: () => void;
  onConfirmPayment: (amountClp: number, amountUsd: number) => void;
}

const PaymentModal: React.FC<PaymentModalProps> = ({
  container,
  paymentAmount,
  setPaymentAmount,
  open,
  onClose,
  onConfirmPayment,
}) => {
  const [currency, setCurrency] = useState<"USD" | "CLP">("USD");
  const { usdToClp } = useAppSelector((state) => state.exchange_rate);

  // Usa el tipo SelectChangeEvent
  const handleCurrencyChange = (event: SelectChangeEvent<"USD" | "CLP">) => {
    setCurrency(event.target.value as "USD" | "CLP");
  };

  const handleConfirmPayment = () => {
    const amountClp =
      currency === "USD" && usdToClp
        ? Number(paymentAmount) * usdToClp
        : Number(paymentAmount);

    const amountUsd =
      currency === "CLP" && usdToClp
        ? Number(paymentAmount) / usdToClp
        : Number(paymentAmount);

    onConfirmPayment(amountClp, amountUsd);
  };

  const convertedAmount =
    currency === "USD"
      ? usdToClp && paymentAmount
        ? (Number(paymentAmount) * usdToClp).toFixed(0)
        : ""
      : usdToClp && paymentAmount
      ? (Number(paymentAmount) / usdToClp).toFixed(2)
      : "";

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Realizar Pago para Contenedor {container?.id}</DialogTitle>
      <DialogContent>
        <FormControl fullWidth sx={{ marginTop: "5px" }}>
          <InputLabel>Moneda</InputLabel>
          <Select
            value={currency}
            onChange={handleCurrencyChange} // No hay errores ahora
            label="Moneda"
          >
            <MenuItem value="USD">USD</MenuItem>
            <MenuItem value="CLP">CLP</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label={`Monto en ${currency}`}
          type="number"
          value={paymentAmount}
          onChange={(e) => {
            const value = e.target.value;
            // Permite solo números no negativos
            if (/^\d*\.?\d*$/.test(value)) {
              setPaymentAmount(value);
            }
          }}
          inputProps={{
            min: 0, // Limita el valor mínimo a 0
          }}
          fullWidth
          margin="normal"
        />
        <TextField
          label={`Monto en ${currency === "USD" ? "CLP" : "USD"}`}
          type="text"
          value={convertedAmount}
          fullWidth
          margin="normal"
          disabled
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancelar
        </Button>
        <Button
          onClick={handleConfirmPayment}
          color="primary"
          disabled={!paymentAmount}
        >
          Confirmar Pago
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PaymentModal;
