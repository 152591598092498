import React, { useEffect, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import {
  Box,
  Grid,
  Typography,
  FormControl,
  InputAdornment,
  OutlinedInput,
  Pagination,
} from "@mui/material/";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import Stack from "@mui/material/Stack";
import { GetPaymentPlantEvent } from "../../../events/company/get_payment_plan_event";
import SideMenu from "../../../components/admin/layout/SideMenu";
import Header from '../../../components/admin/layout/Header';
import ClientTable from "../../../components/admin/clients/PaymentPlan/ClientPaymentTable";
import RestrictionComponent from "../../../components/restriction/restriction";

const ClientsPaymentPlanView: React.FC = () => {
  const { companyPayments } = useAppSelector((state) => state.payment_plan);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const itemsPerPage = 10;

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(GetPaymentPlantEvent());
  }, [dispatch]);

  const filteredClients =
    companyPayments?.filter(
      (company) =>
        company.containers.length > 0 &&
        (company.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
         company.owner.toLowerCase().includes(searchTerm.toLowerCase()))
    ) || [];

  const totalPages = Math.ceil(filteredClients.length / itemsPerPage);
  const paginatedClients = filteredClients.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  const handlePageChange = (_: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };


  return (
    <>
      <RestrictionComponent auth='Admin'/>
      <CssBaseline enableColorScheme />
      <Box sx={{ display: "flex" }}>
        <SideMenu />
        
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            overflow: "auto",
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            height: "100vh",
          }}
        >

        <Stack
            spacing={2}
            sx={{
              alignItems: 'stretch',
              mx: 0,
              pb: 10,
              mt: { xs: 8, md: 0 },
            }}
          >
            <Header />
            
          <Grid container spacing={2} sx={{ backgroundColor: "white" }}>
            <Grid item xs={8} container alignItems="center">
              <Typography
                variant="h4"
                sx={{
                  fontWeight: "bold",
                  color: "#0A66A5",
                  padding: "10px",
                }}
                >
                <strong>Clientes</strong>
              </Typography>
            </Grid>
            <Grid item xs={4} container justifyContent="flex-end" alignItems="center">
              <FormControl
                sx={{
                  width: { xs: "100%", md: "20ch" },
                  padding: "10px",
                  marginRight: "30px",
                }}
                variant="outlined"
                >
                <OutlinedInput
                  size="small"
                  id="search"
                  placeholder="Buscar cliente"
                  sx={{ flexGrow: 1 }}
                  startAdornment={
                    <InputAdornment position="start" sx={{ color: "text.primary" }}>
                      <SearchRoundedIcon fontSize="small" />
                    </InputAdornment>
                  }
                  inputProps={{
                    "aria-label": "search",
                  }}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  />
              </FormControl>
            </Grid>
          </Grid>
          <Stack
            spacing={2}
            sx={{
              alignItems: "center",
              mx: 3,
              pb: 10,
              mt: { xs: 8, md: 5 },
              backgroundColor: "white",
              padding: "8px",
              flexGrow: 1,
            }}
            >
            <Grid container style={{ width: "95%" }}>
            <Grid
              item
              xs={12}
              style={{
                height: "50px",
                borderBottom: "1px solid #ccc",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
              }}
              >
              <Grid container sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Grid item xs={1.5} sx={{ textAlign: "center" }}>
                  <Typography variant="body1" sx={{ fontWeight: "bold", color: "#0A66A5" }}>
                    Nombre
                  </Typography>
                </Grid>
                <Grid item xs={1.5} sx={{ textAlign: "center" }}>
                  <Typography variant="body1" sx={{ fontWeight: "bold", color: "#0A66A5" }}>
                    Compañía
                  </Typography>
                </Grid>
                <Grid item xs={1.5} sx={{ textAlign: "center" }}>
                  <Typography variant="body1" sx={{ fontWeight: "bold", color: "#0A66A5" }}>
                    Contenedores Activos
                  </Typography>
                </Grid>
                <Grid item xs={1.5} sx={{ textAlign: "center" }}>
                  <Typography variant="body1" sx={{ fontWeight: "bold", color: "#0A66A5" }}>
                    Por Pagar (CLP)
                  </Typography>
                </Grid>
                <Grid item xs={1.5} sx={{ textAlign: "center" }}>
                  <Typography variant="body1" sx={{ fontWeight: "bold", color: "#0A66A5" }}>
                    Por Pagar (USD)
                  </Typography>
                </Grid>
                <Grid item xs={1.5} sx={{ textAlign: "center" }}>
                  <Typography variant="body1" sx={{ fontWeight: "bold", color: "#0A66A5" }}>
                    Total Transacciones (CLP)
                  </Typography>
                </Grid>
                <Grid item xs={1.5} sx={{ textAlign: "center" }}>
                  <Typography variant="body1" sx={{ fontWeight: "bold", color: "#0A66A5" }}>
                    Total Transacciones (USD)
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            </Grid>
            <Grid item style={{ width: "95%" }}>
              <Grid container spacing={1}>
                {paginatedClients.map((company) => (
                  <ClientTable company={company} key={company.id} />
                ))}
                {paginatedClients.length === 0 && (
                  <Grid item xs={12}>
                    <Typography variant="body1">No se encontraron resultados.</Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Stack>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              paddingBottom: "20px",
            }}
            >
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              color="primary"
              />
          </Grid>
          </Stack>
        </Box>
      </Box>
    </>
  );
};

export default ClientsPaymentPlanView;
