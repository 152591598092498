import React, { Fragment } from "react";
import { AppBar, Box, Toolbar, Typography, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";

const ContainersNavBar: React.FC<{ title: string }> = ({ title }) => {
  const navigate = useNavigate();

  const handleCreateMenuOpen = () => {
    navigate(`/admin/container/create`);
  };

  return (
    <Fragment>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="static"
          sx={{
            boxShadow: "none",
            backgroundColor: "#757575", // Gris estándar
          }}
        >
          <Toolbar variant="dense">
            <Typography
              variant="h6"
              color="inherit"
              component="div"
              sx={{ flexGrow: 1 }}
            >
              {title || "Contenedores"}
            </Typography>
            <Button
              variant="contained"
              onClick={handleCreateMenuOpen}
              startIcon={<AddIcon />}
              sx={{
                backgroundColor: "#4caf50",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#388e3c",
                },
              }}
            >
              Crear Container
            </Button>
          </Toolbar>
        </AppBar>
      </Box>
    </Fragment>
  );
};

export default ContainersNavBar;
