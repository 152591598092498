import React, { useState } from 'react';
import {
  Grid,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  TextField,
  SelectChangeEvent,
  Stack
} from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { CreateContainerEvent } from '../../../events/admin/create_container_event';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../hooks/hooks';
import Header from '../../../components/admin/layout/Header';
import SideMenu from '../../../components/admin/layout/SideMenu';
import RestrictionComponent from '../../../components/restriction/restriction';

export default function AdminContainerForm() {
  const [name, setName] = useState('');
  const [capacity, setCapacity] = useState<number | ''>(''); 
  const [fixedCost, setFixedCost] = useState<number | ''>(''); 
  const [tax, setTax] = useState<number | ''>(''); 
  const [paymentCount, setPaymentCount] = useState(1);
  const [percentages, setPercentages] = useState<number[]>([1]);
  const [dueDates, setDueDates] = useState<string[]>([new Date().toISOString().split('T')[0]]);
  const [deadlines, setDeadlines] = useState<string[]>([new Date().toISOString().split('T')[0]]);
  const [errors, setErrors] = useState<string[]>(Array(paymentCount).fill('')); // Errores por índice
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const calculatePercentages = (count: number): number[] => {
    if (count === 1) return [1];
    const weights = Array.from({ length: count }, (_, i) => count - i);
    const totalWeight = weights.reduce((sum, weight) => sum + weight, 0);
    const per = weights.map((weight) => Math.round((weight / totalWeight) * 100) / 100); // Redondeo a 2 decimales
    const difference = 1 - per.reduce((sum, p) => sum + p, 0);
    per[per.length - 1] += difference;
    return per;
  };

  const handlePaymentCountChange = (event: SelectChangeEvent<number>) => {
    const count = Number(event.target.value);
    setPaymentCount(count);
    setPercentages(calculatePercentages(count));
    const today = new Date().toISOString().split('T')[0];
    setDueDates(Array(count).fill(today));
    setDeadlines(Array(count).fill(today));
    setErrors(Array(count).fill(''));
  };

  const validateDates = (dueDate: string, deadline: string, index: number) => {
    if (new Date(dueDate) >= new Date(deadline)) {
      const newErrors = [...errors];
      newErrors[index] = 'La fecha de vencimiento debe ser mayor a la fecha límite.';
      setErrors(newErrors);
      return false;
    }
    const newErrors = [...errors];
    newErrors[index] = '';
    setErrors(newErrors);
    return true;
  };

  const handleCreate = async () => {
    // Validar campos antes de crear
    if (!name) {
      alert('El nombre no puede estar vacío.');
      return;
    }
    if (Number(capacity) <= 0 || Number(fixedCost) <= 0 || Number(tax) <= 0) {
      alert('La capacidad, costo fijo y tax deben ser mayores a cero.');
      return;
    }
    
    const roundedPercentages = percentages.map((p) => Math.round(p * 100) / 100);

    const totalPercentage = roundedPercentages.reduce((sum, p) => sum + p, 0);
    if (totalPercentage !== 1) {
      alert('La suma de los porcentajes debe ser igual a 100% (1 en formato decimal).');
      return;
    }

    const container = await CreateContainerEvent(name,Number(capacity),Number(fixedCost),Number(tax),paymentCount,percentages,dueDates,deadlines,dispatch);
    navigate(`/admin/container-clients/${container.id}`);
  };

  const handleCancel = () => {
    navigate(`/admin/containers`);
  };

  return (
    <>
      <RestrictionComponent auth='Admin'/>
      <CssBaseline />
      <Box sx={{ display: 'flex' }}>
        <SideMenu />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column', 
          }}
        >
          <Stack
            spacing={2}
            sx={{
              alignItems: 'center',
              pb: 10,
              mt: { xs: 8, md: 0 },
            }}
          >
            <Header />
        <Box sx={{ display: 'flex', justifyContent: 'center', padding: 4 }}>
          <Grid container spacing={3} sx={{ maxWidth: 800 }}>
            {/* Nombre */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Nombre"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>

            {/* Capacidad */}
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                type="number"
                label="Capacidad"
                value={capacity}
                onChange={(e) => setCapacity(Number(e.target.value) || '')}
                inputProps={{ min: 0 }}
              />
            </Grid>

            {/* Costo fijo */}
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                type="number"
                label="Costo Fijo (USD)"
                value={fixedCost}
                onChange={(e) => setFixedCost(Number(e.target.value) || '')}
                inputProps={{ min: 0 }}
              />
            </Grid>

            {/* Tax */}
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                type="number"
                label="Impuesto (USD)"
                value={tax}
                onChange={(e) => setTax(Number(e.target.value) || '')}
                inputProps={{ min: 0 }}
              />
            </Grid>

            {/* Select pagos */}
            <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
              <FormControl fullWidth>
                <InputLabel id="payment-count-label">Cantidad de Pagos</InputLabel>
                <Select
                  labelId="payment-select-label"
                  value={paymentCount}
                  label="Cantidad de Pagos"
                  onChange={handlePaymentCountChange}
                  inputProps={{ style: { textAlign: "center" } }} 
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        textAlign: 'center', // Centra el texto en el menú
                      },
                    },
                  }}
                >
                  {[1, 2, 3, 4, 5].map((value) => (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Inputs dinámicos de porcentajes, due dates y deadlines */}
            {percentages.map((_, index) => (
              <React.Fragment key={index}>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    type="number"
                    label={`Porcentaje ${index + 1}`}
                    value={percentages[index]}
                    onChange={(e) => {
                      const updatedPercentages = [...percentages];
                      updatedPercentages[index] = Math.min(Math.max(Number(e.target.value), 0), 100); // Limitar a 100
                      setPercentages(updatedPercentages);
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    type="date"
                    label={`Fecha de Vencimiento pago ${index + 1}`}
                    value={dueDates[index]}
                    onChange={(e) => {
                      const updatedDueDates = [...dueDates];
                      updatedDueDates[index] = e.target.value;
                      setDueDates(updatedDueDates);
                      validateDates(e.target.value, deadlines[index], index);
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    type="date"
                    label={`Fecha límite pago ${index + 1}`}
                    value={deadlines[index]}
                    onChange={(e) => {
                      const updatedDeadlines = [...deadlines];
                      updatedDeadlines[index] = e.target.value;
                      setDeadlines(updatedDeadlines);
                      validateDates(dueDates[index], e.target.value, index);
                    }}
                  />
                </Grid>
                {errors[index] && (
                  <Grid item xs={12}>
                    <Box color="error.main">{errors[index]}</Box>
                  </Grid>
                )}
              </React.Fragment>
            ))}

            {/* Botones */}
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
              <Button variant="contained" color="primary" onClick={handleCreate}>
                Crear
              </Button>
              <Button variant="outlined" color="secondary" onClick={handleCancel}>
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </Box>
        </Stack>
        </Box>
      </Box>
    </>
  );
}
