import { Either, Usecase } from "../../core/index";
import { ApiRepositoryImpl } from "../../data/repository/api";
import { ContainerProductDeudas } from "../entities";

export default class GetContainerProductDeudasUsecase implements Usecase<ContainerProductDeudas, string> {
    repository: ApiRepositoryImpl;

    constructor(repository: ApiRepositoryImpl) {
        this.repository = repository;
    }

    async execute(container_id: string): Promise<Either<ContainerProductDeudas>> {
        const data = await this.repository.getContainerProductsDebts(container_id);
        return data;
    }
}
