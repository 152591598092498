import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';

export interface ExchangeRateState {
  usdToClp: number | null; // Valor del tipo de cambio
  loading: boolean; // Indica si se está obteniendo el dato
  error: string | null; // Mensaje de error si falla la API
}

const initialState: ExchangeRateState = {
  usdToClp: null,
  loading: false,
  error: null,
};

// Thunk para obtener el valor automáticamente desde una API
export const fetchUsdToClp = createAsyncThunk(
  'exchange_rate/fetchUsdToClp',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetch('https://api.exchangerate-api.com/v4/latest/USD');
      if (!response.ok) {
        throw new Error(`Error al obtener el tipo de cambio: ${response.status}`);
      }
      const data = await response.json();
      return data.rates.CLP; // Retorna el valor de USD a CLP. Aqui se puede sumar un spread.
    } catch (error: any) {
      return rejectWithValue(error.message || 'Error desconocido');
    }
  }
);

const exchangeRateSlice = createSlice({
  name: 'exchange_rate',
  initialState,
  reducers: {
    setUsdToClp(state, action: PayloadAction<number>) {
      state.usdToClp = action.payload;
      state.error = null; // Limpia errores previos
    },
    clearUsdToClp(state) {
      state.usdToClp = null;
      state.error = null; // Limpia errores previos
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsdToClp.pending, (state) => {
        state.loading = true; // Comienza la carga
        state.error = null; // Limpia errores previos
      })
      .addCase(fetchUsdToClp.fulfilled, (state, action) => {
        state.loading = false; // Termina la carga
        state.usdToClp = action.payload; // Actualiza el valor
        state.error = null; // Limpia errores previos
      })
      .addCase(fetchUsdToClp.rejected, (state, action) => {
        state.loading = false; // Termina la carga
        state.error = action.payload as string; // Guarda el mensaje de error
      });
  },
});

export const { setUsdToClp, clearUsdToClp } = exchangeRateSlice.actions;
export default exchangeRateSlice.reducer;
