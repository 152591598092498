import { HttpClientType } from "../../core/http_client";
import {
  ContainerEntity,
  mapJsonToContainerEntity,
  UserEntity,
  mapJsonToUserEntity,
  ProductEntity,
  mapJsonToProductEntity,
  ContainerFullDetailEntity,
  mapJsonToContainerFullDetailEntity,
  ProformaEntity,
  mapJsonToProformaEntity,
  TransactionEntity,
  mapJsonToTransactionEntity,
  ContainerProductEntity,
  mapJsonToContainerProductEntity,
  CompanyEntity,
  mapJsonToCompanyEntity,
  CompanyPaymentsEntity,
  mapJsonToCompanyPaymentsEntity,
  ClarificationRequestEntity,
  mapJsonToClarificationRequestEntity,
  MetricUnitEntity,
  mapJsonToMetricUnit,
  ContainerMovementEntity,
  mapJsonToContainerMovementEntity,
  ContainerAdminEntity,
  mapJsonToContainerAdminEntity,
  ContainerAdminInfoEntity,
  mapJsonToContainerAdminInfoEntity,
  SupplierEntity,
  mapJsonToSupplierEntity,
  PaymentPlanEntity,
  mapJsonToPaymentPlanEntity,
  ExternalPaymentEntity,
  mapJsonToExternalPaymentEntity,
  ContainerClientInfoEntity,
  mapJsonToContainerClientInfoEntity,
  FullProductEntity,
  mapJsonToFullProductEntity,
  ContainerPaymentsFullDetailEntity,
  mappJsonToContainerPaymentsFullDetail,
  InvestmentEntity,
  mapJsonToInvestmentEntity,
  SimulationEntity,
  mapJsonToSimulationEntity,
  ContainerProductDeudas,
  mapJsonToContainerWithProductsEntity
} from "../../domain/entities";

export interface ILoginUser {
  email: string;
  password: string;
}

export interface ICreateTransaction {
  id: string;
  file: Blob;
  payment_id: string;
  amount: number;
  amount_usd: number;
}

export interface IPutTransaction {
  id: string;
  payment_id: string;
  status: number;
}

export interface ICreateProforma {
  id: string;
  product_container_id: string;
  quantity: number;
  price: number;
  price_usd: number;
  dispatch: boolean;
  address: string;
}

export interface IRegisterUser {
  user_data: {
    first_name: string;
    last_name: string;
    email: string;
  };
  company_data: {
    phone: string;
    business_rut: string;
    business_name: string;
    business_category: string;
  };
}
export interface ICreateProduct {
  id: string;
  name: string;
  price: number;
  category: number;
  description: string;
  width:number;
  length:number;
  depth:number;
  weight: number;
  materials: string;
  image_url: string;
  supplier: string;
}

export interface IGetContainer {
  id: string;
}

export interface ICreateContainer {
  name:string,
  capacity: number;
  fixed_cost: number;
  tax: number;
  payments: number;
  percentages: number[];
  due_dates: string[];
  deadlines: string[];
}

export interface IPutContainer {
  id: string;
  name:string;
  capacity: number;
  fixed_cost: number;
  tax:number;
}

export interface ICreateProductContainer {
  min_quantity: number;
  max_quantity: number;
  unit_base_price: number;
  increased_percent: number;
  interval: number;
  container: string;
  product: string;
  metric_unit: number;
  units_per_package: number;
  unit_base_price_usd:number;
  unit_purchase_price_usd: number;
  unit_purchase_price:number;
}

export interface ICreateMetric {
  name: string;
  description: string;
  square_meters: number;
}

export interface IClarificationRequest {
  id: string;
  proformaId: string;
  clientName: string;
  message: string;
  email: string;
  selectedTransaction: string;
}
export interface ICreateExternalPayment {
  id: string;
  supplierId: string;
  containerId: string;
  amount: number;
  amount_usd: number;
  description: string;
  
}

export interface ICreateContainerMovement {
  estimated_date: string;
  real_date: string;
  step: number;
  container: string;
  status: number;
}

export interface IPutContainerMovement {
  id: number;
  estimated_date: string;
  real_date: string;
  step: number;
  container: string;
  status: number;
}

export interface IPutProfile {
  email: string;
  first_name: string;
  last_name: string;
}

export interface IChangePassword {
    old_password: string,
    new_password:string,
    confirm_password:string
}

export interface IChangeCompany {
  name: string,
  address:string,
  phone:string,
  email:string
}

export interface IPostSimulation {
  precio_venta: number;
  precio_compra: number;
  cantidad: number;
  product_id: string;
}

export interface ApiDataSource {
  getCompanyInvestments<T>(getCompanyInvestments: any): unknown;
  // Containers
  getContainers(): Promise<ContainerEntity[]>;
  getContainersFullDetail(): Promise<ContainerFullDetailEntity[]>;
  getAllContainers(): Promise<ContainerAdminEntity[]>;
  getSingleContainerInfo(params: IGetContainer): Promise<ContainerAdminInfoEntity>;
  getContainerClientInfo(params: IGetContainer): Promise<ContainerClientInfoEntity>;
  createContainer(params: ICreateContainer): Promise<ContainerEntity>;
  getContainerProductsDebts(container_id:string): Promise<ContainerProductDeudas>
  putContainer(params:IPutContainer): Promise<ContainerFullDetailEntity>
  createProductContainer(params: ICreateProductContainer): Promise<ContainerProductEntity>;

  putPassword(params:IChangePassword):Promise<void>;
  putProfile(params:IPutProfile):Promise<UserEntity>;
  patchCompany(params:IChangeCompany):Promise<CompanyEntity>;
  //
  loginUser(params: ILoginUser): Promise<string>;
  getProfile(): Promise<UserEntity>;
  registerUser(params: IRegisterUser): Promise<UserEntity>;
  deleteProduct(params: { product_id: string });
  // Products
  getProducts(): Promise<ProductEntity[]>;
  createProduct(params: ICreateProduct): Promise<ProductEntity>;
  putProduct(params: ICreateProduct): Promise<ProductEntity>;
  getProductFullDetail(params: { product_id: string }):Promise<FullProductEntity>;
  changeVisibility(params:{product_id:string}):Promise<{id:string,is_visible:boolean}>
  // Proforma
  createProforma(params: ICreateProforma): Promise<ProformaEntity>;
  getProformas(): Promise<ProformaEntity[]>;
  getSuppliers(): Promise<SupplierEntity[]>;

  // Transactions
  createTransaction(params: ICreateTransaction): Promise<TransactionEntity>;
  putTransaction(params: IPutTransaction): Promise<TransactionEntity>;

  // Companies
  getCompanies(): Promise<CompanyEntity[]>;
  getCompanyPayments(company_id: string): Promise<CompanyPaymentsEntity>;
  getPaymentPlan(): Promise<PaymentPlanEntity[]>;

  // Simulation
  postSimulation(params: IPostSimulation): Promise<SimulationEntity>;

  sendClarificationRequest(
    params: IClarificationRequest
  ): Promise<ClarificationRequestEntity>;
  createExternalPayment(
    params: ICreateExternalPayment
  ): Promise<ExternalPaymentEntity>;
  acceptUser(params: { user_id: string }): Promise<void>;

  getMetrics(): Promise<MetricUnitEntity[]>;
  createMetric(params: ICreateMetric): Promise<MetricUnitEntity>;

  createContainerMovement(
    params: ICreateContainerMovement
  ): Promise<ContainerMovementEntity>;
  putContainerMovement(
    params: IPutContainerMovement
  ): Promise<ContainerMovementEntity>;
  getContainerFullDetailPayments(page: number):Promise<{containers: ContainerPaymentsFullDetailEntity[],pages:number}>;

  //Dashboard CFO
  getActiveUsers():Promise<{ActiveUsers:number,total_user_count:number}>;
  getActiveRegions():Promise<{ active_users_percentage: { region: string; percentage: number }[] }>
  getContainerCount():Promise<{in_factory:number,in_route:number}>
  getAccountReceivable():Promise<{total_debt: number,monthly_debt: number}>
  getAccountPayable():Promise<{total_payable:number,monthly_payable:number}>
  getRevenewComparison():Promise<{year:number,monthly_revenue_data:{month:number,expected_revenue:number,actual_revenue:number,difference:number}[]}>
}


export class ApiDataSourceImpl implements ApiDataSource {
  httpClient: HttpClientType;

  constructor(httpClient: HttpClientType) {
    this.httpClient = httpClient;
  }

  getContainers = async (): Promise<ContainerEntity[]> => {
    const response = await this.httpClient.get(
      `${process.env.REACT_APP_API_URL}api/containers/`
    );
    return response.data.map((container) =>
      mapJsonToContainerEntity(container)
    );
  };

  getContainersFullDetail = async (): Promise<ContainerFullDetailEntity[]> => {
    const response = await this.httpClient.get(
      `${process.env.REACT_APP_API_URL}api/containers/full-detail/`
    );

    return response.data.map((container) =>
      mapJsonToContainerFullDetailEntity(container)
    );
  };

  getAllContainers = async (): Promise<ContainerAdminEntity[]> => {
    const response = await this.httpClient.get(
      `${process.env.REACT_APP_API_URL}api/all-containers/`
    );

    return response.data.map((container) =>
      mapJsonToContainerAdminEntity(container)
    );
  };

  getSingleContainerInfo = async (params: IGetContainer): Promise<ContainerAdminInfoEntity> => {
    const response = await this.httpClient.get(
      `${process.env.REACT_APP_API_URL}api/container/${params.id}/`
    );

    return mapJsonToContainerAdminInfoEntity(response.data);
  };

  getContainerClientInfo = async (params: IGetContainer): Promise<ContainerClientInfoEntity> => {
    const response = await this.httpClient.get(
      `${process.env.REACT_APP_API_URL}api/clients-info/${params.id}/`
    );

    return mapJsonToContainerClientInfoEntity(response.data);
  };

  createContainer = async (
    params: ICreateContainer
  ): Promise<ContainerEntity> => {
    const response = await this.httpClient.post(
      `${process.env.REACT_APP_API_URL}api/containers/`,
      {
        "name":params.name,
        "capacity": params.capacity,
        "fixed_cost": params.fixed_cost,
        "tax":params.tax,
        "status": 1,
        "used_capacity":0,
        "payments": params.payments,
        "percentages": params.percentages,
        "due_dates": params.due_dates,
        "deadlines": params.deadlines
      },

    );
    return mapJsonToContainerEntity(response.data);
  };

  putPassword = async (params:IChangePassword):Promise<void> => {
    await this.httpClient.put( `${process.env.REACT_APP_API_URL}api/profile/change_password/`,
      {
        "old_password": params.old_password,
        "new_password": params.new_password,
        "confirm_password": params.confirm_password,
      },
    );
  }

  putProfile = async(params:IPutProfile):Promise<UserEntity>=>{
    const response = await this.httpClient.put( `${process.env.REACT_APP_API_URL}api/profile/update/`,
      {
        "email": params.email,
        "first_name": params.first_name,
        "last_name": params.last_name
      },
    );
    return mapJsonToUserEntity(response.data);
  }

  patchCompany = async (params: IChangeCompany): Promise<CompanyEntity> => {
    const response = await this.httpClient.patch( `${process.env.REACT_APP_API_URL}api/company/update/`,
      {
        "name": params.name,
        "address":params.address,
        "phone": params.phone,
        "email": params.email
      },
    );
    return mapJsonToCompanyEntity(response.data.company);
  }

  putContainer= async (params: IPutContainer): Promise<ContainerFullDetailEntity> => {
    const response = await this.httpClient.put( `${process.env.REACT_APP_API_URL}api/container/${params.id}/`,
      {
        "name":params.name,
        "id": params.id,
        "capacity": params.capacity,
        "fixed_cost": params.fixed_cost,
        "tax":params.tax,
      },
    );
    return mapJsonToContainerFullDetailEntity(response.data);
  }

  createProductContainer = async (params: ICreateProductContainer): Promise<ContainerProductEntity> =>{
    const response = await this.httpClient.post(
      `${process.env.REACT_APP_API_URL}api/container_products/`,
      {
        min_quantity: params.min_quantity,
        max_quantity: params.max_quantity,
        unit_base_price: params.unit_base_price,
        increased_percent: params.increased_percent,
        interval: params.interval,
        units_per_package: params.units_per_package,
        container: params.container,
        product: params.product,
        metric_unit: params.metric_unit,
        unit_base_price_usd: params.unit_base_price_usd,
        unit_purchase_price_usd: params.unit_purchase_price_usd,
        unit_purchase_price:params.unit_purchase_price,
      }
    );
    return mapJsonToContainerProductEntity(response.data);
  };

  getProducts = async (): Promise<ProductEntity[]> => {
    const response = await this.httpClient.get(
      `${process.env.REACT_APP_API_URL}api/products/`
    );
    return response.data.map((product) => mapJsonToProductEntity(product));
  };

  changeVisibility = async (params:{product_id:string}): Promise<{id:string,is_visible:boolean}> => {
    const response = await this.httpClient.post( `${process.env.REACT_APP_API_URL}api/product/toggle-visibility/${params.product_id}/`,{});
    return response.data;
  }
  getProductFullDetail = async (params: { product_id: string }):Promise<FullProductEntity> => {
    const response = await this.httpClient.get( `${process.env.REACT_APP_API_URL}api/product/${params.product_id}/`);
    return mapJsonToFullProductEntity(response.data);
  }

  createProduct = async (params: ICreateProduct): Promise<ProductEntity> => {
    //console.log("Price", params.id);
    const response = await this.httpClient.post(
      `${process.env.REACT_APP_API_URL}api/products/`,
      {
        id: params.id,
        name: params.name,
        price: params.price,
        image_url: params.image_url,
        description: params.description,
        category: params.category,
        weight: params.weight,
        materials: params.materials,
        width: params.width,
        length: params.length,
        depth:params.length,
        supplier: params.supplier
      }
    );
    return mapJsonToProductEntity(response.data);
  };

  putProduct = async (params: ICreateProduct): Promise<ProductEntity> => {
    const response = await this.httpClient.put(
      `${process.env.REACT_APP_API_URL}api/product/${params.id}/`,
      {
        id: params.id,
        name: params.name,
        price: params.price,
        image_url: params.image_url,
        description: params.description,
        category: params.category,
        weight: params.weight,
        materials: params.materials,
        width: params.width,
        length: params.length,
        depth:params.length,
        supplier: params.supplier
      }
    );
    return mapJsonToProductEntity(response.data);
  };

  deleteProduct = async (params: { product_id: string }): Promise<void> => {
    await this.httpClient.delete(
      `${process.env.REACT_APP_API_URL}api/product/${params.product_id}/`
    );
  };

  loginUser = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }): Promise<string> => {
    const response = await this.httpClient.post(
      `${process.env.REACT_APP_API_URL}api/login/`,
      {
        email: email,
        password: password,
      },
      false
    );

    return response.data["token"] as string;
  };

  getProfile = async (): Promise<UserEntity> => {
    const response = await this.httpClient.get(
      `${process.env.REACT_APP_API_URL}api/profile/`
    );
    return mapJsonToUserEntity(response.data);
  };

  registerUser = async (params: IRegisterUser): Promise<UserEntity> => {
    const response = await this.httpClient.post(
      `${process.env.REACT_APP_API_URL}api/register/`,
      params
    );
    return mapJsonToUserEntity(response.data);
  };

  getProformas = async (): Promise<ProformaEntity[]> => {
    const response = await this.httpClient.get(
      `${process.env.REACT_APP_API_URL}api/user/proformas/`
    );
    return response.data.map((proforma) => mapJsonToProformaEntity(proforma));
  };

  getSuppliers = async (): Promise<SupplierEntity[]> => {
    const response = await this.httpClient.get(
      `${process.env.REACT_APP_API_URL}api/suppliers/`
    );
    
    return response.data.map((supplier) => mapJsonToSupplierEntity(supplier));
  };

  getContainerProductsDebts = async(container_id:string): Promise<ContainerProductDeudas> => {
    const response = await this.httpClient.get(
      `${process.env.REACT_APP_API_URL}api/container/debt-detail/${container_id}/`
    );
    return mapJsonToContainerWithProductsEntity(response.data);
  }
  createTransaction = async (
    params: ICreateTransaction
  ): Promise<TransactionEntity> => {
    //console.log(
    //   "ENVIANDO transaction REQUEST. FILE: ",
    //   params.file,
    //   " Payment id:",
    //   params.payment_id,
    //   " params id:",
    //   params.id,
    //   " AMOUNT CLP:",
    //   params.amount,
    //   " AMOUNT USD:",
    //   params.amount_usd,
    //   "."
    // );

    // Create a new FormData instance
    const formData = new FormData();
    formData.append("id", params.id);
    formData.append("file", params.file); // file is added as part of FormData
    formData.append("payment_id", params.payment_id);
    formData.append("amount", params.amount.toString()); // Ensure this is sent as a string
    formData.append("amount_usd", params.amount_usd.toString()); // Ensure this is sent as a string

    // Adjust according to your httpClient setup
    const response = await this.httpClient.post(
      `${process.env.REACT_APP_API_URL}api/transactions/`,
      formData
      // Assuming options is passed correctly in your custom client
    );

    return mapJsonToTransactionEntity(response.data);
  };

  putTransaction = async (params: IPutTransaction): Promise<TransactionEntity> => {
    const formData = new FormData();
    formData.append("id", params.id);
    formData.append("payment", params.payment_id);
    formData.append("status", params.status.toString());
    //console.log("ID: ", params.id, "payment: ", params.payment_id, "status: ", params.status)
    const response = await this.httpClient.put(
      `${process.env.REACT_APP_API_URL}api/transaction/${params.id}/`,
      formData
    );

    return mapJsonToTransactionEntity(response.data);
  }

  createProforma = async (params: ICreateProforma): Promise<ProformaEntity> => {
    //console.log("CREANDO PROFORMA")
    //console.log("ID: ", params.id)
    //console.log("PRODUCT CONTAINER: ", params.product_container_id)
    //console.log("QUANTITY: ", params.quantity)
    //console.log("PRICE: ", params.price)
    //console.log("PRICE USD: ", params.price_usd)
    //console.log("DISPATCH: ", params.dispatch)
    //console.log("ADDRESS: ", params.address)

    const response = await this.httpClient.post(
      `${process.env.REACT_APP_API_URL}api/user/proformas/`,
      {
        id: params.id,
        product_container: params.product_container_id,
        quantity: params.quantity,
        price: params.price,
        price_usd: params.price_usd,
        dispatch: params.dispatch,
        address: params.address,
      }
    );

    return mapJsonToProformaEntity(response.data);
  };

  acceptUser = async (params: { user_id: string }): Promise<void> => {
    await this.httpClient.put(
      `${process.env.REACT_APP_API_URL}api/accept-user/${params.user_id}/`,
      {}
    );
  };

  getCompanies = async (): Promise<CompanyEntity[]> => {
    const response = await this.httpClient.get(
      `${process.env.REACT_APP_API_URL}api/companies/`
    );
    return response.data.map((company) => mapJsonToCompanyEntity(company));
  };

  getCompanyPayments = async (
    company_id: string
  ): Promise<CompanyPaymentsEntity> => {
    const response = await this.httpClient.get(
      `${process.env.REACT_APP_API_URL}api/companies/${company_id}/payments/`
    );

    return mapJsonToCompanyPaymentsEntity(response.data);
  };

  getCompanyInvestments = async (): Promise<InvestmentEntity> => {
    const response = await this.httpClient.get(
      `${process.env.REACT_APP_API_URL}api/companies/investments/`
    );
    
    return mapJsonToInvestmentEntity(response.data);

  };
  
  getPaymentPlan = async (): Promise<PaymentPlanEntity[]> => {
    const response = await this.httpClient.get(
      `${process.env.REACT_APP_API_URL}api/client-payment-status/`
    );
    return response.data.map((company) => mapJsonToPaymentPlanEntity(company));
  };

  sendClarificationRequest = async (
    params: IClarificationRequest
  ): Promise<ClarificationRequestEntity> => {
    //console.log("ENVIANDO CLARIFICATION REQUEST");
    //console.log(
    //   "id: ",
    //   params.id,
    //   " proforma id: ",
    //   params.proformaId,
    //   " Client name: ",
    //   params.clientName,
    //   " message: ",
    //   params.message,
    //   " email: ",
    //   params.email,
    //   "transaction: ",
    //   params.selectedTransaction
    // );
    const response = await this.httpClient.post(
      `${process.env.REACT_APP_API_URL}api/clarifications/`, // Asegúrate de que el endpoint sea correcto
      {
        //id: params.id,
        proforma_id: params.proformaId,
        client_name: params.clientName,
        message: params.message,
        client_email: params.email,
        selectedTransaction: params.selectedTransaction,
      }
    );

    // Mapea la respuesta al tipo ClarificationRequestEntity
    return mapJsonToClarificationRequestEntity(response.data);
  };

  getMetrics = async (): Promise<MetricUnitEntity[]> => {
    const response = await this.httpClient.get(
      `${process.env.REACT_APP_API_URL}api/metric_units/`
    );
    return response.data.map((metric) => mapJsonToMetricUnit(metric));
  };

  createMetric = async (params: ICreateMetric): Promise<MetricUnitEntity> => {
    const response = await this.httpClient.post(
      `${process.env.REACT_APP_API_URL}api/metric_units/`,
      {
        name: params.name,
        description: params.description,
        square_meters: params.square_meters,
      }
    );
    return mapJsonToMetricUnit(response.data);
  };
  createExternalPayment = async (params: ICreateExternalPayment): Promise<ExternalPaymentEntity> => {
    //console.log(params);
    const response = await this.httpClient.post(
      `${process.env.REACT_APP_API_URL}api/external_payments/`,
      {
        id: params.id,
        supplier: params.supplierId,
        container: params.containerId,
        amount: Number(params.amount),
        amount_usd: params. amount_usd,
        description: params.description,
      }
    );
    return mapJsonToExternalPaymentEntity(response.data);
  };
  createContainerMovement = async (
    params: ICreateContainerMovement
  ): Promise<ContainerMovementEntity> => {
    //console.log("CREANDO CONT_MOVEMENT, STEP: ", params.step, " STATUS: ", params.status)
    const response = await this.httpClient.post(
      `${process.env.REACT_APP_API_URL}api/container_movement/`,
      {
        estimated_date: params.estimated_date,
        real_date: params.real_date,
        step: params.step,
        container: params.container,

        status:params.status

      }
    );
    return mapJsonToContainerMovementEntity(response.data);
  };

  putContainerMovement = async (
    params: IPutContainerMovement
  ): Promise<ContainerMovementEntity> => {
    //console.log("PUT A CONT_MOVEMENT, STEP: ", params.step, " STATUS: ", params.status)
    const response = await this.httpClient.put(
      `${process.env.REACT_APP_API_URL}api/container_movement/${params.id}/`,
      {
        estimated_date: params.estimated_date,
        real_date: params.real_date,
        step: params.step,
        container: params.container,
        status: params.status,
      }
    );
    return mapJsonToContainerMovementEntity(response.data);
  };

  getContainerFullDetailPayments = async(page: number):Promise<{containers: ContainerPaymentsFullDetailEntity[],pages:number}> =>{
    const response = await this.httpClient.get(
      `${process.env.REACT_APP_API_URL}api/containers/payment-detail/?page=${page}`
    );
    const data ={containers: response.data.results.map((container) => mappJsonToContainerPaymentsFullDetail(container)), pages:response.data.total_pages
    }
    return data;
  };

  postSimulation = async (
    params: IPostSimulation
  ): Promise<SimulationEntity> => {
    const response = await this.httpClient.post(
      `${process.env.REACT_APP_API_URL}api/proformas/simulator/`,
      {
        precio_venta: params.precio_venta,
        precio_compra: params.precio_compra,
        cantidad: params.cantidad,
        product_id: params.product_id,
      }
    );
    return mapJsonToSimulationEntity(response.data);
  };


  getActiveUsers = async():Promise<{ActiveUsers:number,total_user_count:number}> => {
    const response = await this.httpClient.get(
      `${process.env.REACT_APP_API_URL}api/active-users/`
    );
    const data = {ActiveUsers:response.data.active_users_count,total_user_count:response.data.total_user_count}
    return data;
  }

  getActiveRegions = async():Promise<{ active_users_percentage: { region: string; percentage: number }[] }> =>{
    const response = await this.httpClient.get(
      `${process.env.REACT_APP_API_URL}api/active-regions/`
    );
    const data = {
      active_users_percentage: Object.entries(response.data.active_users_percentage).map(
        ([region, percentage]) => ({
          region,
          percentage: Number(percentage),
        })
      ),
    };
  
    return data;
  }

  getContainerCount = async():Promise<{in_factory:number,in_route:number}> => {
    const response = await this.httpClient.get(
      `${process.env.REACT_APP_API_URL}api/container-count/`
    );
    const data = {in_factory:response.data.in_factory,in_route:response.data.on_route }
    return data;
  }

  getAccountReceivable = async():Promise<{total_debt: number,monthly_debt: number}> => {
    const response = await this.httpClient.get(
      `${process.env.REACT_APP_API_URL}api/accounts-receivable/`
    );
    const data = {total_debt:response.data.total_debt,monthly_debt:response.data.monthly_debt }
    return data;
  }
  
  getAccountPayable = async():Promise<{total_payable:number,monthly_payable:number}> => {
    const response = await this.httpClient.get(
      `${process.env.REACT_APP_API_URL}api/accounts-payable/`
    );
    const data = {total_payable:response.data.total_payable,monthly_payable:response.data.monthly_payable }
    return data;
  }

  getRevenewComparison = async():Promise<{year:number,monthly_revenue_data:{month:number,expected_revenue:number,actual_revenue:number,difference:number}[]}> => {
    const response = await this.httpClient.get(
      `${process.env.REACT_APP_API_URL}api/revenew-comparison/`
    );
    const data = {
      year: response.data.year,
      monthly_revenue_data: response.data.monthly_revenue_data.map(
        (item: {
          month: number;
          expected_revenue: number;
          actual_revenue: number;
          difference: number;
        }) => ({
          month: item.month,
          expected_revenue: Number(item.expected_revenue),
          actual_revenue: Number(item.actual_revenue),
          difference: Number(item.difference),
        })
      ),
    };
    return data;
  }

  // re_path(r'^active-users/$', ActiveUsersCountView.as_view(), name='active_user_view'),
  //   re_path(r'^active-regions/$', ActiveUsersRegionView.as_view(), name="active_region_view"),
  //   re_path(r'^container-count/$', ContainerStatusCountView.as_view(), name='count_status_view'),
  //   re_path(r'^accounts-receivable/$', AccountsReceivableView.as_view(), name='accounts_receivable_view'),
  //   re_path(r'^accounts-payable/$', AccountsPayableView.as_view(), name="accounts_payable_view"),
  //   re_path(r'^revenew-comparison/$', RevenueComparisonView.as_view(), name="revenew_comparison_view"),

}
