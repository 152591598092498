import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
} from '@mui/material';

const formatAmount = (amount, currency = "CLP") => {
  const numericAmount = Number(amount);

  if (isNaN(numericAmount)) return amount;

  if (currency === "CLP") {
    // Formato para CLP: punto entre miles, coma para decimales
    return numericAmount.toLocaleString("es-CL", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  } else if (currency === "USD") {
    // Formato para USD: punto decimal, dos decimales
    return numericAmount.toLocaleString("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  }

  return amount; // Retorno sin formato si no se especifica una moneda válida
};

const getPaymentStatus = (payments) => {
  const now = new Date();
  let isPaid = true;
  let isLate = false;

  payments.forEach((payment) => {
    const reformattedDeadline = payment.deadline?.replace(" a las ", "T");
    const deadlineDate = reformattedDeadline ? new Date(reformattedDeadline) : null;
    const totalPaid = payment.transactions.reduce(
      (sum, transaction) => (transaction.status === 1 ? sum + transaction.amount_usd : sum),
      0
    );
    if (totalPaid < payment.amount_usd) {
      isPaid = false;
      if (deadlineDate && deadlineDate < now) {
        isLate = true;
      }
    }
  });

  if (isPaid) {
    return { text: "Pago completado", color: "green" };
  } else if (isLate) {
    return { text: "Pago atrasado", color: "red" };
  } else {
    return { text: "Pago en progreso", color: "orange" };
  }
};

interface ProformasTableProps {
  proformas: {
    product_name: string;
    quantity: number;
    total_amount: number;
    amount_paid: number;
    amount_usd: number;
    amount_paid_usd: number;
    debt: number;
    payments: any[];
    proforma_deadline: string;
  }[];
  emptyMessage: string;
}

const ProformasTable: React.FC<ProformasTableProps> = ({ proformas, emptyMessage }) => {
  return (
    <TableContainer component={Paper}>
      {proformas.length === 0 ? (
        <Box sx={{ padding: '20px', textAlign: 'center' }}>
          <Typography variant="body1">{emptyMessage}</Typography>
        </Box>
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Producto</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Cantidad</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Total</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Pagado</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Deuda</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Fecha Límite</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Estado del Pago</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {proformas.map((proforma, index) => {
              const { text: paymentStatus, color: statusColor } = getPaymentStatus(proforma.payments);
              return (
              <TableRow
                key={index}
              >
                <TableCell>{proforma.product_name}</TableCell>
                <TableCell>{proforma.quantity}</TableCell>
                <TableCell>
                  <Box>
                    <Typography display="block">${formatAmount(proforma.total_amount, "CLP")} CLP /</Typography>
                    <Typography display="block">${formatAmount(proforma.amount_usd, "USD")} USD</Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box>
                    <Typography display="block">${formatAmount(proforma.amount_paid, "CLP")} CLP /</Typography>
                    <Typography display="block">${formatAmount(proforma.amount_paid_usd, "USD")} USD</Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box>
                    <Typography display="block">${formatAmount(proforma.debt, "CLP")} CLP /</Typography>
                    <Typography display="block">${formatAmount(proforma.amount_usd - proforma.amount_paid_usd, "USD")} USD</Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  {new Date(proforma.proforma_deadline).toLocaleString('es-CL', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                  })}
                </TableCell>
                <TableCell>
                  <Typography sx={{ color: statusColor, fontWeight: 'bold' }}>
                    {paymentStatus}
                  </Typography>
                </TableCell>
              </TableRow>
              );
            })}
            </TableBody>
        </Table>
      )}
    </TableContainer>
  );
};

export default ProformasTable;
