import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TransactionDetails from './TransactionDetails'; 
import { PaymentPlanPaymentEntity } from '../../../../../domain/entities';

interface PaymentDetailsProps {
  payment: PaymentPlanPaymentEntity;
  ammount: number;
  amount_usd: number;
}

const PaymentDetails: React.FC<PaymentDetailsProps> = ({ payment, ammount, amount_usd}) => {
  const totalPayment = payment.percentage * ammount;
  const totalPaymentUsd = payment.percentage * amount_usd;
  const totalPaid = payment.transactions.reduce((sum, transaction) => sum + transaction.amount, 0);
  const totalPaidUsd = payment.transactions.reduce((sum, transaction) => sum + transaction.amount_usd, 0);
  return (
    <Accordion sx={{  
      border: "2px solid #ccc" 
    }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel-${payment.id}-content`}
        id={`panel-${payment.id}-header`}
        sx={{
          "&:hover": {
            backgroundColor: "#f5f5f5",
            cursor: "pointer",
          },
        }}
      >
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography fontWeight="bold">{`ID Pago: ${payment.id}`}</Typography>
          </Grid>
          <Grid item>
          <Typography fontWeight="bold">
            Porcentaje de Pago: {payment.percentage * 100}%
          </Typography>
          </Grid>
          <Grid item>
            <Typography fontWeight="bold">{`Fecha de Vencimiento: ${payment.due_date}`}</Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2} sx={{ alignItems: 'center', marginLeft: '-6px' }}>
          <Grid item xs={6}>
            <Grid container direction="column" spacing={1}>
              <Grid container spacing={2}>
                {/* Row 1 */}
                <Grid item xs={6}>
                  <Typography fontWeight="bold">{`Total Pago (CLP): `}</Typography>
                  <Typography>
                    ${totalPayment.toLocaleString("es-ES")}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography fontWeight="bold">{`Total Pago (USD): `}</Typography>
                  <Typography>
                    ${totalPaymentUsd.toLocaleString("en-US")}
                  </Typography>
                </Grid>

                {/* Row 2 */}
                <Grid item xs={6}>
                  <Typography fontWeight="bold">{`Total Pagado (CLP): `}</Typography>
                  <Typography>
                    ${totalPaid.toLocaleString("es-ES")}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography fontWeight="bold">{`Total Pagado (USD): `}</Typography>
                  <Typography>
                    ${totalPaidUsd.toLocaleString("en-US")}
                  </Typography>
                </Grid>

                {/* Row 3 */}
                <Grid item xs={6}>
                  <Typography fontWeight="bold">{`Porcentaje Pagado: `}</Typography>
                  <Typography>
                    {(totalPaid * 100 / totalPayment).toLocaleString("es-ES")}% 
                  </Typography>
                </Grid>
              </Grid>
              <Grid item sx={{ alignItems: 'center', marginLeft: '-6px' }}>
                <Typography fontWeight="bold">{`Cantidad de Transacciones Realizadas: `}</Typography>
                <Typography>{payment.transactions.length}</Typography>
              </Grid>
              <Grid item sx={{ alignItems: 'center', marginLeft: '-6px' }}>
                <Typography fontWeight="bold">{`Fecha Límite: `}</Typography>
                <Typography>{payment.deadline}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <TransactionDetails transactions={payment.transactions} />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default PaymentDetails;
