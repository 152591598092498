// ProvidersCFO.tsx
import React, { useEffect, useState } from "react";
import { Container, Box, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ProviderCard from "./provider_card";
import ProviderTable from "./provider_table";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { GetSuppliersEvent } from "../../../events/cfo/get_suppliers_event";

const ProvidersCFO: React.FC = () => {
  const dispatch = useAppDispatch();
  const { suppliers } = useAppSelector((state) => state.suppliers);
  const [selectedSupplier, setSelectedSupplier] = useState(null);

  useEffect(() => {
    dispatch(GetSuppliersEvent());
  }, [dispatch]);

  const handleSelectSupplier = (supplier) => {
    setSelectedSupplier(supplier);
  };

  const handleBackToTable = () => {
    setSelectedSupplier(null); // Restablece el estado para mostrar la tabla
  };

  return (
    <Box>
      {!selectedSupplier ? (
        <ProviderTable
          suppliers={suppliers}
          onSelectSupplier={handleSelectSupplier}
        />
      ) : (
        <Container
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <IconButton
            onClick={handleBackToTable}
            sx={{
              position: "absolute",
              top: 6,
              left: 40,
              marginRight: 10,
              opacity: 0.7, // Ajuste de opacidad para hacerla más translúcida
            }}
          >
            <ArrowBackIcon />
          </IconButton>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              //marginTop: 4, // Ajusta el margen superior para evitar solapamiento
            }}
          >
            <ProviderCard supplier={selectedSupplier} />
          </Box>
        </Container>
      )}
    </Box>
  );
};

export default ProvidersCFO;
