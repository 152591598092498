import React, { useState } from "react";
import { Grid, Typography, Accordion, AccordionSummary, AccordionDetails, TablePagination } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { PaymentPlanContainerEntity } from "../../../../../domain/entities";
import PaymentDetails from "./PaymentDetails";

interface ContainerDetailsProps {
  container: PaymentPlanContainerEntity;
}

const ContainerDetails: React.FC<ContainerDetailsProps> = ({ container }) => {
  const [showTables, setShowTables] = useState(false);
  const [page, setPage] = useState(0); 
  const itemsPerPage = 2;   

  const fullyPaidCount = container.proformas.filter(
    (proforma) => !proforma.has_debt
  ).length;
  
  const allFullyPaid = fullyPaidCount === container.proformas.length;

  const sortedProformas = [...container.proformas].sort(
    (a, b) =>  a.payment_progress - b.payment_progress
  );
  
  const totalPages = Math.ceil(sortedProformas.length / itemsPerPage);

  const handleToggle = () => {
    setShowTables((prev) => !prev);
    //console.log(showTables);
  };

  const paginatedProformas = sortedProformas.slice(
    page * itemsPerPage,
    (page + 1) * itemsPerPage
  );

  const handlePageChange = (_: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
  };

  return (
    <>
      <Accordion key={container.id} sx={{ width: "100%", backgroundColor: "#fafafa", border: "2px solid #ccc" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel-${container.id}-content`}
          id={`panel-${container.id}-header`}
          onClick={handleToggle}
          sx={{
            "&:hover": {
              backgroundColor: "#e0e0e0",
            },
          }}
        >
          <Grid container alignItems="center">
            <Grid item xs={6}>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "bold",
                  color: allFullyPaid ? "green" : "inherit",
                }}
              >
                Contenedor: {container.name}
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: "right" }}>
              <Typography
                variant="body2"
                sx={{
                  color: allFullyPaid ? "green" : "inherit",
                  fontWeight: "bold",
                }}
              >
                Proformas completas: {fullyPaidCount}/{container.proformas.length}
              </Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1}>
            {paginatedProformas.map((proforma) => (
              <Grid item xs={12} key={proforma.id}>
                <Accordion sx={{ width: "100%", 
                border: "2px solid #ccc", 
                "&:hover": {
                  backgroundColor: "white",
                }, 
                backgroundColor: "#f5f5f5",
                "&.Mui-expanded": {
                  backgroundColor: "white", 
                },
              }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel-proforma-${proforma.id}-content`}
                    id={`panel-proforma-${proforma.id}-header`}
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <Typography variant="body1">
                          <strong>ID Proforma:</strong> {proforma.id}
                        </Typography>
                        <Typography
                          variant="body1"
                        >
                          <strong>Cantidad total (CLP):</strong> ${proforma.total_amount.toLocaleString('es-ES')}
                        </Typography>
                        <Typography
                          variant="body1"
                        >
                          <strong>Cantidad total (USD):</strong> ${proforma.total_amount_usd.toLocaleString('en-US')}
                        </Typography>
                        <Typography variant="body1">
                          <strong>Cantidad Pagada (CLP):</strong> ${proforma.amount_paid.toLocaleString("es-ES")}
                        </Typography>
                        <Typography variant="body1">
                          <strong>Cantidad Pagada (USD):</strong> ${proforma.amount_paid_usd.toLocaleString("en-US")}
                        </Typography>
                        <Typography
                          variant="body1"
                          sx={{
                            color: proforma.has_debt ? "red" : "inherit",
                          }}
                        >
                          <strong>Progreso de Pago:</strong> {proforma.payment_progress.toFixed(2)}%
                        </Typography>
                        <Typography
                          variant="body1"
                          sx={{
                            color: proforma.has_debt ? "red" : "inherit",
                          }}
                        >
                          <strong>Deuda (CLP):</strong> ${proforma.debt.toLocaleString("es-ES")}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sx={{ textAlign: "right" }}>
                        <Typography variant="body1">Detalles de transacciones</Typography>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    {proforma.payments.map((payment) => (
                      <PaymentDetails payment={payment} key={payment.id} ammount={proforma.total_amount} amount_usd={proforma.total_amount_usd}/>
                    ))}
                  </AccordionDetails>
                </Accordion>
              </Grid>
            ))}
          </Grid>
          <TablePagination
            component="div"
            count={sortedProformas.length}
            page={page}
            onPageChange={handlePageChange}
            rowsPerPage={itemsPerPage}
            rowsPerPageOptions={[]}
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
            labelRowsPerPage="Filas por página"
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default ContainerDetails;
