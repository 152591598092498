import React, { useEffect } from "react";
import {
  Card,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { resetSimulation } from "../../redux/slices/simulator_slice";
import { RootState } from "../../redux";
import ProductInfo from "../../components/simulation/product_info";
import SimulationTable from "../../components/simulation/simulation_table";

const SimulationPage: React.FC = () => {
  const { state } = useLocation();
  const { pricingData, container_product, product, product_id } = state;
  const usdToClp = useAppSelector(
    (state: RootState) => state.exchange_rate.usdToClp
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(resetSimulation());
  }, [dispatch]);

  return (
    <Container sx={{ padding: "20px" }}>
      <Typography
        variant="h4"
        sx={{
          fontWeight: "600",
          marginBottom: "30px",
        }}
      >
        Simulador de Utilidad
      </Typography>
      <Card
        sx={{
          padding: "30px",
          borderRadius: "50px",
          borderColor: "#0A66A5",
          borderWidth: "1px",
          borderStyle: "solid",
        }}
      >
        <Grid container direction="column" rowSpacing={5}>
          {/* Product Info */}
          <Grid item>
            <ProductInfo
              pricingData={pricingData}
              container_product={container_product}
              product={product}
            />
          </Grid>

          {/* Simulation Table */}
          <Grid item sx={{ marginTop: 5 }}>
            <SimulationTable
              pricingData={pricingData}
              product_id={product_id}
            />
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
};

export default SimulationPage;
