import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Box,
  LinearProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { useParams } from "react-router-dom";

import { GetProformasEvent } from "../../events/proforma/get_proformas_event";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { ProformaEntity } from "../../../domain/entities";
import PaymentCard from "../../components/user_history/payment_card";
import ClarificationRequest from "../../components/user_history/clarification_request";
import MapView from "../../components/user_history/map_view";
import { RootState } from "../../redux";
const formatAmount = (amount, currency = "CLP") => {
  const numericAmount = Number(amount);

  if (isNaN(numericAmount)) return amount;

  if (currency === "CLP") {
    // Formato para CLP: punto entre miles, coma para decimales
    return numericAmount.toLocaleString("es-CL", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  } else if (currency === "USD") {
    // Formato para USD: punto decimal, dos decimales
    return numericAmount.toLocaleString("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  }

  return amount; // Retorno sin formato si no se especifica una moneda válida
};
const EstadoDeCuenta: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const { proformas }: { proformas: ProformaEntity[] } = useAppSelector(
    (state) => state.proformas
  );
  const { usdToClp, loading, error } = useAppSelector(
    (state: RootState) => state.exchange_rate
  );
  //console.log(usdToClp);
  const [openMap, setOpenMap] = useState(false);

  useEffect(() => {
    dispatch(GetProformasEvent());
  }, []);

  const proformasFiltradas = proformas.filter(
    (proforma) => proforma.id === id && proforma.payments
  );

  const totalAmountPaid = proformasFiltradas[0]?.payments.reduce(
    (sumPayments, payment) => {
      const totalTransactionsAmount = payment.transactions.reduce(
        (sumTransactions, transaction) => {
          return sumTransactions + transaction.amount_usd;
        },
        0
      );
      return sumPayments + totalTransactionsAmount;
    },
    0
  );

  const handleOpenMap = () => {
    setOpenMap(true);
  };

  const handleCloseMap = () => {
    setOpenMap(false);
  };

  return (
    <Container>
      <Typography
        variant="h4"
        component="h1"
        gutterBottom
        sx={{ mt: 4, fontWeight: "bold", color: "#0A66A5" }}
      >
        Estado de cuenta
      </Typography>

      {proformasFiltradas.length === 0 ? (
        <Typography variant="body1">No se encontraron proformas.</Typography>
      ) : (
        proformasFiltradas.map((proforma) => (
          <Box
            key={proforma.id}
            sx={{ display: "flex", flexDirection: "row", mb: 2 }}
          >
            <Box
              sx={{
                flex: 1,
                backgroundColor: "#f5f5f5",
                padding: 2,
                borderRadius: "16px",
                height: "700px",
              }}
            >
              <img
                src={proforma.productImageUrl}
                alt="Producto"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  borderRadius: "16px",
                }}
              />
              <Typography
                variant="h6"
                component="div"
                sx={{ color: "#0A66A5", mt: 1, fontWeight: "bold" }}
              >
                {proforma.productName}
              </Typography>
              <Typography
                variant="body2"
                component="div"
                sx={{ color: "#0D74C0", mb: 2 }}
              >
                {`${proforma.productSize || "40 x 40 "} centimetros`} |{" "}
                {`${proforma.productWeight || "40"} gramos`}
                <br />
                {proforma.productMaterials || "85% polyester | 15% Polímero"}
              </Typography>

              <Box sx={{ mt: 2 }}>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{ fontWeight: "bold", color: "#0A66A5" }}
                >
                  Total transferido
                </Typography>
                <Typography
                  variant="h4"
                  component="div"
                  sx={{ color: "#0D74C0" }}
                >
                  ${formatAmount(totalAmountPaid, "USD")}{" "}
                  <Typography
                    variant="h4"
                    component="span"
                    sx={{ color: "text.secondary" }}
                  >
                    / ${formatAmount(proforma.amount_usd, "USD")}{" "}
                  </Typography>
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={(totalAmountPaid / proforma.amount_usd) * 100}
                  sx={{ mt: 1 }}
                />
                <Typography
                  variant="body1"
                  color="text.secondary"
                  sx={{ mt: 1, textAlign: "center" }}
                >
                  {((totalAmountPaid / proforma.amount_usd) * 100).toFixed(0)}%
                  abonado
                </Typography>
              </Box>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  mt: 2,
                  display:
                    proforma.current_step === "En transito maritimo"
                      ? "block"
                      : "none",
                  mx: "auto", // Margen automático para centrar horizontalmente
                }}
                onClick={handleOpenMap}
              >
                Ver mapa
              </Button>
              <Box
  sx={{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    //height: "100%", // Ajusta según el contenedor padre
    marginTop: "10px"
  }}
>
  <ClarificationRequest proforma={proforma} />
</Box>
            </Box>
            <Box sx={{ flex: 2, pl: 15 }}>
              {proforma.payments &&
                proforma.payments.map((payment) => (
                  <PaymentCard key={payment.id} payment={payment} />
                ))}
              
            </Box>

            {/* Pop-up del mapa */}
            <Dialog
              open={openMap}
              onClose={handleCloseMap}
              maxWidth="md"
              fullWidth
            >
              <DialogTitle>Mapa de ubicación</DialogTitle>
              <DialogContent>
                <MapView
                  latitude={proforma.container_coordinates[0]}
                  longitude={proforma.container_coordinates[1]}
                />
              </DialogContent>
            </Dialog>
          </Box>
        ))
      )}
    </Container>
  );
};

export default EstadoDeCuenta;
