import React from "react";
import { Box } from "@mui/material";

interface MapViewProps {
  latitude: number;
  longitude: number;
}

const MapView: React.FC<MapViewProps> = ({ latitude, longitude }) => {
  const zoomLevel = 5; // Menor nivel de zoom
  const mapUrl = `https://www.google.com/maps/embed/v1/place?key=AIzaSyAGsYlj5HSS_Zmp4vcxg5daPSEeEObXTpw&q=${latitude},${longitude}&zoom=${zoomLevel}`;

  return (
    <Box sx={{ mt: 2, height: "400px", width: "100%" }}>
      <iframe
        src={mapUrl}
        width="100%"
        height="100%"
        style={{ border: 0 }}
        allowFullScreen
        loading="lazy"
        title="Google Maps"
      ></iframe>
    </Box>
  );
};

export default MapView;
