import apiRepository from "../../../data/repository";
import ChangeVisibilityUseCase from "../../../domain/usecases/changeVisibility_usecase";
import {changeVisibility} from '../../redux/slices/product_slice';
import { setStatus, StatusLayoutEnum } from "../../redux/slices/layout_slice";

export const ChangeVisibilityEvent = async (
    product_id: string,
    dispatch,
): Promise<{id:string,is_visible:boolean}> => {
    dispatch(setStatus(StatusLayoutEnum.Loading));
    const usecase = new ChangeVisibilityUseCase(apiRepository);
    const result = await usecase.execute({
        product_id
    });

    if (result.isLeft()) {
        const error = result.left;
        if (error) {
            throw new Error(error.message);
        } else {
            throw new Error("Error desconocido");
        }
    }

    const value = result.right;
    if (!value) {
        throw new Error("No se pudo crear la métrica");
    }
    dispatch(changeVisibility(value));
    dispatch(setStatus(StatusLayoutEnum.Success));;
    return value;
}