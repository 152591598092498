import React from "react";
import { Typography, Grid, Paper } from "@mui/material";

interface CompanyDetailsProps {
  name: string;
  rut: string;
  address: string;
  phone: string;
  email: string;
  owner: string;
}

const CompanyDetails: React.FC<CompanyDetailsProps> = ({
  name,
  rut,
  address,
  phone,
  email,
  owner,
}) => {
  return (
    <Paper
      elevation={3}
      sx={{
        padding: "30px",
        marginBottom: "40px",
        width: "100%",
        maxWidth: "1100px",
      }}
    >
      <Typography
        variant="h4"
        sx={{
          fontWeight: "bold",
          marginBottom: "20px",
          textAlign: "center",
          color: "#0A66A5",
        }}
      >
        {name}
      </Typography>
      <Grid container spacing={2} sx={{ maxWidth: "1000px", margin: "auto" }}>
        {/* Columna 1 */}
        <Grid item xs={12} sm={4}>
          <Typography
            variant="body1"
            sx={{ fontWeight: "bold", marginTop: "10px" }}
          >
            RUT:
          </Typography>
          <Typography variant="body2">{rut}</Typography>
          <Typography
            variant="body1"
            sx={{ fontWeight: "bold", marginTop: "10px" }}
          >
            Dueño:
          </Typography>
          <Typography variant="body2">{owner}</Typography>
        </Grid>

        {/* Columna 2 */}
        <Grid item xs={12} sm={4}>
          <Typography
            variant="body1"
            sx={{ fontWeight: "bold", marginTop: "10px" }}
          >
            Dirección:
          </Typography>
          <Typography variant="body2">{address}</Typography>
          <Typography
            variant="body1"
            sx={{ fontWeight: "bold", marginTop: "10px" }}
          >
            Email:
          </Typography>
          <Typography variant="body2">{email}</Typography>
        </Grid>

        {/* Columna 3 */}
        <Grid item xs={12} sm={4}>
          <Typography
            variant="body1"
            sx={{ fontWeight: "bold", marginTop: "10px" }}
          >
            Teléfono:
          </Typography>
          <Typography variant="body2">{phone}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CompanyDetails;
