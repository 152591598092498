import React, { useState, useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import ProviderInfo from "./provider_info";
import ContainerList from "./container_list";
import ContainerProductsModal from "./container_products_modal";
import PaymentModal from "./payment_modal";
import {
  SupplierContainerEntity,
  SupplierEntity,
} from "../../../../domain/entities";
import { CreateExternalPaymentEvent } from "../../../events/cfo/create_external_payment_event";
import {
  updateSupplierDebt,
  updateSupplierDebtUsd,
} from "../../../redux/slices/supplier_slice";
import { useAppDispatch } from "../../../hooks/hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux";

interface ProviderCardProps {
  supplier: SupplierEntity;
}

const ProviderCard: React.FC<ProviderCardProps> = ({ supplier }) => {
  const [selectedContainer, setSelectedContainer] =
    useState<SupplierContainerEntity>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openPaymentDialog, setOpenPaymentDialog] = useState(false);
  const [paymentAmount, setPaymentAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [totalDebt, setTotalDebt] = useState<number>(0); // Estado para la deuda acumulada
  const [totalDebt_usd, setTotalDebt_usd] = useState<number>(0); // Estado para la deuda acumulada
  const dispatch = useAppDispatch();

  // Calcular la cantidad total de contenedores y productos
  const totalContainers = supplier.containers?.length || 0;
  const totalProducts =
    supplier.containers?.reduce(
      (sum, container) => sum + (container.containerProducts?.length || 0),
      0
    ) || 0;

  useEffect(() => {
    // Inicializar deuda total de todos los contenedores
    const initialDebt =
      supplier.containers?.reduce(
        (sum, container) => sum + (container.debt || 0),
        0
      ) || 0;
    setTotalDebt(initialDebt);
  }, [supplier.containers]);

  useEffect(() => {
    // Inicializar deuda total de todos los contenedores
    const initialDebt_usd =
      supplier.containers?.reduce(
        (sum, container) => sum + (container.debt_usd || 0),
        0
      ) || 0;
    setTotalDebt_usd(initialDebt_usd);
  }, [supplier.containers]);

  const handleOpenDialog = (container: SupplierContainerEntity) => {
    setSelectedContainer(container);
    setOpenDialog(true);
  };
  const formatAmount = (amount, currency = "CLP") => {
    const numericAmount = Number(amount);

    if (isNaN(numericAmount)) return amount;

    if (currency === "CLP") {
      // Formato para CLP: punto entre miles, coma para decimales
      return numericAmount.toLocaleString("es-CL", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    } else if (currency === "USD") {
      // Formato para USD: punto decimal, dos decimales
      return numericAmount.toLocaleString("en-US", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    }

    return amount; // Retorno sin formato si no se especifica una moneda válida
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedContainer(null);
  };

  const handleOpenPaymentDialog = () => {
    setOpenPaymentDialog(true);
  };

  const handleClosePaymentDialog = () => {
    setOpenPaymentDialog(false);
    setPaymentAmount("");
  };

  const selectedContainerUpdated = useSelector((state: RootState) =>
    state.suppliers.suppliers
      .find((sup) => sup.id === supplier.id)
      ?.containers.find((container) => container.id === selectedContainer?.id)
  );

  const handlePayment = async (amountClp: number, amountUsd: number) => {
    if (!amountClp || !amountUsd || !selectedContainer || !supplier) {
      setError("Por favor ingrese un monto válido y seleccione un contenedor.");
      return;
    }

    //console.log("MONTO EN USD: ", amountUsd);
    //console.log("MONTO EN CLP: ", amountClp);

    setLoading(true);
    setError(null);
    setSuccessMessage(null);

    try {
      const result = await CreateExternalPaymentEvent(
        supplier.id,
        selectedContainer.id,
        amountClp,
        amountUsd,
        `Pago de ${amountClp} CLP (${amountUsd.toFixed(
          2
        )} USD) para el contenedor ${selectedContainer.id}`
      );

      dispatch(
        updateSupplierDebt({
          supplierId: supplier.id,
          containerId: selectedContainer.id,
          amount: amountClp,
        })
      );
      dispatch(
        updateSupplierDebtUsd({
          supplierId: supplier.id,
          containerId: selectedContainer.id,
          amountUsd: amountUsd,
        })
      );

      setTotalDebt((prevDebt) => prevDebt - amountClp);
      setTotalDebt_usd((prevDebt_usd) => prevDebt_usd - amountUsd);

      setSuccessMessage(
        `Pago realizado con éxito: ${result.amount} CLP (${amountUsd.toFixed(
          2
        )} USD)`
      );
      handleClosePaymentDialog();
    } catch (err) {
      setError(err.message || "Ocurrió un error durante el proceso de pago.");
    } finally {
      setLoading(false);
    }
  };

  <PaymentModal
    container={selectedContainer}
    paymentAmount={paymentAmount}
    setPaymentAmount={setPaymentAmount}
    open={openPaymentDialog}
    onClose={handleClosePaymentDialog}
    onConfirmPayment={handlePayment} // Se pasa la función ajustada
  />;

  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{
        width: "100%",
        border: "1px solid #ccc",
        padding: "10px",
        borderRadius: "4px",
        marginBottom: "25px",
        overflow: "hidden",
      }}
    >
      <Grid container spacing={1} style={{ width: "100%" }}>
        {/* Nombre del proveedor centrado */}
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h6"
            gutterBottom
            style={{ fontWeight: "bold", color: "#0A66A5" }}
          >
            {supplier.name || "Nombre Proveedor"}
          </Typography>
        </Grid>

        {/* ProviderInfo: Ocupa la mitad del ancho */}
        <Grid container spacing={8} style={{ width: "100%" }}>
          {/* ProviderInfo: Ocupa la mitad del ancho */}
          <Grid
            item
            xs={6}
            style={{
              marginTop: "20px",
            }}
          >
            <ProviderInfo supplier={supplier} />
          </Grid>

          {/* ContainerList: Ocupa la otra mitad del ancho */}
          <Grid
            item
            xs={6}
            style={{
              marginTop: "20px",
            }}
          >
            <ContainerList
              containers={supplier.containers}
              onSelectContainer={handleOpenDialog}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" align="center" sx={{ marginTop: "15px" }}>
            Deuda Acumulada: ${formatAmount(totalDebt, "CLP")} CLP - $
            {formatAmount(totalDebt_usd, "USD")} USD
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body1" align="center" sx={{ marginTop: "10px" }}>
            Total de Contenedores: {totalContainers}
          </Typography>
          <Typography variant="body1" align="center">
            Total de Productos: {totalProducts}
          </Typography>
        </Grid>

        <ContainerProductsModal
          container={selectedContainerUpdated}
          open={openDialog}
          onClose={handleCloseDialog}
          onOpenPaymentDialog={handleOpenPaymentDialog}
        />

        <PaymentModal
          container={selectedContainer}
          paymentAmount={paymentAmount}
          setPaymentAmount={setPaymentAmount}
          open={openPaymentDialog}
          onClose={handleClosePaymentDialog}
          onConfirmPayment={handlePayment}
        />

        {error && (
          <Grid item xs={12}>
            <Typography color="error" align="center">
              {error}
            </Typography>
          </Grid>
        )}
        {successMessage && (
          <Grid item xs={12}>
            <Typography color="primary" align="center">
              {successMessage}
            </Typography>
          </Grid>
        )}
        {loading && (
          <Grid item xs={12}>
            <Typography align="center">Procesando pago...</Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default ProviderCard;
