import { Either, Usecase } from "../../../core/index";
import { ApiRepositoryImpl } from "../../../data/repository/api";

export default class GetActiveRegionsUsecase implements Usecase<{ active_users_percentage: { region: string; percentage: number }[] }> {
    repository: ApiRepositoryImpl;

    constructor(repository: ApiRepositoryImpl) {
        this.repository = repository;
    }

    async execute(): Promise<Either<{ active_users_percentage: { region: string; percentage: number }[] }>> {
        const data = await this.repository.getActiveRegions();

        return data;
    }
};