import { Either, Usecase } from "../../core/index";

import { ApiRepositoryImpl } from "../../data/repository/api";
import { ContainerPaymentsFullDetailEntity } from "../entities";

export default class GetContainersPaymentsFullDetailUsecase implements Usecase<{containers: ContainerPaymentsFullDetailEntity[],pages:number},number> {
    repository: ApiRepositoryImpl;
    constructor(repository: ApiRepositoryImpl) {
        this.repository = repository;
    }
    
    async execute(page: number): Promise<Either<{containers: ContainerPaymentsFullDetailEntity[],pages:number}>> {
        const data = await this.repository.getContainerFullDetailPayments(page);
        return data;
    }
};