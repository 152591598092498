import { ContainerStatusEnum } from './container_entity';
import { StatusEntity, mapJsonToStatusEntity } from './status';

export interface ContainerAdminEntity {
    id: string; // Corresponde a models.CharField
    status: ContainerStatusEnum; // Corresponde a models.IntegerField con choice
    createdAt: string; // Corresponde a models.DateTimeField(auto_now_add=True)
    updatedAt: string; // Corresponde a models.DateTimeField(auto_now=True)
    name: string; // Corresponde a models.CharField
    containerMovements: AdminContainerMovementEntity[];
}

export function mapJsonToContainerAdminEntity(json): ContainerAdminEntity {
    return {
        id: json.id,
        status: json.status,
        createdAt: json.created_at,
        updatedAt: json.updated_at,
        name: json.name,
        containerMovements: json.container_movements.map(mapJsonToAdminContainerMovementEntity),
    } as ContainerAdminEntity;
}

export interface AdminContainerMovementEntity {
    id: number;
    status: StatusEntity; // Debe definirse una interfaz Status correspondiente
    is_completed: boolean; // Corresponde a models.BooleanField
    estimatedDate: string; // Utilizamos string para las fechas en formato ISO
    realDate: string; // Utilizamos string para la duración en formato ISO 8601
    step: number;
    createdAt: string; // Utilizamos string para las fechas en formato ISO
    updatedAt: string; // Utilizamos string para las fechas en formato ISO

    // "real_date": "2024-03-01T12:00:00Z",
    // "step": 1,
    // "created_at": "2024-10-05T21:50:47.356995Z",
    // "updated_at": "2024-10-05T21:50:47.357003Z",
    // "container": "container-002",

}

export function mapJsonToAdminContainerMovementEntity(json): AdminContainerMovementEntity {
    return {
        id: json.id,
        status: mapJsonToStatusEntity(json.status),
        is_completed: json.is_completed,
        estimatedDate: json.estimated_date,
        realDate: json.real_date,
        step: json.step,
        createdAt: json.created_at,
        updatedAt: json.updated_at,
    } as AdminContainerMovementEntity;
}