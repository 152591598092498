
import { setSuppliers } from '../../redux/slices/supplier_slice';

import apiRepository from '../../../data/repository/index';
import { setStatus, StatusLayoutEnum } from '../../redux/slices/layout_slice';
import GetSuppliersUsecase from '../../../domain/usecases/get_suppliers_usecase';


export const GetSuppliersEvent = () => async (dispatch) => {
  dispatch(setStatus(StatusLayoutEnum.Loading));
  const usecase = new GetSuppliersUsecase(apiRepository);

  const result = await usecase.execute();

  result.fold(
    (error) => {
      console.error('Error fetching suppliers:', error);
    },
    (data) => {
      //console.log('Suppliers fetched:', data);
      dispatch(setSuppliers(data));
      dispatch(setStatus(StatusLayoutEnum.Success));
    }
  );
};

