import React, { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Typography } from "@mui/material";
import { AcceptUserEvent } from "../../../events/admin/accept_user_event";
import { enqueueSnackbar } from "notistack";
import RestrictionComponent from "../../../components/restriction/restriction";

const AcceptUserPage = () => {
    const { user_id } = useParams();

    const [loading, setLoading] = useState<boolean>(false);


    useEffect(() => {
        setLoading(true);
        AcceptUserEvent(user_id).then(() => {
            setLoading(false);
        }).catch(() => {
            enqueueSnackbar('Error al aceptar usuario', { variant: 'error', autoHideDuration: 2000 });
        });
    }, []);

    return (
      <Container>
          <RestrictionComponent auth='Admin'/>
            {
                loading &&
                <Typography variant="h4">
                    Cargando...
                </Typography>
            }
            {
                !loading &&
                <Typography variant="h4">
                    Aceptar usuario con id: {user_id}
                </Typography>
            }
        </Container>
    );
}

export default AcceptUserPage;