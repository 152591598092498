import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Header from '../../../components/admin/layout/Header';
import SideMenu from '../../../components/admin/layout/SideMenu';
import ProviderForm from '../../../components/admin/providers/provider_form';
import RestrictionComponent from '../../../components/restriction/restriction';

export default function AdminProviderFormPage() {
  return (
    <>
      <RestrictionComponent auth='Admin'/>
      <CssBaseline enableColorScheme />
      <Box sx={{ display: 'flex' }}>
        <SideMenu />
        <Box
          component="main"
          sx={() => ({
            flexGrow: 1
          
          })}
        >
          <Stack
            spacing={2}
            sx={{
              alignItems: 'stretch',
              mx: 0,
              pb: 10,
              mt: { xs: 8, md: 0 },
            }}
          >
            <Header/>
            <ProviderForm/>
          </Stack>
        </Box>
      </Box>
      </>
  );
}