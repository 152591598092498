
interface Transaction {
    id: string;
    amount: number;
    amount_usd: number;
    status: number;
    file: string | null;
    additional_payments: Record<string, any>;
    created_at: string;
    updated_at: string;
    payment: string;
}

interface Payment {
    id: string;
    transactions: Transaction[];
    due_date: string;
    deadline: string;
    amount: number;
    amount_usd: number;
    overpayment_amount: number;
    overpayment_transactions: Transaction[];
    company: string;
}

interface ContainerPayment {
    id: number;
    status: number;
    percentage:number;
    payments: Payment[];
}

interface ExternalPayment {
    id: string;
    amount: number;
    amount_usd: number;
    description: string;
    created_at: string;
    updated_at: string;
    supplier: string;
    container: string;
}

export interface ContainerPaymentsFullDetailEntity {
    id: string;
    name:string;
    container_payments: ContainerPayment[];
    external_payments: ExternalPayment[];
    received_amount: number;
    china_amount: number;
    customs_amount: number;
    status:number;
    updated_at: string;
}


export function mappJsonToContainerPaymentsFullDetail(data: any): ContainerPaymentsFullDetailEntity {
    return {
        id: data.id,
        name:data.name,
        container_payments: data.container_payments.map((containerPayment: any) => ({
            id: containerPayment.id,
            status: containerPayment.status,
            percentage: containerPayment.percentage,
            payments: containerPayment.payments.map((payment: any) => ({
                id: payment.id,
                transactions: payment.transactions.map((transaction: any) => ({
                    id: transaction.id,
                    amount: transaction.amount,
                    amount_usd: transaction.amount_usd,
                    status: transaction.status,
                    file: transaction.file,
                    additional_payments: transaction.additional_payments,
                    created_at: transaction.created_at,
                    updated_at: transaction.updated_at,
                    payment: transaction.payment,
                })),
                due_date: payment.due_date,
                deadline: payment.deadline,
                amount: payment.amount,
                amount_usd: payment.amount_usd,
                overpayment_amount: payment.overpayment_amount,
                overpayment_transactions: payment.overpayment_transactions.map((transaction: any) => ({
                    id: transaction.id,
                    amount: transaction.amount,
                    status: transaction.status,
                    file: transaction.file,
                    additional_payments: transaction.additional_payments,
                    created_at: transaction.created_at,
                    updated_at: transaction.updated_at,
                    payment: transaction.payment,
                })),
                company: payment.company,
            })),
        })),
        external_payments: data.external_payments.map((externalPayment: any) => ({
            id: externalPayment.id,
            amount: externalPayment.amount,
            description: externalPayment.description,
            created_at: externalPayment.created_at,
            updated_at: externalPayment.updated_at,
            supplier: externalPayment.supplier,
            container: externalPayment.container,
        })),
        received_amount: data.received_amount,
        china_amount: data.china_amount,
        customs_amount: data.customs_amount,
        status: data.current_movement,
        updated_at: data.updated_at,
    };
}
