import React from "react";
import { Box, Typography, Card, Grid } from "@mui/material";

interface Product {
  name: string;
  id: string;
}

interface Container {
  total_amount: number;
  amount_usd: number;
  products: Product[];
  status: string;
  end_date: string;
}

interface ContainersTableProps {
  containers: Container[];
  title: string;
}
  const formatAmount = (amount, currency = "CLP") => {
    const numericAmount = Number(amount);
  
    if (isNaN(numericAmount)) return amount;
  
    if (currency === "CLP") {
      // Formato para CLP: punto entre miles, coma para decimales
      return numericAmount.toLocaleString("es-CL", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    } else if (currency === "USD") {
      // Formato para USD: punto decimal, dos decimales
      return numericAmount.toLocaleString("en-US", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    }
  
    return amount; // Retorno sin formato si no se especifica una moneda válida
  };

const ContainersTable: React.FC<ContainersTableProps> = ({ containers, title }) => {


  const formatEndDate = (endDate: string | null) => {
    if (!endDate || endDate === "2999-12-31T20:00:00Z") {
      return "Sin fecha estimada aún";
    }
    const date = new Date(endDate);
    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return date.toLocaleDateString("es-CL", options).replace(",", " a las");
  };

  const containersArray = Object.entries(containers).map(([key, value]) => ({
    key,
    ...value,
  }));
  const inTransitContainers = containersArray.filter((container) => container.status !== "Entregado");
  const deliveredContainers = containersArray.filter((container) => container.status === "Entregado");
  const containersByTitle =
    title === "Contenedores Entregados" ? deliveredContainers : inTransitContainers;


  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "500px",
        height: "400px",
        padding: "16px",
        backgroundColor: "#f9f9f9",
        borderRadius: "8px",
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: 1,
      }}
    >
      <Typography
        variant="h5"
        sx={{ color: "#0A66A5", marginBottom: 2, fontWeight: "bold" }}
      >
        {title}
      </Typography>
      <Box
        sx={{
          width: "100%",
          overflowY: "auto",
          maxHeight: "400px",
        }}
      >

        {containersByTitle.length === 0 ? (
          <Typography
            variant="body2"
            color="textSecondary"
            align="center"
            sx={{ color: "#0A66A5", textAlign: "center", marginTop: "16px" }}
          >
            No hay contenedores para mostrar.
          </Typography>
        ) : (
          <Grid container spacing={2}>
            {containersByTitle.map((container, index) => (
              <Grid item xs={12} key={index}>
                <Card
                  sx={{
                    padding: "15px",
                    borderRadius: "7px",
                    textAlign: "left",
                    marginBottom: "10px",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{ color: "#0A66A5", fontWeight: 600, marginBottom: "8px" }}
                  >
                    Contenedor ID: {container.key}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: 600, marginBottom: "8px" }}
                  >

                    Monto total: ${formatAmount(container.total_amount, 'CLP')} CLP / $
                    {formatAmount(container.amount_usd, 'USD')} USD

                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: 600, marginBottom: "8px" }}
                  >
                    Estado: {container.status}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: 600, marginBottom: "8px" }}
                  >
                    Fecha de entrega: {formatEndDate(container.end_date)}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontWeight: 500, marginBottom: "8px" }}
                  >
                    Productos:
                  </Typography>
                  <Box sx={{ textAlign: "left", paddingLeft: "16px" }}>
                    {Array.isArray(container.products) && container.products.length > 0 ? (
                      container.products.map((product, productIndex) => (
                        <Typography
                          key={productIndex}
                          variant="body2"
                          sx={{ color: "#333", marginBottom: "4px" }}
                        >
                          - {product.name || "Producto sin nombre"}
                        </Typography>
                      ))
                    ) : (
                      <Typography
                        variant="body2"
                        sx={{ color: "GrayText" }}
                      >
                        No hay productos en este contenedor
                      </Typography>
                    )}
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>

        )}
      </Box>
    </Box>
  );

};

export default ContainersTable;
