import React, { useState, ChangeEvent } from "react";
import {
  Button,
  TextField,
  Grid,
  Box,
  Typography,
  MenuItem,
} from "@mui/material";
import { CreateTransactionEvent } from "../../events/proforma/create_transaction_event";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { addTransactionToPayment } from "../../redux/slices/proforma_slice";
import { setStatus, StatusLayoutEnum } from "../../redux/slices/layout_slice";
import { ProformaEntity, PaymentEntity } from "../../../domain/entities";
import { RootState } from "../../redux";

interface MultiTransactionProps {
  proformas: ProformaEntity[];
}

interface TransactionData {
  proformaId: string;
  paymentId: string;
  amount: number | string;
  currency: "USD" | "CLP";
}

const MultiTransaction: React.FC<MultiTransactionProps> = ({ proformas }) => {
  const dispatch = useAppDispatch();
  const { usdToClp } = useAppSelector(
    (state: RootState) => state.exchange_rate
  );

  const [transactions, setTransactions] = useState<TransactionData[]>([
    { proformaId: "", paymentId: "", amount: "", currency: "USD" },
  ]);
  const [file, setFile] = useState<File | null>(null);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile && selectedFile.type === "application/pdf") {
      setFile(selectedFile);
    } else {
      alert("Please select a valid PDF file.");
    }
  };

  const handleAddTransaction = () => {
    setTransactions([
      ...transactions,
      { proformaId: "", paymentId: "", amount: "", currency: "USD" },
    ]);
  };

  const handleTransactionChange = (
    index: number,
    field: keyof TransactionData,
    value: string | number
  ) => {
    const newTransactions = [...transactions];
    newTransactions[index] = {
      ...newTransactions[index],
      [field]: value,
    };
    setTransactions(newTransactions);
  };

  const handleCurrencyChange = (index: number, currency: "USD" | "CLP") => {
    handleTransactionChange(index, "currency", currency);
  };

  const handleSubmit = () => {
    if (!file) {
      alert("Please select a file.");
      return;
    }

    dispatch(setStatus(StatusLayoutEnum.Loading));

    const transactionPromises = transactions.map((transaction) => {
      if (transaction.paymentId && transaction.amount) {
        const amount = Number(transaction.amount);
        const amountUsd =
          transaction.currency === "CLP" && usdToClp
            ? amount / usdToClp
            : amount;

        const amountClp =
          transaction.currency === "USD" && usdToClp
            ? amount * usdToClp
            : amount;

        return CreateTransactionEvent(
          file,
          transaction.paymentId,
          amountClp,
          amountUsd
        ).then((newTransaction) => {
          dispatch(
            addTransactionToPayment({
              paymentId: transaction.paymentId,
              transaction: newTransaction,
            })
          );
        });
      }
      return Promise.resolve();
    });

    Promise.all(transactionPromises)
      .then(() => {
        setTransactions([
          { proformaId: "", paymentId: "", amount: "", currency: "USD" },
        ]);
        setFile(null);
        const fileInput = document.getElementById(
          "contained-button-file"
        ) as HTMLInputElement;
        if (fileInput) {
          fileInput.value = "";
        }
      })
      .finally(() => {
        dispatch(setStatus(StatusLayoutEnum.Success));
      });
  };

  const getPaymentsForProforma = (proformaId: string): PaymentEntity[] => {
    const proforma = proformas.find((p) => p.id === proformaId);
    return proforma ? proforma.payments : [];
  };

  const convertedAmount = (
    amount: string | number,
    currency: "USD" | "CLP"
  ) => {
    if (!usdToClp || !amount) return "";
    return currency === "USD"
      ? (Number(amount) * usdToClp).toFixed(0)
      : (Number(amount) / usdToClp).toFixed(2);
  };

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "500px",
        maxHeight: "400px",
        minHeight: "400px",
        height: "auto",
        padding: "16px",
        backgroundColor: "#f9f9f9",
        borderRadius: "8px",
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        boxShadow: 1,
      }}
    >
      <Typography
        variant="h5"
        sx={{ color: "#0A66A5", fontWeight: "bold", marginBottom: "10px" }}
      >
        Agregar Múltiples Transacciones
      </Typography>

      <Box sx={{ width: "100%", overflowY: "auto", marginTop: "5px" }}>
        {transactions.map((transaction, index) => (
          <Box key={index} sx={{ marginBottom: "24px" , marginTop: "5px"}}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  select
                  label="Seleccionar Proforma"
                  value={transaction.proformaId}
                  onChange={(e) => {
                    const proformaId = e.target.value;
                    handleTransactionChange(index, "proformaId", proformaId);
                  }}
                  fullWidth
                >
                  {proformas.map((proforma) => (
                    <MenuItem key={proforma.id} value={proforma.id}>
                      {proforma.productName}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  select
                  label="Seleccionar Payment ID"
                  value={transaction.paymentId}
                  onChange={(e) =>
                    handleTransactionChange(index, "paymentId", e.target.value)
                  }
                  fullWidth
                  disabled={!transaction.proformaId}
                >
                  {getPaymentsForProforma(transaction.proformaId).map(
                    (payment) => (
                      <MenuItem key={payment.id} value={payment.id}>
                        {payment.id}
                      </MenuItem>
                    )
                  )}
                </TextField>
              </Grid>

              <Grid item xs={4}>
                <TextField
                  select
                  label="Seleccionar Moneda"
                  value={transaction.currency}
                  onChange={(e) =>
                    handleCurrencyChange(index, e.target.value as "USD" | "CLP")
                  }
                  fullWidth
                >
                  <MenuItem value="USD">USD</MenuItem>
                  <MenuItem value="CLP">CLP</MenuItem>
                </TextField>
              </Grid>

              <Grid item xs={4}>
                <TextField
                  label={`Monto en ${transaction.currency}`}
                  type="number"
                  value={transaction.amount}
                  onChange={(e) => {
                    const value = Number(e.target.value);
                    if (value >= 0 || e.target.value === "") {
                      handleTransactionChange(index, "amount", e.target.value);
                    }
                  }}
                  error={Number(transaction.amount) < 0}
                  helperText={
                    Number(transaction.amount) < 0
                      ? "El monto no puede ser negativo."
                      : ""
                  }
                  fullWidth
                />
              </Grid>

              <Grid item xs={4}>
                <TextField
                  label={`Monto en ${
                    transaction.currency === "USD" ? "CLP" : "USD"
                  }`}
                  type="text"
                  value={convertedAmount(
                    transaction.amount,
                    transaction.currency
                  )}
                  fullWidth
                  disabled
                />
              </Grid>
            </Grid>
          </Box>
        ))}
      </Box>

      <Button
        onClick={handleAddTransaction}
        variant="contained"
        color="primary"
        sx={{ marginTop: "16px" }}
      >
        Agregar Transacción
      </Button>

      {file && (
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{ marginY: "8px" }}
        >
          Archivo seleccionado: {file.name}
        </Typography>
      )}

      <Box sx={{ marginY: "16px" }}>
        <input
          accept="application/pdf"
          id="contained-button-file"
          type="file"
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
        <label htmlFor="contained-button-file">
          <Button variant="contained" color="primary" component="span">
            {file ? "Cambiar archivo" : "Seleccionar archivo"}
          </Button>
        </label>
      </Box>

      <Button
        onClick={handleSubmit}
        variant="contained"
        color="success"
        disabled={!file || !transactions.length}
      >
        Enviar
      </Button>
    </Box>
  );
};

export default MultiTransaction;
