import React, { useState } from 'react';
import { Box, Tabs, Tab, Typography, Table, TableBody, TableCell, TableContainer, TableRow  } from '@mui/material';

const ProductDetails = ({product}) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const renderContent = () => {
    switch (selectedTab) {
      case 0:
        return <Typography variant="body1" style={{ textAlign: 'justify' }}>{product.description}</Typography>;
      case 1:
        return ( <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Material</TableCell>
                <TableCell align="center">{product.materials}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Ancho</TableCell>
                <TableCell align="center">{product.width} cm</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Alto</TableCell>
                <TableCell align="center">{product.depth} cm</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Largo</TableCell>
                <TableCell align="center">{product.length} cm </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Peso</TableCell>
                <TableCell align="center">{product.weight} gramos</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      );
      default:
        return null;
    }
  };

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column',justifyContent: 'center',alignItems: 'center', }}>
      {/* Parte superior: Imagen */}
      
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Typography variant="h5" component="h5" gutterBottom>
        <strong>{product.name}</strong>
        </Typography>
      </Box>
    
      <Box sx={{ height:'40vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img
          src={product.imageUrl} // Imagen de ejemplo  margin: '0 auto'
          alt="Producto"
          style={{ height: '90%', objectFit: 'contain' }}
        />
      </Box>

      {/* Pestañas de selección */}
      <Tabs value={selectedTab} onChange={handleTabChange} centered>
        <Tab label="Descripción" />
        <Tab label="Características" />
      </Tabs>

      {/* Contenido dinámico */}
      <Box sx={{ flex: '1 1 auto', overflowY: 'auto', padding: 2 , width: '90%'}} >
        {renderContent()}
      </Box>
    </Box>
  );
};

export default ProductDetails;
