import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  IconButton,
  TablePagination,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { GetCompaniesEvent } from "../../../events/company/get_companies_event";
import ClientLayout from "./Client_layout";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const ClientsCfo: React.FC = () => {
  const { companies } = useAppSelector((state) => state.company);
  const dispatch = useAppDispatch();

  const [sortConfig, setSortConfig] = useState<{
    column: string;
    direction: "asc" | "desc";
  }>({ column: "rut", direction: "asc" });
  const [page, setPage] = useState(0);
  const rowsPerPage = 5;

  useEffect(() => {
    dispatch(GetCompaniesEvent());
  }, [dispatch]);

  const sortedCompanies = [...companies].sort((a, b) => {
    const { column, direction } = sortConfig;
    let aValue, bValue;

    if (column === "rut") {
      aValue = a.rut;
      bValue = b.rut;
    } else if (column === "name") {
      aValue = a.name;
      bValue = b.name;
    }

    if (aValue > bValue) return direction === "asc" ? 1 : -1;
    if (aValue < bValue) return direction === "asc" ? -1 : 1;
    return 0;
  });

  const paginatedCompanies = sortedCompanies.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const toggleSortDirection = (column: string) => {
    setSortConfig((prevConfig) => ({
      column,
      direction:
        prevConfig.column === column && prevConfig.direction === "asc"
          ? "desc"
          : "asc",
    }));
  };

  return (
    <>
      <Grid container style={{ width: "95%" }}>
        <Grid
          item
          xs={12}
          style={{
            height: "50px",
            borderBottom: "1px solid #ccc",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Grid container sx={{ display: "flex", alignItems: "center" }}>
            <Grid
              item
              xs={2.4}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", color: "#0A66A5" }}
              >
                Rut
              </Typography>
              <IconButton onClick={() => toggleSortDirection("rut")}>
                {sortConfig.column === "rut" &&
                sortConfig.direction === "asc" ? (
                  <ArrowUpwardIcon sx={{ fontSize: 20 }} />
                ) : (
                  <ArrowDownwardIcon sx={{ fontSize: 20 }} />
                )}
              </IconButton>
            </Grid>
            <Grid
              item
              xs={2.4}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", color: "#0A66A5" }}
              >
                Nombre
              </Typography>
              <IconButton onClick={() => toggleSortDirection("name")}>
                {sortConfig.column === "name" &&
                sortConfig.direction === "asc" ? (
                  <ArrowUpwardIcon sx={{ fontSize: 20 }} />
                ) : (
                  <ArrowDownwardIcon sx={{ fontSize: 20 }} />
                )}
              </IconButton>
            </Grid>
            <Grid
              item
              xs={2.4}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", color: "#0A66A5" }}
              >
                Dirección
              </Typography>
            </Grid>
            <Grid
              item
              xs={2.4}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", color: "#0A66A5" }}
              >
                Teléfono
              </Typography>
            </Grid>
            <Grid
              item
              xs={2.4}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", color: "#0A66A5" }}
              >
                Email
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item style={{ width: "95%" }}>
        <Grid container spacing={1}>
          {paginatedCompanies.map((client) => (
            <Grid item xs={12} key={client.id}>
              <ClientLayout client={client} />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <TablePagination
        component="div"
        count={companies.length}
        page={page}
        onPageChange={handlePageChange}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5]}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} de ${count}`
        }
      />
    </>
  );
};

export default ClientsCfo;
