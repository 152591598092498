import { Either, Usecase } from "../../../core/index";
import { ApiRepositoryImpl } from "../../../data/repository/api";

export default class GetAccountReceivableUsecase implements Usecase<{total_debt: number,monthly_debt: number}> {
    repository: ApiRepositoryImpl;

    constructor(repository: ApiRepositoryImpl) {
        this.repository = repository;
    }

    async execute(): Promise<Either<{total_debt: number,monthly_debt: number}>> {
        const data = await this.repository.getAccountReceivable();

        return data;
    }
};