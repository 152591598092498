import apiRepository from '../../../data/repository/index';
import { setStatus, StatusLayoutEnum } from '../../redux/slices/layout_slice';
import GetContainerProductDeudasUsecase from '../../../domain/usecases/get_container_product_deudas_usecase';


export const GetContainerProducDeudasEvent = (container_id: string) => async (dispatch) => {
  dispatch(setStatus(StatusLayoutEnum.Loading));
  const usecase = new GetContainerProductDeudasUsecase(apiRepository);

  const result = await usecase.execute(container_id);

  result.fold(
    (error) => {
      console.error('Error fetching suppliers:', error);
    },
    () => {
      dispatch(setStatus(StatusLayoutEnum.Success));
    }
  );
    return result.right;
};

