import React from 'react';
import { Card, Container, Grid, Typography } from '@mui/material';
import DataCard from './container_quantity_table';
import { ProductEntity, ContainerPaymentEntity} from '../../../domain/entities';

interface ContainerCardProps {
    id: string;
    containerProduct: {
        id: string;
        product: ProductEntity;
        quantity: number;
        minQuantity: number;
        maxQuantity: number;
        unit_base_price_usd: number;
        unit_purchase_price_usd: number;
        unitBasePrice: number;
        unitsPerPackage: number;
        increasedPercent: number;
        interval: number;
    };
    containerPayments: ContainerPaymentEntity[]
}

const ContainerCard: React.FC<{ container: ContainerCardProps }> = ({ container }) => {

    return (
        <Card
            elevation={3}
            sx={{
                padding: '30px',
                marginBottom: '40px',
                borderRadius: '50px',
                borderColor: '#0A66A5',
                borderWidth: '1px',
                borderStyle: 'solid', // Add this line
            }}
        >
            <Grid container>
                <Grid container item xs={4} direction='column'>
                    <Container>
                        <Typography variant='h5' sx={{
                            fontWeight: '600',
                            color: '#0A66A5',
                            textAlign:'center'
                        }}>
                            {container.containerProduct.product.name}
                        </Typography>
                    </Container>

                    <Container
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: '30px',
                        }}
                    >
                        <img
                            src={container.containerProduct.product.imageUrl}
                            style={{
                                height: '200px',
                                aspectRatio: '1/1',
                            }}
                            alt="Random"
                        />
                    </Container>
                    <Container
                        sx={{
                            marginTop: '30px',
                        }}
                    >
                        <Card
                            sx={{
                                padding: '5px',
                                borderRadius: '30px',
                                borderColor: '#0A66A5',
                                borderWidth: '1px',
                                borderStyle: 'solid',
                            }}
                        >
                            <Grid container sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Grid item xs sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'column'
                                }}>
                                    <Typography variant='body2' sx={{
                                        color: '#0A66A5',
                                        lineHeight: '1'
                                    }}>
                                        {container.containerProduct.product.size||"40x40"}
                                    </Typography>
                                    <Typography variant='body2' sx={{
                                        color: '#0A66A5',
                                        lineHeight: '1'
                                    }}>
                                        Centímetros
                                    </Typography>
                                </Grid>
                                <div style={{ border: '1px solid #0A66A5' }}></div>
                                <Grid item xs sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'column'
                                }}>
                                    <Typography variant='body2' sx={{
                                        color: '#0A66A5',
                                        lineHeight: '1'
                                    }}>
                                        {container.containerProduct.product.weight||"40"}
                                    </Typography>
                                    <Typography variant='body2' sx={{
                                        color: '#0A66A5',
                                        lineHeight: '1'
                                    }}>
                                        Gramos
                                    </Typography>
                                </Grid>
                                <div style={{ border: '1px solid #0A66A5' }}></div>
                                <Grid item xs sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'column'
                                }}>
                                    <Typography variant='body2' sx={{
                                        color: '#0A66A5',
                                        lineHeight: '1'
                                    }}>
                                        {container.containerProduct.product.materials||"80% poliester"}
                                    </Typography>
                                   
                                </Grid>
                            </Grid>
                        </Card>
                    </Container>
                </Grid>

                 <Grid
                        item
                        xs={8}
                        container
                        alignItems="center"
                        justifyContent="center"
                >
                    <DataCard importData={container}/>
                </Grid> 
            </Grid>
        </Card>
    );
};

export default ContainerCard;
