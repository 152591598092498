import React, { useState } from "react";
import {
  Grid,
  Button,
  TableContainer,
  TableRow,
  TableHead,
  Table,
  TableCell,
  TableBody,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Box,
  SelectChangeEvent,
  InputLabel,
  Select,
  FormControl
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import AddIcon from "@mui/icons-material/Add";
import { Status } from "../../../redux/slices/admin_container_slice";
import ContainerProduct from "./container_product_layout";
import { GetContainerProducDeudasEvent } from "../../../events/cfo/get_container_product_deudas_events";
import { useAppDispatch } from "../../../hooks/hooks";
import { CreateExternalPaymentEvent } from "../../../events/cfo/create_external_payment_event";
import { useAppSelector } from "../../../hooks/hooks";

const suppliers = [
  {
    id: "SUP001",
    name: "Supplier 1",
  },
  {
    id: "SUP002",
    name: "Supplier 2",
  },
  {
    id: "CUS001",
    name: "Customs 1",
  },
  {
    id: "FOR001",
    name: "Logistics Services Inc.",
  },
];

const ContainerLayout = ({ contenedor }) => {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [filteredSuppliers, setFilteredSuppliers] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState("");
  const [amount, setAmount] = useState("");
  const [amountUSD, setAmountUSD] = useState("");
  const [products, setProducts] = useState(null);
  const dispatch = useAppDispatch();

  const [currency, setCurrency] = useState<"USD" | "CLP">("USD");
  const { usdToClp } = useAppSelector((state) => state.exchange_rate);
  const [paymentAmount, setPaymentAmount] = useState("");

  const handleCurrencyChange = (event: SelectChangeEvent<"USD" | "CLP">) => {
    setCurrency(event.target.value as "USD" | "CLP");
  };
  const [dialogOpen, setDialogOpen] = useState(false);
  const [showTables, setShowTables] = useState(false);
  
  const handleToggle = () => {
    setShowTables((prev) => !prev);
  };

  const handleDialogOpen = async () => {
    const products = await dispatch(
      GetContainerProducDeudasEvent(contenedor.id)
    );
    setProducts(products);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleFormSubmit = () => {
    crear_pago_externo();
  };

  const handleCategoryChange = (e) => {
    const category = e.target.value;
    setSelectedCategory(category);

    // Caso 1: Pago a China - Mostrar proveedores sin "SUP" en su supplier
    if (category === "Internación") {
      const validSuppliers = suppliers.filter(
        (supplier) => !supplier.id.includes("SUP") // Filtramos proveedores que no contienen "SUP"
      );
      setFilteredSuppliers(validSuppliers);
      setSelectedSupplier(""); // Reiniciar selección de proveedor
      return;
    }

    if (!products || !products.containerProducts) {
      console.error("Productos no disponibles para filtrar proveedores.");
      setFilteredSuppliers([]);
      return;
    }

    const validSuppliersForInternation = suppliers.filter((supplier) =>
      products.containerProducts.some(
        (product) => product.supplier === supplier.id
      )
    );

    setFilteredSuppliers(validSuppliersForInternation);
    setSelectedSupplier(""); // Reiniciar selección de proveedor
  };

  function formatDate(dateString) {
    const formattedString = dateString.replace(" a las ", "T");
    const date = new Date(formattedString);
    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "short",
      year: "numeric",
    };
    return new Intl.DateTimeFormat("es-ES", options).format(date);
  }

  function formatDateISO(dateString) {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "short",
      year: "numeric",
    };
    return new Intl.DateTimeFormat("es-ES", options).format(date);
  }

  const utilidad =
    contenedor.received_amount -
    contenedor.customs_amount -
    contenedor.china_amount;
  const estados = ["Pendiente", "Listo", "Rechazado"];

  const convertedAmount =
  currency === "USD"
    ? usdToClp && paymentAmount
      ? (Number(paymentAmount) * usdToClp).toFixed(0)
      : ""
    : usdToClp && paymentAmount
    ? (Number(paymentAmount) / usdToClp).toFixed(2)
    : "";

  const crear_pago_externo = async () => {
    // const amountNumber = parseFloat(amount.replace(",", "."));
    // const amountUSDNumber = parseFloat(amountUSD.replace(",", "."));
    
    const amountClp =
      currency === "USD" && usdToClp
        ? Number(paymentAmount) * usdToClp
        : Number(paymentAmount);

    const amountUsd =
      currency === "CLP" && usdToClp
        ? Number(paymentAmount) / usdToClp
        : Number(paymentAmount);


    if (
      !selectedCategory ||
      !selectedSupplier ||
      amountClp <= 0 ||
      amountUsd <= 0
    ) {
      alert(
        "Todos los campos deben estar llenos y los montos debe ser mayor a 0."
      );
      return;
    }
    const description = `Pago externo para ${selectedCategory}`;

    const pago = await CreateExternalPaymentEvent(
      selectedSupplier, // supplierId
      contenedor.id, // containerId
      amountClp, // amount
      amountUsd, // amount_usd
      description // descripción
    );
    const data = {
      amount: pago.amount,
      container: pago.containerId,
      created_at: pago.createdAt,
      description: pago.description,
      id: pago.id,
      supplier: pago.supplierId,
      updated_at: pago.createdAt,
    };
    contenedor.external_payments.push(data);

    if (data.supplier.includes("SUP")) {
      contenedor.china_amount += pago.amount;
    } else {
      contenedor.customs_amount += pago.amount;
    }
    setSelectedCategory("");
    setAmount("");
    setAmountUSD("");
    handleDialogClose();
  };

  return (
    <Grid container style={{ width: "100%" }}>
      {/* Fila superior */}
      <Grid
        item
        xs={12}
        style={{ height: "50px", borderBottom: "1px solid #ccc" }}
        sx={{
          "&:hover": {
            backgroundColor: "#e0e0e0", // Cambia el color de fondo al pasar el mouse
            cursor: "pointer", // Cambia el cursor a puntero
          },
          transition: "background-color 0.3s ease", // Transición suave
        }}
        onClick={handleToggle}
      >
        <Grid container>
          <Grid
            item
            xs={0.8}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "5px",
            }}
            
          >
            <Button
              //onClick={handleToggle}
              startIcon={
                showTables ? (
                  <ExpandLessIcon style={{ fontSize: "30px" }} />
                ) : (
                  <ExpandMoreIcon style={{ fontSize: "30px" }} />
                )
              }
              sx={{ minHeight: "35px", padding: "0" }}
            />
          </Grid>
          <Grid
            item
            xs={1.8}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Typography
              variant="body1"
              sx={{ fontWeight: "bold", textAlign: "center" }}
            >
              {contenedor.name}
            </Typography>
          </Grid>
          <Grid
            item
            xs={1.8}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Typography variant="body1" sx={{ textAlign: "center" }}>
              {Status[contenedor.status - 1].name}
            </Typography>
          </Grid>
          <Grid
            item
            xs={1.9}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Typography variant="body1">
              $
              {contenedor.received_amount.toLocaleString("es-ES", {
                maximumFractionDigits: 0,
              })}
            </Typography>
          </Grid>
          <Grid
            item
            xs={1.9}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Typography variant="body1">
              $
              {contenedor.china_amount.toLocaleString("es-ES", {
                maximumFractionDigits: 0,
              })}
            </Typography>
          </Grid>
          <Grid
            item
            xs={1.9}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Typography variant="body1">
              $
              {contenedor.customs_amount.toLocaleString("es-ES", {
                maximumFractionDigits: 0,
              })}
            </Typography>
          </Grid>
          <Grid
            item
            xs={1.9}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Typography
              variant="body1"
              sx={{ color: utilidad < 0 ? "red" : "#0A66A5" }}
            >
              {utilidad < 0
                ? `-$${Math.abs(utilidad).toLocaleString("es-ES", {
                    maximumFractionDigits: 0,
                  })}`
                : `$${utilidad.toLocaleString("es-ES", {
                    maximumFractionDigits: 0,
                  })}`}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {showTables && (
        <>
          <Grid item xs={12}>
            <Grid container item sx={{ marginBottom: "10px" }}>
              <Grid
                item
                xs={6}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: "bold",
                    color: "#0A66A5",
                    padding: "5px",
                    marginLeft: "15px",
                  }}
                >
                  Abonos
                </Typography>

                <TableContainer sx={{ maxHeight: "100%", width: "94%" }}>
                  <Table
                    sx={{
                      borderCollapse: "separate",
                      borderSpacing: "0px 0px",
                      width: "100%",
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            fontWeight: "bold",
                            padding: "2px",
                            textAlign: "center",
                            backgroundColor: "#f0f0f0",
                            border: "none",
                          }}
                        >
                          Cliente
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: "bold",
                            padding: "2px",
                            textAlign: "center",
                            backgroundColor: "#f0f0f0",
                            border: "none",
                          }}
                        >
                          Fecha
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: "bold",
                            padding: "2px",
                            textAlign: "center",
                            backgroundColor: "#f0f0f0",
                            border: "none",
                          }}
                        >
                          Monto
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: "bold",
                            padding: "2px",
                            textAlign: "center",
                            backgroundColor: "#f0f0f0",
                            border: "none",
                          }}
                        >
                          Plan de pago
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: "bold",
                            padding: "2px",
                            textAlign: "center",
                            backgroundColor: "#f0f0f0",
                            border: "none",
                          }}
                        >
                          Estado
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {contenedor.container_payments.map((container_payment) =>
                        container_payment.payments.map((payment) =>
                          payment.transactions.map((transaction, index) => (
                            <TableRow
                              key={index}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                sx={{
                                  padding: "1px",
                                  textAlign: "center",
                                  backgroundColor: "#f0f0f0",
                                  border: "none",
                                }}
                              >
                                {payment.company}
                              </TableCell>
                              <TableCell
                                sx={{
                                  padding: "1px",
                                  textAlign: "center",
                                  backgroundColor: "#f0f0f0",
                                  border: "none",
                                }}
                              >
                                {formatDate(payment.due_date)}
                              </TableCell>
                              <TableCell
                                sx={{
                                  padding: "1px",
                                  textAlign: "center",
                                  backgroundColor: "#f0f0f0",
                                  border: "none",
                                }}
                              >
                                {transaction.amount.toLocaleString("es-ES", {
                                  maximumFractionDigits: 0,
                                })}
                              </TableCell>
                              <TableCell
                                sx={{
                                  padding: "1px",
                                  textAlign: "center",
                                  backgroundColor: "#f0f0f0",
                                  border: "none",
                                }}
                              >
                                {container_payment.percentage * 100}% -{" "}
                                {(
                                  payment.amount - payment.overpayment_amount
                                ).toLocaleString("es-ES", {
                                  maximumFractionDigits: 0,
                                })}{" "}
                              </TableCell>
                              <TableCell
                                sx={{
                                  padding: "1px",
                                  textAlign: "center",
                                  backgroundColor: "#f0f0f0",
                                  border: "none",
                                  color:
                                    transaction.status === 2
                                      ? "red"
                                      : transaction.status === 0
                                      ? "#e7bb00"
                                      : transaction.status === 1
                                      ? "green"
                                      : "inherit",
                                }}
                              >
                                <strong>{estados[transaction.status]}</strong>
                              </TableCell>
                            </TableRow>
                          ))
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid
                item
                xs={5.8}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: "bold",
                    color: "#0A66A5",
                    padding: "5px",
                    marginLeft: "15px",
                  }}
                >
                  Pagos
                </Typography>
                <Grid container item sx={{ alignItems: "center" }}>
                  <Grid
                    item
                    xs={10}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <TableContainer sx={{ maxHeight: "100%", width: "100%" }}>
                      <Table
                        sx={{
                          borderCollapse: "separate",
                          borderSpacing: "0px 0px",
                          width: "100%",
                        }}
                      >
                        <TableHead>
                        <TableRow>
       
                            <TableCell
                              sx={{
                                fontWeight: "bold",
                                padding: "2px",
                                textAlign: "center",
                                backgroundColor: "#f0f0f0",
                                border: "none",
                              }}
                            >
                              Proveedor
                            </TableCell>
                            <TableCell
                              sx={{
                                fontWeight: "bold",
                                padding: "2px",
                                textAlign: "center",
                                backgroundColor: "#f0f0f0",
                                border: "none",
                              }}
                            >
                              Categoría
                            </TableCell>
                            <TableCell
                              sx={{
                                fontWeight: "bold",
                                padding: "2px",
                                textAlign: "center",
                                backgroundColor: "#f0f0f0",
                                border: "none",
                              }}
                            >
                              Fecha
                            </TableCell>
                            <TableCell
                              sx={{
                                fontWeight: "bold",
                                padding: "2px",
                                textAlign: "center",
                                backgroundColor: "#f0f0f0",
                                border: "none",
                              }}
                            >
                              Monto
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {contenedor.external_payments.map(
                            (external_payment) => (
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  sx={{
                                    padding: "1px",
                                    textAlign: "center",
                                    backgroundColor: "#f0f0f0",
                                    border: "none",
                                  }}
                                >
                                  {
                                    // Buscar el proveedor con el supplierId correspondiente
                                    suppliers.find(
                                      (supplier) =>
                                        supplier.id ===
                                        external_payment.supplier
                                    )?.name || "Proveedor no encontrado"
                                  }
                                </TableCell>
                                <TableCell
                                  sx={{
                                    padding: "1px",
                                    textAlign: "center",
                                    backgroundColor: "#f0f0f0",
                                    border: "none",
                                  }}
                                >
                                  {external_payment.supplier.includes("SUP")
                                    ? "Pagos a China"
                                    : "Pagos internación"}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    padding: "1px",
                                    textAlign: "center",
                                    backgroundColor: "#f0f0f0",
                                    border: "none",
                                  }}
                                >
                                  {formatDateISO(external_payment.created_at)}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    padding: "1px",
                                    textAlign: "center",
                                    backgroundColor: "#f0f0f0",
                                    border: "none",
                                  }}
                                >
                                  {external_payment.amount.toLocaleString(
                                    "es-ES",
                                    { maximumFractionDigits: 0 }
                                  )}
                                </TableCell>
                              </TableRow>
                            )
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                  <Grid
  item
  xs={2}
  sx={{
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "20px",
  }}
>
  <Button
    variant="outlined"
    startIcon={
      <AddIcon
        sx={{ marginLeft: "10px" }} // Desplaza el ícono hacia la derecha
      />
    }
    onClick={handleDialogOpen}
    sx={{
      width: "40px", // Igualar ancho y alto para que sea un cuadrado
      height: "40px",
      display: "flex",
      alignItems: "center", // Centra el contenido verticalmente
      justifyContent: "center", // Centra el contenido horizontalmente
      padding: "0", // Remueve padding innecesario
      minWidth: "unset", // Evita restricciones predeterminadas de Material UI
    }}
  />
</Grid>

                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}

      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        sx={{
          "& .MuiDialog-paper": {
            width: "70vw",
            maxWidth: "1000px",
          },
        }}
      >
        <DialogTitle sx={{ textAlign: "center" }}>
          Agregar Nuevo Pago
        </DialogTitle>
        <DialogContent>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            width="100%"
          >
            {/* Campo de selección para la categoría */}
            <Box
              display="flex"
              justifyContent="space-between"
              width="80%"
              mb={2}
            >
              <TextField
                select
                label="Categoría"
                value={selectedCategory}
                onChange={(e) => handleCategoryChange(e)}
                margin="dense"
                sx={{ width: "48%" }}
              >
                <MenuItem value="Proveedor">Pagos a China</MenuItem>
                <MenuItem value="Internación">Pagos internación</MenuItem>
              </TextField>

              {/* Campo de selección para el proveedor */}
              <TextField
                select
                label="Proveedor"
                value={selectedSupplier}
                onChange={(e) => setSelectedSupplier(e.target.value)}
                margin="dense"
                sx={{ width: "48%" }}
              >
                {filteredSuppliers.map((supplier) => (
                  <MenuItem key={supplier.id} value={supplier.id}>
                    {supplier.name}
                  </MenuItem>
                ))}
              </TextField>
            </Box>

            {selectedCategory === "Proveedor" &&
              products?.containerProducts?.length > 0 && (
                <>
                  {products.containerProducts.map((container, index) => (
                    <ContainerProduct key={index} container={container} />
                  ))}
                  <DialogTitle sx={{ textAlign: "center" }}>
                    {`Deuda del Contenedor: $ ${products.debtClp.toLocaleString(
                      "es-ES",
                      { maximumFractionDigits: 0 }
                    )} CLP / $ ${products.debtUsd.toLocaleString("es-US", {
                      maximumFractionDigits: 2,
                    })} USD`}
                  </DialogTitle>
                </>
              )}

            {/* Campo de monto */}
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                gap={2} // Espaciado uniforme entre los elementos
                width="80%"
                mb={2}
              >
                <Box sx={{ flex: 1 }}>
                <FormControl fullWidth>
                  <InputLabel id="currency-label">Moneda</InputLabel>
                  <Select
                    labelId="currency-label" // Asociar etiqueta con el Select
                    value={currency}
                    label="Moneda"
                    onChange={handleCurrencyChange}
                  >
                    <MenuItem value="USD">USD</MenuItem>
                    <MenuItem value="CLP">CLP</MenuItem>
                  </Select>
                </FormControl>
                </Box>

                  <TextField
                    label={`Monto en ${currency}`}
                    type="number"
                    value={paymentAmount}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d*\.?\d*$/.test(value)) {
                        setPaymentAmount(value);
                      }
                    }}
                    inputProps={{
                      min: 0,
                    }}
                    fullWidth
                    sx={{
                      flex: 1,
                      "& input[type=number]::-webkit-inner-spin-button": {
                        display: "none",
                      },
                    }}
                  />

            <TextField
              label={`Monto en ${currency === "USD" ? "CLP" : "USD"}`}
              type="text"
              value={convertedAmount}
              disabled
              fullWidth
              sx={{
                flex: 1,
                "& input[type=number]::-webkit-inner-spin-button": {
                  display: "none",
                },
              }}
            />
          </Box>

          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button variant="contained" color='error' onClick={handleDialogClose}>Cancelar</Button>
          <Button variant="contained" color="primary" onClick={handleFormSubmit}>Agregar</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default ContainerLayout;
