import React, { ChangeEvent, useState, useRef} from "react";
import {
  Button,
  Card,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
  Radio, RadioGroup,FormControlLabel
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { CreateTransactionEvent } from "../../events/proforma/create_transaction_event";
import { CreateProformaEvent } from "../../events/proforma/create_proforma_event";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { setStatus, StatusLayoutEnum } from "../../redux/slices/layout_slice";
import { RootState } from "../../redux";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Autocomplete, useJsApiLoader } from '@react-google-maps/api';
const CLPFormatter = new Intl.NumberFormat("es-CL", {
  style: "currency",
  currency: "CLP",
});

const USDFromatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const CartPage: React.FC = () => {
  const { state } = useLocation();
  const { container, totalPrice, units } = state;
  const [isSuccess, setIsSuccess] = useState(false);
  const [fileName, setFileName] = useState<string>("");
  const [file, setFile] = useState<Blob | null>(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();


  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyAGsYlj5HSS_Zmp4vcxg5daPSEeEObXTpw",
    libraries: ['places'],
  });
  const [deliveryOption, setDeliveryOption] = useState<string>('retiro');
  const [address, setAddress] = useState<string>('');


  const handlePlaceChanged = () => {
    if (autocompleteRef.current && typeof google !== "undefined") {
        const place = autocompleteRef.current.getPlace();
        if (place && place.formatted_address) {
            setAddress(place.formatted_address);
        }
    } else {
        console.warn("Google API not loaded yet");
    }
  };

  const handleDeliveryOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setDeliveryOption(event.target.value);
  };


  const usdToClp = useAppSelector(
    (state: RootState) => state.exchange_rate.usdToClp
  );
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [currency, setCurrency] = useState<"USD" | "CLP">("USD");
  const [transactionAmount, setTransactionAmount] = useState<number | string>(
    ""
  ); // Nuevo estado para el monto de la transacción
  const handleNavigateToHistory = () => {
    navigate("/historialcompras"); // Redirigir al historial de compras
  };
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      if (selectedFile.type === "application/pdf") {
        setFile(selectedFile);
        setFileName(selectedFile.name); // Actualiza el nombre del archivo
      } else {
        alert("Por favor selecciona un archivo PDF válido.");
        setFile(null);
        setFileName(""); // Restablece el nombre del archivo
      }
    }
  };

  const handleGenerateOrder = () => {
    //console.log("Generating order...");
    if (!file) {
      alert("Please select a file.");
      return;
    }

    dispatch(setStatus(StatusLayoutEnum.Loading));

    const amountUsd =
      currency === "CLP" && usdToClp
        ? Number(transactionAmount) / usdToClp
        : Number(transactionAmount);

    const amountClp =
      currency === "USD" && usdToClp
        ? Number(transactionAmount) * usdToClp
        : Number(transactionAmount);

    // Calcula el precio unitario en ambas monedas
    const unitPriceClp = totalPrice / units; // Precio unitario en CLP
    const unitPriceUsd = totalPrice / units / usdToClp; // Precio unitario en USD
    //console.log("CONTAINER", container)
    // Llamada a CreateProformaEvent con ambos precios como argumentos separados
    CreateProformaEvent(
      container.containerProduct.id,
      units,
      unitPriceClp,
      unitPriceUsd,
      deliveryOption === "envio",
      address === "" ? "N/A" : address
    ).then((proforma) => {
      //console.log("Proforma generated successfully ", proforma);
      CreateTransactionEvent(
        file,
        proforma.payments[0].id,
        amountClp, // Enviar monto en CLP
        amountUsd // Enviar monto en USD
      )
        .then((transaction) => {
          //console.log("Order generated successfully ", transaction);
          setIsSuccess(true);
        })
        .finally(() => {
          dispatch(setStatus(StatusLayoutEnum.Success));
        });
    });
  };

  const orderedPaymentsByDueDate = [...container.containerPayments].sort(
    (a, b) => {
      const aSeconds = new Date(a.dueDate).getTime();
      const bSeconds = new Date(b.dueDate).getTime();
      return aSeconds - bSeconds; // No need to add +1 here
    }
  );
  const convertedAmount = () => {
    if (!transactionAmount || !usdToClp) return "";
    return currency === "USD"
      ? (Number(transactionAmount) * usdToClp).toFixed(0)
      : (Number(transactionAmount) / usdToClp).toFixed(2);
  };
  
  if (!isLoaded) {
    return <div>Loading Google Maps...</div>;
}

  return (
    <Container sx={{ padding: "20px" }}>
      <Typography
        variant="h4"
        sx={{
          fontWeight: "600",
          marginBottom: "30px",
        }}
      >
        Carrito de compras
      </Typography>
      {isSuccess ? (
        <Card
          sx={{
            padding: "30px",
            borderRadius: "50px",
            borderColor: "#0A66A5",
            borderWidth: "1px",
            borderStyle: "solid", // Add this line
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontWeight: "600",
              color: "#0A66A5",
            }}
          >
            ¡Orden generada con éxito!
          </Typography>
          <Typography
            variant="body1"
            sx={{
              marginTop: "20px",
            }}
          >
            Tu orden de compra ha sido generada con éxito. Contandem confirmará
            la transferencia bancaria en un plazo de 1 día hábil.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{ marginTop: "20px" }}
            onClick={handleNavigateToHistory}
          >
            Ir al historial de compras
          </Button>
        </Card>
      ) : (
        <Card
          sx={{
            padding: "30px",
            borderRadius: "50px",
            borderColor: "#0A66A5",
            borderWidth: "1px",
            borderStyle: "solid", // Add this line
          }}
        >
          <Grid direction="column" container rowSpacing={3}>
            <Grid container item>
              <Grid container xs={6} item direction="column">
                <Container>
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: "600",
                      color: "#0A66A5",
                      textAlign: "center",
                    }}
                  >
                    {container.containerProduct.product.name}
                  </Typography>
                </Container>

                <Container
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "30px",
                  }}
                >
                  <img
                    src={container.containerProduct.product.imageUrl}
                    style={{
                      height: "200px",
                      aspectRatio: "1/1",
                    }}
                    alt="Random"
                  />
                </Container>
                <Container
                  sx={{
                    marginTop: "30px",
                  }}
                >
                  <Card
                    sx={{
                      padding: "5px",
                      borderRadius: "30px",
                      borderColor: "#0A66A5",
                      borderWidth: "1px",
                      borderStyle: "solid",
                    }}
                  >
                    <Grid
                      container
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <Grid
                        item
                        xs
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            color: "#0A66A5",
                            lineHeight: "1",
                          }}
                        >
                          {container.containerProduct.product.size}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            color: "#0A66A5",
                            lineHeight: "1",
                          }}
                        >
                          Centímetros
                        </Typography>
                      </Grid>
                      <div style={{ border: "1px solid #0A66A5" }}></div>
                      <Grid
                        item
                        xs
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            color: "#0A66A5",
                            lineHeight: "1",
                          }}
                        >
                          {container.containerProduct.product.weight}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            color: "#0A66A5",
                            lineHeight: "1",
                          }}
                        >
                          Gramos
                        </Typography>
                      </Grid>
                      <div style={{ border: "1px solid #0A66A5" }}></div>
                      <Grid
                        item
                        xs
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            color: "#0A66A5",
                            lineHeight: "2",
                          }}
                        >
                          {container.containerProduct.product.materials}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Card>
                </Container>
              </Grid>
              <Grid item xs={6}>
                <Container>
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: "600",
                      color: "#0A66A5",
                    }}
                  >
                    Resumen de compra
                  </Typography>
                </Container>
                <Container
                  sx={{
                    marginTop: "20px",
                  }}
                >
                  <Grid item container>
                    <Grid item xs={6}>
                      <Typography variant="body1">Cantidad total:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1">
                        {units / container.containerProduct.unitsPerPackage}{" "}
                        Cajas - {units} Unidades
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1">Precio:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1">
                        {CLPFormatter.format(totalPrice / units)} CLP -{" "}
                        {USDFromatter.format(totalPrice / units / 950)} USD
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1">Monto total:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1">
                        {CLPFormatter.format(totalPrice)} CLP -{" "}
                        {USDFromatter.format(totalPrice / 800)} USD
                      </Typography>
                    </Grid>
                  </Grid>
                </Container>

                <Grid container item sx={{marginLeft:'15px'}}>
                        <Grid container item>
                            <RadioGroup row value={deliveryOption} onChange={handleDeliveryOptionChange}>
                                <FormControlLabel
                                    value="retiro"
                                    control={<Radio sx={{ transform: 'scale(0.8)' }} />}
                                    label={<Typography >Retiro en bodega</Typography>}
                                />
                                <FormControlLabel
                                    value="envio"
                                    control={<Radio sx={{ transform: 'scale(0.8)' }} />}
                                    label={<Typography >Envío (a cotizar)</Typography>}
                                />
                            </RadioGroup>
                        </Grid>
                        <Grid container item xs={12}>
                            {deliveryOption === 'envio' && (
                                <Autocomplete
                                    onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
                                    onPlaceChanged={handlePlaceChanged}
                                    options={{
                                      componentRestrictions: { country: "cl" }, // Limitar a Chile
                                      types: ["address"], // Solo direcciones
                                    }}
                                >
                                    <TextField
                                        label="Dirección de envío"
                                        fullWidth
                                        variant="outlined"
                                        value={address}
                                        onChange={(e) => setAddress(e.target.value)}
                                        autoComplete="off"
                                        required
                                        sx={{ fontSize: '12px', minWidth: '300px' }}
                                    />
                                </Autocomplete>
                            )}
                        </Grid>
              </Grid>
             
                <Container
                  sx={{
                    marginTop: "30px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: "600",
                      color: "#0A66A5",
                    }}
                  >
                    Plan de pago
                  </Typography>
                  <IconButton
                    sx={{
                      marginLeft: "8px",
                    }}
                    onClick={handleOpen}
                    color="primary"
                    aria-label="Descripción del plan de pago"
                  >
                    <HelpOutlineIcon />
                  </IconButton>

                  <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Descripción del Plan de Pago</DialogTitle>
                    <DialogContent>
                      <Typography
                        sx={{
                          textAlign: "justify", // Alineación justificada
                        }}
                      >
                        El pago total de la importación se divide en un número
                        determinado de abonos, usualmente dos o tres. Para que
                        la importación se haga efectiva, se deben abonar los
                        montos de cada pago antes de la fecha de vencimiento de
                        estos, pudiendo realizar la cantidad de transacciones
                        que el usuario estime convenientes.
                      </Typography>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose} color="primary">
                        Cerrar
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Container>

                <Container
                  sx={{
                    marginTop: "20px",
                  }}
                >
                  {orderedPaymentsByDueDate.map((payment, index) => {
                    return (
                      <Container
                        key={index}
                        sx={{
                          marginTop: "10px",
                        }}
                      >
                        <Typography fontWeight={"bold"}>
                          Pago {index + 1} - {payment.percentage * 100}%
                        </Typography>
                        <Container>
                          <Typography variant="body1">
                            Monto total:{" "}
                            {CLPFormatter.format(
                              Math.round(totalPrice * payment.percentage)
                            )}{" "}
                            CLP
                          </Typography>
                          <Typography variant="body1">
                            Fecha de Vencimiento:{" "}
                            {new Date(payment.dueDate).toLocaleDateString()}
                          </Typography>
                        </Container>
                      </Container>
                    );
                  })}
                </Container>
              </Grid>
            </Grid>
            <Grid container item direction="column">
              <Container>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "600",
                    color: "#0A66A5",
                  }}
                >
                  Condiciones del pago
                </Typography>
                <Container
                  sx={{
                    marginTop: "10px",
                  }}
                >
                  <Typography variant="body1" textAlign={"justify"}>
                    Para asegurar tu compra, debes depositar el 20% del monto
                    total y adjuntar el comprobante de pago en esta orden de
                    compra. Contandem confirmará la transferencia bancaria en un
                    plazo de 1 día hábil. Puedes transferir a una de las
                    siguientes cuentas bancarias:
                  </Typography>
                </Container>
              </Container>
            </Grid>


            <Grid item>
              <Container>
                <Grid container item>
                  <Grid
                    container
                    item
                    xs={6}
                    direction={"column"}
                    alignContent={"center"}
                  >
                    <Typography
                      variant="body1"
                      fontWeight={"bold"}
                      fontSize={20}
                    >
                      Transferencia en CLP
                    </Typography>
                    <Typography variant="body1">
                      Nombre: Contandem SpA
                      <br />
                      Banco Santander
                      <br />
                      Rut: 77.636.029-5
                      <br />
                      Cuenta Corriente
                      <br />
                      Número cuenta: 0-000-9445944-3
                      <br />
                      Correo: finanzas@contandem.cl
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={6}
                    direction={"column"}
                    alignContent={"center"}
                  >
                    <Typography
                      variant="body1"
                      fontWeight={"bold"}
                      fontSize={20}
                    >
                      Transferencia en USD
                    </Typography>
                    <Typography variant="body1">
                      Nombre: Contandem SpA
                      <br />
                      Banco Santander
                      <br />
                      Rut: 77.636.029-5
                      <br />
                      Número cuenta: 005104619152
                      <br />
                      Código SWIFT SANTANDER: BSCHCLRM
                      <br />
                      Dirección: Bandera 140, Santiago.
                      <br />
                      Correo: finanzas@contandem.cl
                    </Typography>
                  </Grid>
                </Grid>
              </Container>
            </Grid>
            {/* Nuevo campo para ingresar el monto de la transacción */}
            {/* Campo para ingresar el monto de la transacción */}
            <Grid container item direction="column">
              <Container>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "600",
                    color: "#0A66A5",
                  }}
                >
                  Monto de la transacción
                </Typography>
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  sx={{ marginTop: "10px" }}
                >
                  {/* Selección de moneda */}
                  <Grid item xs={4}>
                    <TextField
                      select
                      label="Moneda"
                      value={currency}
                      onChange={(e) =>
                        setCurrency(e.target.value as "USD" | "CLP")
                      }
                      fullWidth
                    >
                      <MenuItem value="USD">USD</MenuItem>
                      <MenuItem value="CLP">CLP</MenuItem>
                    </TextField>
                  </Grid>

                  {/* Ingreso del monto */}
                  <Grid item xs={4}>
                    <TextField
                      label={`Monto en ${currency}`}
                      type="number"
                      fullWidth
                      value={transactionAmount}
                      onChange={(e) => setTransactionAmount(e.target.value)}
                    />
                  </Grid>

                  {/* Monto convertido */}
                  <Grid item xs={4}>
                    <TextField
                      label={`Monto en ${currency === "USD" ? "CLP" : "USD"}`}
                      type="text"
                      fullWidth
                      value={convertedAmount()}
                      disabled
                    />
                  </Grid>
                </Grid>
              </Container>
            </Grid>

            {/* Botones para archivo y generación de orden */}
            <Grid item container justifyContent={"end"} columnSpacing={4}>
              <Grid item>
                <form>
                  <input
                    accept="application/pdf"
                    id="contained-button-file"
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                  <label htmlFor="contained-button-file">
                    <Button
                      variant="contained"
                      color={"primary"}
                      component="span"
                    >
                      {file ? "Cambiar archivo" : "Seleccionar archivo"}
                    </Button>
                  </label>
                </form>
                {fileName && ( // Renderiza el nombre del archivo seleccionado
                  <Typography
                    variant="body2"
                    sx={{
                      marginTop: "10px",
                      fontStyle: "italic",
                    }}
                  >
                    Archivo seleccionado: {fileName}
                  </Typography>
                )}
              </Grid>
              <Grid item>
                <Button
                  disabled={!file || !transactionAmount}
                  variant="contained"
                  color={"success"}
                  onClick={handleGenerateOrder}
                >
                  Generar orden
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      )}
    </Container>
  );
};

export default CartPage;
