import React, { useState } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  styled,
} from "@mui/material";
import { SendClarificationEvent } from "../../events/proforma/send_clarification_event";
import { ProformaEntity } from "../../../domain/entities";
import { useAppSelector } from '../../hooks/hooks';

interface ClarificationRequestProps {
  proforma: ProformaEntity; // Proforma actual para saber qué cliente está solicitando la aclaración
}

// Botón Cancelar personalizado
const CancelButton = styled(Button)({
  color: "#fff",
  backgroundColor: "#f44336", // Rojo
  '&:hover': {
    backgroundColor: "#d32f2f",
  },
});

// Estilo para el diálogo cuadrado
const SquareDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    width: "500px", // Ancho fijo
    height: "350px", // Alto igual al ancho
    borderRadius: theme.shape.borderRadius, // Mantiene bordes redondeados si están definidos globalmente
  },
}));

const ClarificationRequest: React.FC<ClarificationRequestProps> = ({ proforma }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState<string>("");
  const [selectedTransaction, setSelectedTransaction] = useState<string>("");

  // Accedemos al cliente desde el estado global de Redux
  const user = useAppSelector((state) => state.user.user);

  const handleSendClarification = async () => {
    try {
      const name = user?.first_name + " " + user?.last_name;
      await SendClarificationEvent(proforma.id, name, message, user.email, selectedTransaction);
      alert("Solicitud de aclaración enviada exitosamente.");
      setOpen(false);
    } catch (error) {
      alert("Error al enviar la solicitud de aclaración.");
    }
  };

  return (
    <div>
      {/* Botón para abrir el diálogo de aclaración */}
      <Button variant="outlined" onClick={() => setOpen(true)}>
        Solicitud de Aclaración
      </Button>

      {/* Dialogo para enviar la solicitud */}
      <SquareDialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth={false} // No se restringe el tamaño predeterminado
      >
        <DialogTitle>Enviar Solicitud de Aclaración</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="dense">
            <InputLabel id="transaction-select-label">Transacción</InputLabel>
            <Select
              labelId="transaction-select-label"
              value={selectedTransaction}
              onChange={(e) => setSelectedTransaction(e.target.value as string)}
              label="Transacción"
            >
              {proforma.payments.flatMap(payment =>
                payment.transactions.map(transaction => (
                  <MenuItem key={transaction.id} value={transaction.id}>
                    {`ID: ${transaction.id} - Monto: ${transaction.amount}`}
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>
          <TextField
            autoFocus
            margin="dense"
            label="Mensaje"
            fullWidth
            multiline
            rows={4}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <CancelButton onClick={() => setOpen(false)}>Cancelar</CancelButton>
          <Button
            onClick={handleSendClarification}
            disabled={!selectedTransaction}
            variant="contained"
            color="primary"
          >
            Enviar
          </Button>
        </DialogActions>
      </SquareDialog>
    </div>
  );
};

export default ClarificationRequest;
