import apiRepository from "../../../data/repository";
import { ContainerFullDetailEntity } from '../../../domain/entities';
import PutContainerUseCase from "../../../domain/usecases/put_container_usecase";
import { setStatus, StatusLayoutEnum } from "../../redux/slices/layout_slice";
import { setSelectedContainer } from "../../redux/slices/admin_container_slice";

export const PutContainerEvent = async (
    id:string,
    name:string,
    capacity:number,
    fixed_cost: number,
    tax: number,
    dispatch
): Promise<ContainerFullDetailEntity> => {
    dispatch(setStatus(StatusLayoutEnum.Loading));
    const usecase = new PutContainerUseCase(apiRepository);
    const result = await usecase.execute({
        id: id,
        name: name,
        capacity: capacity,
        fixed_cost:fixed_cost,
        tax:tax,
    });

    if (result.isLeft()) {
        const error = result.left;
        if (error) {
            throw new Error(error.message);
        } else {
            throw new Error("Error desconocido");
        }
    }

    const ContainerEntity = result.right;
    if (!ContainerEntity) {
        throw new Error("No se pudo crear el producto");
    }
    dispatch(setSelectedContainer(ContainerEntity));
    dispatch(setStatus(StatusLayoutEnum.Success));
    return ContainerEntity;
}