import React from "react";
import { Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { SupplierEntity } from "../../../../domain/entities";

interface ProviderInfoProps {
  supplier: SupplierEntity;
}

// Función para verificar si el campo es válido y no está vacío
const displayValue = (value: string | number | undefined) => {
  return value !== undefined && value !== null && value.toString().trim() !== ""
    ? value
    : "No Aplica";
};

// Estilizar la fila de la tabla con efecto hover
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
    transition: "background-color 0.3s ease",
  },
}));

const ProviderInfo: React.FC<ProviderInfoProps> = ({ supplier }) => (
  <div>
    {/* Título centrado */}
    <Typography variant="h6" align="center">
      Información del Proveedor
    </Typography>

    {/* Tabla con detalles del proveedor */}
    <Table>
      <TableBody>
        <StyledTableRow>
          <TableCell align="left" style={{ fontWeight: "bold", width: "40%" }}>
            Nombre:
          </TableCell>
          <TableCell align="left" style={{ width: "60%" }}>
            {displayValue(supplier.name)}
          </TableCell>
        </StyledTableRow>
        <StyledTableRow>
          <TableCell align="left" style={{ fontWeight: "bold", width: "40%" }}>
            Nombre Contacto:
          </TableCell>
          <TableCell align="left" style={{ width: "60%" }}>
            {displayValue(supplier.contactName)}
          </TableCell>
        </StyledTableRow>
        <StyledTableRow>
          <TableCell align="left" style={{ fontWeight: "bold", width: "40%" }}>
            Correo:
          </TableCell>
          <TableCell align="left" style={{ width: "60%" }}>
            {displayValue(supplier.email)}
          </TableCell>
        </StyledTableRow>
        <StyledTableRow>
          <TableCell align="left" style={{ fontWeight: "bold", width: "40%" }}>
            Celular:
          </TableCell>
          <TableCell align="left" style={{ width: "60%" }}>
            {displayValue(supplier.phone)}
          </TableCell>
        </StyledTableRow>
        <StyledTableRow>
          <TableCell align="left" style={{ fontWeight: "bold", width: "40%" }}>
            País:
          </TableCell>
          <TableCell align="left" style={{ width: "60%" }}>
            {displayValue(supplier.country)}
          </TableCell>
        </StyledTableRow>
        <StyledTableRow>
          <TableCell align="left" style={{ fontWeight: "bold", width: "40%" }}>
            Ciudad:
          </TableCell>
          <TableCell align="left" style={{ width: "60%" }}>
            {displayValue(supplier.city)}
          </TableCell>
        </StyledTableRow>
        <StyledTableRow>
          <TableCell align="left" style={{ fontWeight: "bold", width: "40%" }}>
            Dirección:
          </TableCell>
          <TableCell align="left" style={{ width: "60%" }}>
            {displayValue(supplier.address)}
          </TableCell>
        </StyledTableRow>
        
      </TableBody>
    </Table>
  </div>
);

export default ProviderInfo;
