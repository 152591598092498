import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface ClientCardProps {
  client: {
    id: string;
    rut: string;
    name: string;
    address: string;
    phone: string;
    email: string;
  };
}

const ClientLayout: React.FC<ClientCardProps> = ({ client }) => {
  const [hover, setHover] = useState(false);
  const navigate = useNavigate();

  const handleMouseEnter = () => setHover(true);
  const handleMouseLeave = () => setHover(false);

  const handleNavigate = () => {
    //console.log(client.id);
    navigate(`/admin/clients/${client.id}`);
  };

  return (
    <Grid container style={{ width: "100%" }}>
      {/* Fila superior */}
      <Grid
        item
        xs={12}
        style={{ height: "50px", borderBottom: "1px solid #ccc" }}
      >
        <Grid
          container
          sx={{
            height: "99%",
            padding: "10px",
            transition: "background-color 0.3s",
            backgroundColor: hover ? "#f0f8ff" : "#fff",
            alignItems: "center",
            textAlign: "center",
            cursor: "pointer",
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleNavigate}
        >
          <Grid
            item
            xs={2.4}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Typography
              variant="body1"
              sx={{ fontWeight: "bold", color: "#0A66A5" }}
            >
              {client.rut}
            </Typography>
          </Grid>
          <Grid
            item
            xs={2.4}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Typography variant="body1">{client.name}</Typography>
          </Grid>
          <Grid
            item
            xs={2.4}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Typography variant="body1">{client.address}</Typography>
          </Grid>
          <Grid
            item
            xs={2.4}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Typography variant="body1">{client.phone}</Typography>
          </Grid>
          <Grid
            item
            xs={2.4}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Typography variant="body1">{client.email}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ClientLayout;
