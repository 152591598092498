import React from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Status } from "../../../../redux/slices/admin_container_slice";
import Box from "@mui/material/Box";

export default function StatusProgressBar({ activo }) {
  //console.log("ACTIVO: ", activo)
  const steps = Status.map((status) => status.name);
  const totalSteps = steps.length;
  const maxVisibleSteps = 5;
  const halfVisibleSteps = Math.floor(maxVisibleSteps / 2);

  let visibleSteps;
  let position;
  if (activo <= halfVisibleSteps) {
    visibleSteps = steps.slice(0, maxVisibleSteps);
    position = activo;
  } else if (activo >= totalSteps - halfVisibleSteps) {
    visibleSteps = steps.slice(totalSteps - maxVisibleSteps, totalSteps);
    position = activo - totalSteps + maxVisibleSteps;
  } else {
    visibleSteps = steps.slice(
      activo - halfVisibleSteps,
      activo + halfVisibleSteps + 1
    );
    position = activo - totalSteps + maxVisibleSteps;
    position = 2;
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={position} alternativeLabel>
        {visibleSteps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );

  
}


