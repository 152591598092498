import React, { useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom'; 
import { Container, Grid, Box, Typography, Button } from "@mui/material";
import { GetContainerClientInfoEvent } from "../../../../events/container/get_container_client_info_event";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import { ContainerCostumerEntity } from "../../../../../domain/entities";
import { setStatus, StatusLayoutEnum } from "../../../../redux/slices/layout_slice";
import ClientsProfomaAdmin from './ClientProforma';
import { setSelectedContainer,AdminContainersModeEnum,setMode } from '../../../../redux/slices/admin_container_slice';
import { GetContainersEvent } from "../../../../events/container/get_containers_event";

const ContainerClientsAdmin: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { container_client_info } = useAppSelector((state) => state.simple_container);
  const { containers } = useAppSelector(state => state.container);
  const container = container_client_info;
  const dispatch = useAppDispatch();
  const navigate = useNavigate(); 

  useEffect(() => {
    dispatch(GetContainersEvent());
  }, []);

  useEffect(() => {
    dispatch(GetContainerClientInfoEvent(id));
    containers.forEach((contenedor) => {
      //console.log(contenedor);
      if (contenedor.id === id) {
          //console.log(contenedor);
          dispatch(setSelectedContainer(contenedor));
      }
    });
    dispatch(setStatus(StatusLayoutEnum.Success));
}, [dispatch, id, containers]);

  if (container == null) return null;

  const usedCapacityPercentage = ((container.used_capacity) * 100).toFixed(2);


  const handleNavigate = () => {
    dispatch(setMode(AdminContainersModeEnum.ViewContainer))
    navigate(`/admin/container/${id}`);
  };

  return (
    <Box sx={{ width: '100%', padding: 2 }}>
      <Container sx={{ width: '100%', padding: 0 }}>
        <Grid container sx={{ marginTop: '10px' }} spacing={2}>
          <Grid item xs={12}>
            <Box
              key={container.id}
              sx={{
                marginBottom: '20px',
                borderRadius: 2,
                boxShadow: 1,
                padding: 2,
                backgroundColor: '#f5f5f5',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                '&:hover': {
                  backgroundColor: '#E3F2FD',
                },
              }}
              onClick={() => handleNavigate()}
            >
              <Typography variant="h5" gutterBottom sx={{ color: '#0A66A5', flex: 1 }}>Contenedor: {container.name}</Typography>
              <Grid container spacing={2} sx={{ flex: 2, justifyContent: 'space-between' }}>
                <Grid item xs={6}>
                  <Typography variant="body1">Capacidad: {container.capacity} m³</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">Capacidad Usada Total: {usedCapacityPercentage}%</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">Costo Fijo (USD): ${container.fixed_cost.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">Impuestos (USD): ${container.tax.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">Fecha Creación: {new Date(container.created_at).toLocaleDateString()}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">Fecha de Última Actualización: {new Date(container.updated_at).toLocaleDateString()}</Typography>
                </Grid>
              </Grid>
            </Box>
            <Grid item xs={12} sx={{ marginTop: '10px', textAlign: 'center' }}>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Clientes y Proformas Relacionadas al Contenedor</Typography>
            </Grid>
            <Grid>
              <ClientsProfomaAdmin customers={container.customers} container={container} /> 
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ textAlign: 'left', marginTop: 2 }}>
            <Button variant="contained" color="primary" onClick={() => navigate("/admin/containers")}>Volver</Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ContainerClientsAdmin;
