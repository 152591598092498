import React from 'react';
import { Link } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import AnalyticsRoundedIcon from '@mui/icons-material/AnalyticsRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import Divider from '@mui/material/Divider';

export default function MenuContent() {
  const mainListItems = [
    { text: 'Inicio', icon: <HomeRoundedIcon />, link: '/' },
    { text: 'Proveedores', icon: <AnalyticsRoundedIcon />, link: '/admin/providers'},
    { text: 'Clientes', icon: <PeopleRoundedIcon />, link: '/admin/clients'},
    { text: 'Productos', icon: <LocalGroceryStoreIcon />, link: '/admin/products'},
    { text: 'Planes de Pago', icon: <CreditCardIcon />, link: '/admin/clients/payment-plan'},
    { text: 'Contenedores', icon: <DirectionsBoatIcon />, link: '/admin/containers'},
  ];
  return (
    <Stack sx={{ flexGrow: 1, p: 1, justifyContent: 'space-between' }}>
      <List dense>
        {mainListItems.map((item, index) => (
          <React.Fragment key={index}>
            <ListItem disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                component={Link}
                to={item.link || ''}
                selected={index === 0}
                sx={{ pl: 2, pr: 1, py: 1 }}
              >
                <ListItemIcon sx={{ minWidth: '36px', color: 'white' }}>{item.icon}</ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ fontSize: '0.9rem', color: 'white' }} 
                  primary={item.text}
                />
              </ListItemButton>
            </ListItem>
            {index === 3 && <Divider sx={{ borderColor: 'white' }} />}
          </React.Fragment>
        ))}
      </List>
    </Stack>
  );
}
