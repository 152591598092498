import React, { useState } from "react";
import {
  Button,
  Card,
  Container,
  Grid,
  Typography,
  TextField,
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle
} from "@mui/material";
import { useAppDispatch } from "../../hooks/hooks";
import { PostSimulationEvent } from "../../events/simulator/post_simulator_event";
import { setStatus, StatusLayoutEnum } from '../../redux/slices/layout_slice';
import { setSimulation } from '../../redux/slices/simulator_slice';
import ResultsSection from "./results_section";

interface SimulatorProps {
  pricingData: any;
  product_id: string;
}

const SimulatorTable: React.FC<SimulatorProps> = ({ pricingData, product_id }) => {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const toggleDialog = () => setDialogOpen((prev) => !prev);
  const dispatch = useAppDispatch();
  const [formValues, setFormValues] = useState({
    precio_venta: "",
    precio_compra: "",
    cantidad: "",
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name === 'precio_venta' || name === 'precio_compra' || name === 'cantidad') {
      if (value === "" || /^[+]?\d*\.?\d*$/.test(value)) {
        setFormValues((prev) => ({ ...prev, [name]: value }));
      }
    } else {
      setFormValues((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSimulate = async () => {
    try {
      dispatch(setStatus(StatusLayoutEnum.Loading));
  
      const precioVenta = formValues.precio_venta === "" ? 1000 : parseFloat(formValues.precio_venta);
      const precioCompra = formValues.precio_compra === "" ? 500 : parseFloat(formValues.precio_compra);
      const cantidad = formValues.cantidad === "" ? 50000 : parseInt(formValues.cantidad, 10);
  
      const result = await PostSimulationEvent(
        precioVenta,
        precioCompra,
        cantidad,
        product_id,
        dispatch
      );
  
      if (result) {
        dispatch(setSimulation(result));
      }
    } catch (error: any) {
      if (error.message === "El ID del producto no es válido.") {
        dispatch(setStatus(StatusLayoutEnum.Success));
        setDialogOpen(true);
      } else {
        console.error("Simulation failed", error);
      }
    }
  };
  
  

  const handleClear = () => {
    setFormValues({
      precio_venta: "",
      precio_compra: "",
      cantidad: "",
    });
  };

  return (
    <Container sx={{ padding: 3 }}>
      <Grid container spacing={3}>
        {/* Form Section */}
        <Grid item xs={12} md={6}>
          <Card sx={{ padding: 3, borderRadius: 2, backgroundColor: "#f5f5f5" }}>
            <Typography variant="h6" sx={{ marginBottom: 2, color: "#0A66A5", fontWeight: "bold" }}>
              Parámetros de Simulación
            </Typography>
            <Grid container spacing={2}>
              {/* Precio de Venta */}
              <Grid item xs={12}>
              <Grid container spacing={1} alignItems="center">
                  <Grid item xs={12} sm={4}>
                    <Typography variant="body2" sx={{ color: "#0A66A5", fontSize: "1.1rem" }}>
                      Precio de Venta CLP:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <TextField
                      placeholder="1000"
                      name="precio_venta"
                      value={formValues.precio_venta}
                      onChange={handleInputChange}
                      fullWidth
                      type="number"
                      inputProps={{
                        min: 1,
                        style: { color: "white", backgroundColor: "#0A66A5", fontSize: "1.1rem" },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {/* Precio de Compra */}
              <Grid item xs={12}>
              <Grid container spacing={1} alignItems="center">
                  <Grid item xs={12} sm={4}>
                    <Typography variant="body2" sx={{ color: "#0A66A5", fontSize: "1.1rem" }}>
                      Precio de Compra CLP:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <TextField
                      placeholder="500"
                      name="precio_compra"
                      value={formValues.precio_compra}
                      onChange={handleInputChange}
                      fullWidth
                      type="number"
                      inputProps={{
                        min: 1,
                        style: { color: "white", backgroundColor: "#0A66A5", fontSize: "1.1rem" },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {/* Cantidad */}
              <Grid item xs={12}>
              <Grid container spacing={1} alignItems="center">
                  <Grid item xs={12} sm={4}>
                    <Typography variant="body2" sx={{ color: "#0A66A5", fontSize: "1.1rem" }}>
                      Cantidad:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <TextField
                      placeholder="50000"
                      name="cantidad"
                      value={formValues.cantidad}
                      onChange={handleInputChange}
                      fullWidth
                      type="number"
                      inputProps={{
                        min: 1,
                        style: { color: "white", backgroundColor: "#0A66A5", fontSize: "1.1rem" },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {/* Action Buttons */}
              <Grid container justifyContent="flex-end" sx={{ marginTop: 2 }} spacing={2}>
                <Grid item>
                  <Button variant="contained" color="primary" onClick={handleSimulate}>
                    Simular Utilidad
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="outlined" color="secondary" onClick={handleClear}>
                    Limpiar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>

        {/* Results Section */}
        <Grid item xs={12} md={6}>
          <Card sx={{ padding: 3, borderRadius: 2 }}>
            <ResultsSection/>
          </Card>
        </Grid>
      </Grid>

      {/* Dialog Component */}
      <Dialog open={isDialogOpen} onClose={toggleDialog}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          El producto no está habilitado para simulación. Intente nuevamente con otro producto.
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleDialog} color="primary">Cerrar</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default SimulatorTable;
