import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  IconButton,
  TableContainer,
  TableRow,
  TableHead,
  Table,
  TableCell,
  TableBody,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useAppSelector } from "../../../hooks/hooks";
const formatAmount = (amount, currency = "CLP") => {
  const numericAmount = Number(amount);

  if (isNaN(numericAmount)) return amount;

  if (currency === "CLP") {
    // Formato para CLP: punto entre miles, coma para decimales
    return numericAmount.toLocaleString("es-CL", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  } else if (currency === "USD") {
    // Formato para USD: punto decimal, dos decimales
    return numericAmount.toLocaleString("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  }

  return amount; // Retorno sin formato si no se especifica una moneda válida
};

const createPricingTable = (importData) => {
  const {
    interval,
    max_quantity,
    min_quantity,
    unit_base_price,
    increased_percent,
    unit_base_price_usd,
  } = importData;

  const pricingData = [];
  let currentMin = min_quantity;
  let index = 0;

  while (currentMin < max_quantity) {
    const currentMax = Math.min(currentMin + interval, max_quantity);
    const discount = (1 - increased_percent) ** index;
    const price = unit_base_price * discount;
    const price_usd = unit_base_price_usd * discount;
    pricingData.push({
      range: `${Math.round(currentMin)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")} - ${Math.round(currentMax)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`,
      price: price
        .toFixed(2)
        .replace(",", ".")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      price_usd: price_usd
        .toFixed(4)
        .replace(".", ",")
        //.replace(/\B(?=(\d{3})+(?!\d))/g, "."),
    });

    currentMin += interval;
    index++;
  }

  return pricingData;
};

const ContainerActiveLayout = ({
  importData,
  isExpanded,
  index,
  onToggleExpand,
  product_id,
  name,
  imageURL,
  weight,
  materials,
  size,
}) => {
  const maxCajasDisponibles = Math.trunc(
    (importData.max_quantity - importData.quantity) / importData.units_per_package
  );
  //console.log("IMPORT DATA",importData)
  const pricingData = createPricingTable(importData);
  const [quantity, setQuantity] = useState(1);
  const [selectedContent, setSelectedContent] = useState(0);
  const { usdToClp } = useAppSelector((state) => state.exchange_rate);
  const [totalPrice, setTotalPrice] = useState(
    100 * importData.unit_base_price
  );
  const navigate = useNavigate();
  useEffect(() => {
    setTotalPrice(
      getPriceForQuantity() * quantity * importData.units_per_package
    );
  }, [quantity]);
  const handleLeftClick = () => {
    setSelectedContent(0); // Cambia a contenido izquierdo
  };

  const AddCart = () => {
    const container = {
      containerProduct: {
        product: {
          name: name,
          imageUrl: imageURL,
          size: size,
          materials: materials,
          weight: weight,
        },
        id: importData.id,
        unitsPerPackage: importData.units_per_package,
      },
      containerPayments: importData.payments,
    };
    navigate("/cart", {
      state: {
        container: container,
        totalPrice: totalPrice,
        units: quantity * importData.units_per_package,
      },
    });
  };
  //console.log("PRICING DATA ACTIVE", pricingData)

  const getPriceForQuantity = () => {
    for (let i = 0; i < pricingData.length; i++) {
      const [min, max] = pricingData[i].range
        .split(" - ")
        .map((num) => parseInt(num.replace(".", ""), 10));
      if (quantity >= min && quantity <= max) {
        return parseFloat(pricingData[i].price.replace(",", "."));
      }
    }
    return importData.unit_base_price;
  };

  const increaseQuantity = () => {
    const temp = Math.trunc(
      (importData.max_quantity - importData.quantity) /
        importData.units_per_package
    );
    setQuantity(quantity);
    setQuantity((prev) => (prev < temp ? prev + 1 : prev));
  };

  const decreaseQuantity = () => {
    setQuantity((prev) => (prev > 1 ? prev - 1 : 1));
  };

  const handleQuantityChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value) && value > 0 && value <= maxCajasDisponibles) {
      setQuantity(value);
    }
  };

  const handleRightClick = () => {
    setSelectedContent(1);
  };

  const Simulation = () => {
    //console.log(product_id);
    const product = {
      name: name,
      imageUrl: imageURL,
      size: size,
      materials: materials,
      weight: weight,
    };
    navigate("/simulation", {
      state: {
        pricingData: pricingData,
        container_product: importData,
        product: product,
        product_id: product_id,
      },
    });
  };

  return (
    <Box
      sx={{
        height: "50hv",
        width: "98%",
        padding: "5px",
        backgroundColor: "#f5f5f5",
        borderBottom: "2px solid #d3d3d3",
      }}
    >
      <Grid container sx={{ height: "100%", maxHeight: "50hv" }}>
        <Grid
          container
          item
          sx={{ height: "25%", alignItems: "flex-end", cursor: "pointer" }}
        >
          <Grid container sx={{ display: "flex", alignItems: "center" }}>
            <Grid item xs={2}>
              <Button
                onClick={onToggleExpand}
                startIcon={
                  isExpanded ? (
                    <ExpandLessIcon style={{ fontSize: "30px" }} />
                  ) : (
                    <ExpandMoreIcon style={{ fontSize: "30px" }} />
                  )
                }
                sx={{ minHeight: "35px", padding: "0" }}
              />
              {/* onClick={onToggleExpand} */}
            </Grid>
            <Grid
              item
              xs={5}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", color: "#0A66A5", fontSize: "18px" }}
              >
                <strong>
                  {Math.trunc(
                    (importData.quantity / importData.max_quantity) * 100
                  )}
                  %
                </strong>
              </Typography>
            </Grid>
            <Grid
              item
              xs={5}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Typography sx={{ fontSize: "18px" }}>
                Quedan{" "}
                {Math.trunc(
                  (importData.max_quantity - importData.quantity) /
                    importData.units_per_package
                )}{" "}
                de{" "}
                {Math.trunc(
                  importData.max_quantity / importData.units_per_package
                )}{" "}
                cajas
              </Typography>
            </Grid>
          </Grid>
          {/* 
            <Grid item xs={1} sx={{height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
                <Typography sx={{ fontSize: '35px', color: '#0A66A5' }}>
                <strong>{index}</strong>
                </Typography>
            </Grid>
            <Grid item xs={1.7} sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
                <img
                src={`https://assetscontandem.s3.us-east-1.amazonaws.com/porcentajes/${calcularValorIntervalo()}.png`}
                alt="Capacidad Usada"
                style={{ width: 'auto', height: '10vh', objectFit: 'contain' }}
                />
            </Grid>

            <Grid item xs={1.5} sx={{height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
                <Typography sx={{ fontSize: '35px', color: '#0A66A5' }}>
                <strong>{Math.round(( (importData.quantity)/ importData.max_quantity) * 100)}%</strong>
                </Typography>
            </Grid>

            <Grid item xs={3.5} sx={{  height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
                <Typography sx={{ fontSize: '20px', color: '#0A66A5', lineHeight: 1.3 }}>
                <strong>Del contenedor vendido</strong>
                </Typography>
            </Grid>

            <Grid item xs={4.3} sx={{ display: 'flex', height: '100%', padding: '8px' }}>
                <Typography sx={{ fontSize: '16px' }}>
                / Quedan { Math.round((importData.max_quantity - importData.quantity)/importData.units_per_package)} de { Math.round(importData.max_quantity/importData.units_per_package)} cajas
                </Typography>
            </Grid> */}
        </Grid>
        {isExpanded && (
          <>
            <Grid
              container
              item
              sx={{
                height: "15%",
                backgroundColor: "#dce775",
                alignItems: "center",
              }}
            >
              <Grid
                item
                xs={6}
                sx={{
                  backgroundColor:
                    selectedContent === 0 ? "#c2c2c2" : "#dedede",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={handleLeftClick}
              >
                <Typography variant="h6">Precios</Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  backgroundColor:
                    selectedContent === 1 ? "#c2c2c2" : "#dedede",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={handleRightClick}
              >
                <Typography variant="h6">Importación</Typography>
              </Grid>
            </Grid>
            <Grid
              container
              item
              sx={{
                height: "60%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid
                item
                xs={8.5}
                sx={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {selectedContent === 0 ? (
                  <Grid container sx={{ height: "100%" }}>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        height: "15%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#0A66A5",
                          marginLeft: "10px",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <strong>Tabla de precios:</strong>
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        height: "85%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <TableContainer sx={{ maxHeight: "100%", width: "90%" }}>
                        <Table
                          sx={{
                            borderCollapse: "separate",
                            borderSpacing: "5px 0px",
                            width: "100%",
                          }}
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell
                                sx={{
                                  fontWeight: "bold",
                                  color: "#0A66A5",
                                  padding: "2px",
                                  textAlign: "center",
                                  backgroundColor: "#f0f0f0",
                                  border: "none",
                                }}
                              >
                                Desde-Hasta
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontWeight: "bold",
                                  color: "#0A66A5",
                                  padding: "2px",
                                  textAlign: "center",
                                  backgroundColor: "#f0f0f0",
                                  border: "none",
                                }}
                              >
                                Precio CLP (IVA inc)
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontWeight: "bold",
                                  color: "#0A66A5",
                                  padding: "2px",
                                  textAlign: "center",
                                  backgroundColor: "#f0f0f0",
                                  border: "none",
                                }}
                              >
                                Precio USD (IVA inc)
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {pricingData.map((row, index) => (
                              <TableRow
                                key={index}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  sx={{
                                    padding: "1px",
                                    textAlign: "center",
                                    backgroundColor: "#f0f0f0",
                                    border: "none",
                                  }}
                                >
                                  {row.range}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    padding: "1px",
                                    textAlign: "center",
                                    backgroundColor: "#f0f0f0",
                                    border: "none",
                                  }}
                                >
                                  {row.price}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    padding: "1px",
                                    textAlign: "center",
                                    backgroundColor: "#f0f0f0",
                                    border: "none",
                                  }}
                                >
                                  {row.price_usd}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container sx={{ height: "100%" }}>
                    
                    <Grid
                      item
                      xs={12}
                      sx={{
                        height: "25%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="body1" sx={{ marginLeft: "5px" }}>
                        {quantity.toLocaleString("es-ES")} cajas -{" "}
                        {(
                          quantity * importData.units_per_package
                        ).toLocaleString("es-ES")}{" "}
                        unidades
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          border: "2px solid #0A66A5",
                          borderRadius: "8px",
                          padding: "3px",
                          marginLeft: "auto",
                        }}
                      >
                        <IconButton onClick={decreaseQuantity}>
                          <RemoveIcon sx={{ fontSize: "15px" }} />
                        </IconButton>
                        <TextField
                          value={quantity}
                          onChange={handleQuantityChange}
                          sx={{
                            width: "55px",
                            "& input": {
                              textAlign: "center", // Centra el texto
                            },
                          }}
                          inputProps={{ min: 0 }}
                          InputProps={{
                            disableUnderline: true, // Quita la línea debajo del número
                          }}
                          variant="standard"
                        />
                        <IconButton onClick={increaseQuantity}>
                          <AddIcon sx={{ fontSize: "15px" }} />
                        </IconButton>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        height: "25%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{ color: "#0A66A5", marginLeft: "5px" }}
                      >
                        <strong>Pagos</strong>
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        height: "25%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Grid
                        item
                        xs={6}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          paddingLeft: "10px",
                        }}
                      >
                        {importData.payments.map((payment, index) => (
                          <Typography variant="body1" key={index}>
                            {Math.round(payment.percentage * 100)}%{" "}
                            ${formatAmount(totalPrice * payment.percentage, 'CLP')} CLP
                          </Typography>
                        ))}
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="body1">
                      <span style={{ color: "#0A66A5" }}>Total:</span>{" "}
                      ${formatAmount(totalPrice, 'CLP')} CLP -{" "}
                      {formatAmount(totalPrice / usdToClp, 'USD')}{" "}
                      USD
                    </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              {selectedContent === 0 ? (
                <Grid
                  item
                  xs={3.5}
                  sx={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button variant="outlined" onClick={Simulation} sx={{ width: "90%" }}>
                    SIMULAR IMPORTACIÓN
                  </Button>
                </Grid>
              ) : (
                <Grid
                  item
                  xs={3.5}
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    paddingBottom: "16px",
                  }}
                >
                  <Button
                    variant="outlined"
                    sx={{ width: "90%", alignSelf: "center", marginTop: "50%" }}
                    onClick={AddCart}
                  >
                    AGREGAR AL CARRO
                  </Button>
                </Grid>
              )}
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default ContainerActiveLayout;
